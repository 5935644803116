import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Card, Label } from "flowbite-react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAirtimeController,
  useNetworkSelectionController,
} from "controllers/AirtimeAndData/AirtimeController";
import Spinners from "components/Spinner/Spinner";
import { Formik, Form } from "formik";
import { FormikButton } from "alat-components-library";
import { airtimeDataValidationSchema } from "./airtimeValidation/airtimeValidation";
import { StateDropdown } from "components/Dashboard/components/StateDropdown";
import PageLoader from "components/Loader/PageLoader";

const NewAirtime = () => {
  const { presetAmountData, presetAmountLoading } = useAirtimeController();
  const { NetworkSelectionData } = useNetworkSelectionController();
  const navigate = useNavigate();
  const location = useLocation();

  const beneficiary = location?.state?.beneficiary;

  const NetworkList = () => {
    const newArray =
      NetworkSelectionData?.length > 0
        ? NetworkSelectionData?.map((arr: any, index: any) => {
            return {
              ...arr,
              id: index,
              label: arr,
              name: arr,
            };
          })
        : [];
    return newArray;
  };

  function handleAirtimeRequest(airtimeData: any) {
    navigate("/Airtime/AirtimePurchase", {
      state: {
        airtimeData: {
          ...airtimeData,
          savedBeneficiary: beneficiary?.alias,
        },
      },
    });
  }
  return (
    <div className="w-full">
      <Card className=" bg-white md:w-[48rem] w-full mx-auto dark:bg-white dark:border-transparent border-transparent">
        <div>
          <div className="flex items-center justify-between">
            <ChevronLeftIcon
              onClick={() => navigate(-1)}
              id="back_btn"
              className="h-6 w-6 text-[#333333] cursor-pointer"
            />
            <div
              className="text-[#4D4D4D] text-xl text-center"
              id="banktransfer_heading"
            >
              Buy Airtime
            </div>
            <div></div>
          </div>
        </div>
        <hr />
        {presetAmountLoading ? <PageLoader /> : null}
        <Formik
          initialValues={{
            amount: beneficiary?.amount || 0,
            phonenumber: beneficiary?.phoneNumber || "",
            networkProvidersName: beneficiary?.networkName || "",
          }}
          onSubmit={handleAirtimeRequest}
          validationSchema={airtimeDataValidationSchema}
          validateOnChange
        >
          {({
            handleChange,
            handleBlur,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
            values,
            setFieldValue,
          }) => (
            <Form>
              <div>
                <div className="flex flex-wrap justify-center gap-4 ">
                  <div className="grid md:grid-cols-3 gap-14 text-alat-grey relative w-full lg:max-w-sm mb-2">
                    {presetAmountData?.map((amount: any, index: any) => (
                      <div
                        className="flex items-center justify-center"
                        key={index}
                        onClick={() => setFieldValue("amount", amount)}
                      >
                        <Card
                          className="cursor-pointer hover:bg-primary hover:text-white hover:fill-white dark:bg-white dark:border-[#E4DFDF] border-[#E4DFDF] dark:shadow-white"
                          style={{
                            boxSizing: "border-box",
                            //   width: '110px',
                            height: "50px",
                            border: "1.5px solid",
                          }}
                        >
                          <h1 className="text-[14px]" id="newtransfer_text">
                            ₦{amount}
                          </h1>
                        </Card>
                      </div>
                    ))}
                  </div>

                  <div className="text-alat-grey relative w-full lg:max-w-sm mb-2">
                    <div className="mb-2 block text-left">
                      <Label
                        htmlFor="amount"
                        value="Amount"
                        className="!text-[#464646] font-normal text-lg leading-[1rem]"
                      />
                    </div>
                    <div className="relative w-full">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-[#666666]">₦</span>
                      </div>
                      <input
                        name="amount"
                        type="number"
                        value={values?.amount}
                        id="airtimeInput"
                        className="pl-6 w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm"
                        onChange={handleChange}
                      />
                    </div>
                    {touched?.amount && errors?.amount && (
                      <span className="text-sm text-red-600">
                        {typeof errors?.amount == "string"
                          ? errors?.amount
                          : ""}
                      </span>
                    )}

                    <div className="relative w-full mb-2">
                      <div className="mb-2 block text-left">
                        <Label
                          htmlFor="phoneNumber"
                          value="Phone Number"
                          className="!text-[#464646] font-normal text-lg leading-[1rem]"
                        />
                      </div>
                      <div className="relative w-full">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"></div>
                        <input
                          id="phoneNumber"
                          name="phonenumber"
                          type="text"
                          className="pl-6 w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.phonenumber}
                        />
                        {touched?.phonenumber && errors?.phonenumber && (
                          <span className="text-sm text-red-600">
                            {typeof errors?.phonenumber == "string"
                              ? errors?.phonenumber
                              : ""}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="relative w-full lg:max-w-sm mb-2">
                      <div className="mb-2 block text-left text-[#666666]">
                        <Label
                          value="Select Network"
                          className="!text-[#464646] font-normal text-lg leading-[1rem]"
                        />
                      </div>
                      <div className="relative w-full lg:max-w-sm">
                        <StateDropdown
                          name="networkProvidersName"
                          id="networkprovider"
                          onChange={handleChange}
                          value={values?.networkProvidersName}
                          options={NetworkList()}
                          placeholder="Select Network"
                          onSelectOption={(e) => {}}
                        />

                        {touched?.networkProvidersName &&
                          errors?.networkProvidersName && (
                            <span className="text-sm text-red-600">
                              {typeof errors?.networkProvidersName == "string"
                                ? errors?.networkProvidersName
                                : ""}
                            </span>
                          )}
                        {""}
                      </div>
                    </div>
                  </div>

                  <div className="relative w-full lg:max-w-sm mb-2  flex items-center justify-center">
                    <FormikButton
                      id="proceed"
                      labelText="Proceed"
                      isDisabled={!isValid && !dirty}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default NewAirtime;
