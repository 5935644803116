import { PrimaryButton, TextInput } from "alat-components-library";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import { useEffect, useState } from "react";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import {
  usePaymentState,
  setPendingTransactionsResponse,
} from "react-wrappers/stores/redux/payments/slices";
import {
  POST_FETCH_TRANSACTION_DETAIL_ACTION,
  PUT_APROVE_OR_REJECT_TRANSACTION_ACTION,
} from "react-wrappers/stores/redux/payments/actions";
import { formatNumberToCurrency } from "utils/helperFunctions";
import { useAppDispatch } from "hooks/useReduxHook";
import { ReactComponent as BackArrow } from "../../alat-components-library/assets/images/icons/back-arrow-two.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import { toast } from "react-toastify";
import { ReactComponent as ModalClose } from "../../alat-components-library/assets/images/icons/modal-close.svg";
import { ToastNotify } from "helpers/toastNotify";
import PageLoader from "components/Loader/PageLoader";

export const PendingTransactionsHeaders = [
  { id: 1, title: "from" },
  { id: 2, title: "amount" },
  { id: 3, title: "type" },
  { id: 4, title: "description" },
  { id: 5, title: "date" },
  { id: 6, title: "status" },
  { id: 7, title: "action" },
];

const TransactionDetail = () => {
  const [formPayload, setFormPayload] = useState({
    transactionPin: "",
    token: "",
    remark: "",
  });
  const [rejectionModal, setRejectionModal] = useState(false);
  const [remarkMessage, setRemarkMessage] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAuthState();
  const { pendingTransactionDetail } = usePaymentState();
  const [openBeneficiaryModal, setBeneficiaryModal] = useState(false);
  const [load, toggleLoading] = useState(false);

  const {
    initiatedBy,
    beneficiaryDetails,
    sourceAccountNumber,
    type,
    totalCharge,
    count,
    totalAmount,
    sourceAccountCurrency,
    initiatedByFullName,
  } = pendingTransactionDetail;

  const correlationId = searchParams.get("correlationId");

  useEffect(() => {
    const payload = {
      correlationId,
      mappedAccounts: user.accounts.map((x: any) => x.accountNo),
    };

    dispatch(POST_FETCH_TRANSACTION_DETAIL_ACTION(payload));
    // eslint-disable-next-line
  }, [correlationId]);

  const handleApproveOrRejct = (approvalAction: number) => {
    if (
      user?.hasSoftToken === false &&
      (formPayload.transactionPin === "" || formPayload.token === "")
    ) {
      setFormPayload({ ...formPayload, ...{ remark: "" } });
      setRemarkMessage("");
      setRejectionModal(false);
      return ToastNotify({
        type: "error",
        message: "Please enter a pin and hard token to continue.",
      });
    }
    if (user?.hasSoftToken === true && formPayload.token === "") {
      setFormPayload({ ...formPayload, ...{ remark: "" } });
      setRemarkMessage("");
      setRejectionModal(false);
      return ToastNotify({
        type: "error",
        message: "Please enter a token to continue.",
      });
    }

    if (
      user?.hasSoftToken === false &&
      formPayload.transactionPin.length !== 4
    ) {
      //error
      setFormPayload({ ...formPayload, ...{ remark: "" } });
      setRemarkMessage("");
      setRejectionModal(false);
      return ToastNotify({
        type: "error",
        message: "Pin must be a 4 digit number.",
      });
    }

    if (formPayload.token.length !== 6) {
      //error
      setFormPayload({ ...formPayload, ...{ remark: "" } });
      setRemarkMessage("");
      setRejectionModal(false);
      return ToastNotify({
        type: "error",
        message: "Token must be a 6 digit number.",
      });
    }

    if (approvalAction === 2 && formPayload.remark === "") {
      return setRemarkMessage("Please enter a reason for rejection");
    }

    const body = {
      mappedAccounts: user.accounts.map((x: any) => x.accountNo),
      correlationId,
      isSoftToken: user?.hasSoftToken,
      approvalAction: approvalAction,
      ...formPayload,
    };

    toggleLoading(true);
    setRejectionModal(false);
    dispatch(PUT_APROVE_OR_REJECT_TRANSACTION_ACTION(body))
      .unwrap()
      .then((res) => {
        // console.log("res", res);
        if (approvalAction === 2) {
          setFormPayload({ ...formPayload, ...{ remark: "" } });
          setRemarkMessage("");
          setRejectionModal(false);
        }
        toggleLoading(false);
        if (res.status === 200) {
          dispatch(setPendingTransactionsResponse(res?.data));
          setTimeout(() => {
            navigate(
              approvalAction === 1
                ? "/payments/verify/transaction/approved"
                : "/payments/verify/transaction/reject"
            );
          }, 500);
        }
      })
      .catch(() => {
        setRejectionModal(false);
        navigate("/payments/verify/transaction/reject");
      });
  };
  return (
    <DashboardWrapper>
      {load && <PageLoader />}
      {/* {loading.includes("PUT_APROVE_OR_REJECT_TRANSACTION") && <Spinner />}
      {loading.includes("GET_PENDING_TRANSACTION") && <Spinner />} */}
      <div className="md:px-20">
        <div className="text-xl font-semibold leading-tight py-3">
          Review Transaction
        </div>
        <div className="bg-white w-full mx-auto h-[1000px] mt-14">
          <div className="flex justify-between items-center">
            <BackArrow
              id="back_btn"
              className="ml-5 cursor-pointer"
              onClick={() => navigate("/payments/pending-transactions")}
            />
            <h3 className="font-normal" id="reviewType_text">
              {type} {type === "Bulk transfer" && "request"}
            </h3>
            <PrimaryButton
              id="pendingApproval_btn"
              containerVariant="w-36 h-10 rounded-bl-lg flex justify-center cursor-pointer rounded-t-sm bg-[#FFEBCC]"
              variant="font-bold text-xs text-[#F90] bg-[#FFEBCC]"
              labelText="Pending Approval"
              handleClick={() => null}
            />
          </div>
          <div className="flex gap-5 pl-10 my-10">
            <div>
              <h3 className="text-lg text-[#333333] font-semibold">
                Initiator:
              </h3>
            </div>
            <div className="flex flex-col gap-1">
              <p id="initiator-name_text">
                {initiatedByFullName &&
                  initiatedByFullName?.charAt(0)?.toUpperCase() +
                    initiatedByFullName?.slice(1)}
              </p>
              <p className="text-[#606060] text-xs" id="initiatedBy_text">
                {initiatedBy &&
                  initiatedBy?.charAt(0)?.toUpperCase() + initiatedBy?.slice(1)}
              </p>
            </div>
          </div>
          <hr />
          <div className="pl-10 text-lg text-[#333333] font-semibold my-10">
            Beneficiary Details
          </div>
          <p className="pl-10">{type}</p>
          {type === "Multiple transfer" || type === "Bulk transfer" ? (
            <div className="flex gap-5 pl-10 my-5 flex-col mb-10">
              <table className="w-[400px]">
                <tbody className="text-sm">
                  <tr>
                    <td>Group:</td>
                    <td className="font-semibold py-2" id="groupname_text">
                      New group
                    </td>
                  </tr>
                  <tr>
                    <td>Number of recipients:</td>
                    <td
                      className="font-semibold py-2"
                      id="numberOfRecipients_text"
                    >
                      {count}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td
                      onClick={() => {
                        type === "Multiple transfer" &&
                          setBeneficiaryModal(true);
                        type === "Bulk transfer" &&
                          navigate("/payments/bulk/recipent-list");
                      }}
                      className="font-semibold text-red-500 underline cursor-pointer py-2"
                      id="view-recipients_btn"
                    >
                      Click here to view recipients
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className=""></table>
            </div>
          ) : (
            <div className="flex gap-5 pl-10 my-5 flex-col mb-10">
              <table className="w-[400px]">
                <tbody className="text-sm">
                  {beneficiaryDetails?.map((x: any, index: any) => (
                    <>
                      <tr>
                        <td>Bank:</td>
                        <td
                          className="font-semibold py-2"
                          id={`bankname-${index}_text`}
                        >
                          {x?.bankName}
                        </td>
                      </tr>
                      <tr>
                        <td>Account name:</td>
                        <td
                          className="font-semibold py-2"
                          id={`accountName-${index}_text`}
                        >
                          {x?.accountName}
                        </td>
                      </tr>
                      <tr>
                        <td>Account number:</td>
                        <td
                          className="font-semibold py-2"
                          id={`accountNumber-${index}_text`}
                        >
                          {x?.accountNumber}
                        </td>
                      </tr>
                      <hr />
                    </>
                  ))}
                </tbody>
              </table>
              <table className=""></table>
            </div>
          )}
          <hr />
          <div className="px-10 py-10">
            <h3 className="text-lg text-[#333333] font-semibold">
              Transaction Summary
            </h3>
          </div>
          <div className="border w-11/12 mx-auto rounded-lg overflow-x-auto">
            <table className=" w-full px-20 mx-auto">
              <tbody>
                <tr className="border-b bg-gray-100 rounded-t-lg">
                  <td className="px-4 py-2">Transaction type</td>
                  <td className="px-4 py-2">Account to be debited</td>
                  <td className="px-4 py-2">Principal Amount</td>
                  <td className="px-4 py-2">Transaction Charges</td>
                  <td className="px-4 py-2">Total Amount</td>
                </tr>
                <tr>
                  <td className="px-4 py-2" id="transaction-type_text">
                    {type}
                  </td>
                  <td className="px-4 py-2" id="sourceAccountNumber_text">
                    {sourceAccountNumber}
                  </td>
                  <td className="px-4 py-2" id="totalAmount_text">
                    {sourceAccountCurrency} {Number(totalAmount).toFixed(2)}
                  </td>
                  <td className="px-4 py-2" id="totalCharge_text">
                    {Number(totalCharge).toFixed(2)}
                  </td>
                  <td className="px-4 py-2" id="totalAmountAndCharge_text">
                    {sourceAccountCurrency}{" "}
                    {(Number(totalAmount) + Number(totalCharge)).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="ml-10 w-72 mt-10">
            {user?.hasSoftToken && (
              <TextInput
                id="softToken_input"
                label="Enter soft token"
                name="softToken"
                type="password"
                isPin
                maxLength={6}
                handleChange={(event) => {
                  setFormPayload({ ...formPayload, token: event.target.value });
                }}
                variant="border text-black w-full h-10 text-lg px-5"
                value={formPayload.token}
              />
            )}
            {!user?.hasSoftToken && (
              <>
                <TextInput
                  id="pin_input"
                  label="Enter PIN"
                  placeHolder="Enter pin"
                  type="password"
                  isPin
                  name="pin"
                  maxLength={4}
                  handleChange={(event) => {
                    setFormPayload({
                      ...formPayload,
                      transactionPin: event.target.value,
                    });
                  }}
                  variant="border text-black w-full h-10 text-lg px-5"
                  value={formPayload.transactionPin}
                />
                <p className="text-right text-[#A90836] text-sm pt-4 -mb-5 cursor-pointer">
                  Don’t have token?
                </p>
                <TextInput
                  id="hardToken_input"
                  label="Enter hard token"
                  placeHolder="Enter hard token"
                  type="password"
                  isPin
                  name="hardToken"
                  maxLength={6}
                  handleChange={(event) => {
                    setFormPayload({
                      ...formPayload,
                      token: event.target.value,
                    });
                  }}
                  variant="border text-black w-full h-10 text-lg px-5"
                  value={formPayload.token}
                />
              </>
            )}
          </div>
          <div className="w-72 flex gap-5 mt-10 mx-10">
            <div
              id="approve_btn"
              className="w-full h-10 text-white bg-lib-alat-dark-red rounded-md flex justify-center items-center cursor-pointer"
              onClick={() => handleApproveOrRejct(1)}
            >
              Approve
            </div>
            <div
              id="reject_btn"
              className="w-full h-10 text-lib-alat-dark-red bg-white border border-[#A90836] rounded-md flex justify-center items-center cursor-pointer"
              onClick={() => {
                if (user?.hasSoftToken && formPayload.token !== "") {
                  return setRejectionModal(true);
                }
                if (
                  !user?.hasSoftToken &&
                  formPayload.token !== "" &&
                  formPayload.transactionPin !== ""
                ) {
                  return setRejectionModal(true);
                }

                if (user?.hasSoftToken && formPayload.token === "") {
                  ToastNotify({
                    type: "error",
                    message: "Please enter token to continue.",
                  });
                }

                if (
                  !user?.hasSoftToken &&
                  (formPayload.token === "" ||
                    formPayload.transactionPin === "")
                ) {
                  ToastNotify({
                    type: "error",
                    message: "Please enter pin and hard token to continue.",
                  });
                }
              }}
            >
              Reject
            </div>
          </div>
        </div>
      </div>

      {/* Beneficiaries modal */}
      {openBeneficiaryModal && (
        <div
          className="fixed top-0 left-0 flex justify-center items-center w-full h-full bg-black bg-opacity-10 p-5 z-[999999] overflow-hidden backdrop-filter backdrop-blur-sm"
          style={{ margin: 0 }}
        >
          <center className="z-10">
            <div className="flex flex-col py-5 px-5 w-[700px] h-[560px] relative mb-4 bg-white rounded-lg overflow-scroll">
              <p
                id="close-modal_btn"
                className="text-right text-xs absolute right-5 top-5 cursor-pointer"
                onClick={() => {
                  setBeneficiaryModal(false);
                }}
              >
                <ModalClose />
              </p>
              <div className="text-center text-lg font-bold py-5">
                Group details
              </div>
              <div className="flex text-lg font-normal pb-5">
                <div className="text-left ml-3">#</div>
                <div className="text-center pl-20 ml-40">Recipients</div>
              </div>
              <table
                style={{ height: "10px" }}
                className="table-auto min-w-max md:w-full overflow-x-auto text-xs font-semibold text-center py-2 p-1 px-4"
              >
                <tbody>
                  {beneficiaryDetails?.map((x: any, index: any) => (
                    <tr
                      className="text-[#333333] odd:bg-[#F7F7F7] even:bg-white"
                      key={index}
                    >
                      <td
                        className="py-10 font-light text-sm px-4 text-left"
                        id={`sn-${index}_text`}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="py-10 font-light text-sm px-4 text-left"
                        id={`accountName-${index}_text`}
                      >
                        {x?.accountName}
                      </td>
                      <td
                        className="py-10 font-light text-sm px-4 text-left"
                        id={`formattedAmount-${index}_text`}
                      >
                        {formatNumberToCurrency({
                          currencyCode: x?.currency,
                          number: x?.amount,
                        })}
                      </td>
                      <td
                        className="py-10 font-light text-sm px-4 text-left"
                        id={`bankAndAccount-${index}_text`}
                      >
                        {x?.bankName}-{x?.accountNumber}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <p className="text-red-500 text-sm text-left" id="remark_text">
                {remarkMessage}
              </p>
            </div>
          </center>
        </div>
      )}

      {/* Rejection reason Modal */}
      {rejectionModal && (
        <div
          className="fixed top-0 left-0 flex justify-center items-center w-full h-full bg-black bg-opacity-10 p-5 z-[999999] overflow-hidden backdrop-filter backdrop-blur-sm"
          style={{ margin: 0 }}
          id="rejection_modal"
        >
          <center className="z-10">
            <div className="flex flex-col py-5 px-10 w-[700px] h-[560px] relative mb-4 bg-white rounded-lg relative">
              <p
                id="closeRejectionModal_btn"
                className="text-right text-xs absolute right-5 top-5 cursor-pointer"
                onClick={() => {
                  setFormPayload({ ...formPayload, ...{ remark: "" } });
                  setRejectionModal(false);
                }}
              >
                <ModalClose />
              </p>
              <div className="text-center text-lg font-bold py-5">
                Please provide a reason for rejection
              </div>
              <textarea
                name="rejectionReason"
                id="rejectionReason"
                cols={4}
                rows={7}
                maxLength={100}
                placeholder="Type your reason here.."
                className="bg-gray-50 p-4 border outline-none rounded-md"
                value={formPayload.remark}
                onChange={(event) =>
                  setFormPayload({
                    ...formPayload,
                    ...{ remark: event.target.value },
                  })
                }
              />
              <div className="mt-2 flex items-center justify-between">
                <p
                  className="text-red-500 text-sm text-left"
                  id="remarkMessage"
                >
                  {remarkMessage}
                </p>
                <label
                  className="text-right"
                  id="remarkLength"
                >{`${formPayload?.remark?.length} / 100`}</label>
              </div>
              <div className="w-full flex gap-5 mt-10 mx-auto">
                <div
                  id="returnToDashboard_btn"
                  className="w-full py-3 h-10 text-lib-alat-dark-red bg-white text-bold border border-lib-alat-dark-red rounded-md flex justify-center items-center cursor-pointer text-xs"
                  onClick={() => navigate("/user-dashboard")}
                >
                  Return to dashboard
                </div>
                <div
                  id="confirmRejection_btn"
                  className="w-full py-3 h-10 text-white bg-lib-alat-dark-red rounded-md flex justify-center items-center cursor-pointer text-xs"
                  onClick={() => handleApproveOrRejct(2)}
                >
                  Confirm rejection
                </div>
              </div>
            </div>
          </center>
        </div>
      )}
    </DashboardWrapper>
  );
};

export default TransactionDetail;
