import { ReusableCard } from "components/reusables/Page/ReusableCard";
import { Formik, Form } from "formik";
import React, { useEffect } from "react";
import {
  FormikButton,
  PasswordInput,
  TextInput,
} from "alat-components-library";
import { formatNumberToCurrency, handleErrors } from "utils/helperFunctions";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { useUtilityCard } from "../hooks/useUtilityCard";
import { CardRequestPayload } from "types/global";
import { useRequestCard } from "hooks/apis/Cards/utilityCards/useRequestCard";
import PageLoader from "components/Loader/PageLoader";

interface IAuth {
  softToken: string;
  hardTokenOtp: string;
  hardTokenPin: string;
  pin: string;
}
const SummaryForm = ({
  cardRequestPayload,
  handlePrev,
  onNext,
}: {
  cardRequestPayload: CardRequestPayload | null;
  handlePrev: () => void;
  onNext: (val: number, msg?: string) => void;
}) => {
  const auth = useAuthState();
  const { charges } = useUtilityCard();
  const { handleRequestCard, isLoading, responseData, status } =
    useRequestCard();

  const authType = () => {
    if (auth?.user?.hasTransactionPin) return "pin";
    if (auth?.user?.hasSoftToken && !auth?.user?.hasTransactionPin)
      return "soft";
    return "hard";
  };
  // const onSubmit = (values: IAuth) => {
  const onSubmit = (values: IAuth) => {
    const isSoftToken = authType() === "soft";
    const isPin = authType() === "pin";
    const isHardToken = authType() === "hard";
    if (isSoftToken && !values.softToken) {
      handleErrors("Please enter your soft token to proceed.");
      return;
    }
    if (isPin && !values.pin) {
      handleErrors("Please enter your pin to proceed.");
      return;
    }
    if (isHardToken && !values.hardTokenPin) {
      handleErrors("Please enter your hard token pin to proceed.");
      return;
    }
    if (isHardToken && !values.hardTokenOtp) {
      handleErrors("Please enter your hard token otp to proceed.");
      return;
    }
    const isToken = authType() === "soft";

    const payload = {
      ...cardRequestPayload,
      isSoftToken: isToken,
      token: values.softToken || values.hardTokenOtp,
      transactionPin: values.pin || values.hardTokenPin,
    } as CardRequestPayload;
    handleRequestCard(payload);
  };
  // Navigate screen
  useEffect(() => {
    if (status) {
      const msg = responseData;
      onNext(4, msg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <>
      {isLoading && <PageLoader />}

      <ReusableCard
        cardTitle="Payment Summary"
        className="mt-0 border-b border-alat-gray-input-border"
        variant="md:w-[48rem] w-full mx-auto bg-white border border-alat-gray-input-border rounded-lg"
        handleBackNavigation={handlePrev}
        showGoBackIcon
      >
        <div className="max-w-[411px] mx-auto">
          <Formik
            initialValues={{
              softToken: "",
              hardTokenOtp: "",
              hardTokenPin: "",
              pin: "",
            }}
            onSubmit={onSubmit}
            validateOnChange
          >
            {({
              handleChange,
              handleBlur,
              errors,
              touched,
              isValid,
              dirty,
              values,
            }) => (
              <Form>
                <div>
                  <div className="flex flex-wrap justify-center gap-4 ">
                    <div className="text-alat-grey relative w-full lg:max-w-sm mb-2 pb-5">
                      <div className="bg-white mb-4 rounded-lg border px-4 pt-1 mt-4">
                        <div className="border-b py-4 flex justify-between items-center">
                          <p className="text-sm">Card Type</p>
                          <p className="text-sm">Verve debit card</p>
                        </div>
                        <div className="border-b py-4 flex justify-between items-center">
                          <p className="text-sm">Card Fee</p>
                          <p className="text-sm">
                            {formatNumberToCurrency({
                              number: cardRequestPayload
                                ? charges[
                                    cardRequestPayload?.deliveryOption - 1
                                  ]?.cardFees
                                : 0,
                              precision: 2,
                            })}
                          </p>
                        </div>
                        <div className="border-b py-4 flex justify-between items-center">
                          <p className="text-sm">Delivery</p>
                          <p className="text-sm">
                            {formatNumberToCurrency({
                              number: cardRequestPayload
                                ? charges[
                                    cardRequestPayload?.deliveryOption - 1
                                  ]?.deliveryFee
                                : 0,
                              precision: 2,
                            })}
                          </p>
                        </div>
                        <div className="border-b py-4 flex justify-between items-center">
                          <p className="text-sm">VAT</p>
                          <p className="text-sm">
                            {formatNumberToCurrency({
                              number: cardRequestPayload
                                ? charges[
                                    cardRequestPayload?.deliveryOption - 1
                                  ]?.vat
                                : 0,
                              precision: 2,
                            })}
                          </p>
                        </div>
                        <div className="py-4 flex justify-between items-center">
                          <p className="text-sm">Total Charge</p>
                          <p className="text-sm">
                            {formatNumberToCurrency({
                              number: cardRequestPayload?.totalDebitAmount || 0,
                              precision: 2,
                            })}
                          </p>
                        </div>
                      </div>
                      <div className="py-4 space-y-4">
                        {authType() === "pin" && (
                          <PasswordInput
                            name={"pin"}
                            label={"Enter AFB PIN"}
                            placeHolder={""}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.pin}
                            hasError={errors?.pin && touched?.pin}
                            error={errors?.pin}
                            iconStyle={{
                              wrapper: "",
                              icon: "w-[3.5vh] h-[3.5vh]",
                            }}
                            maxLength={4}
                          />
                        )}
                        {authType() === "soft" && (
                          <TextInput
                            id="token_input"
                            name={"softToken"}
                            type={"password"}
                            label={"Enter Soft Token"}
                            placeHolder={""}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.softToken}
                            hasError={errors?.softToken && touched?.softToken}
                            error={errors?.softToken}
                            maxLength={6}
                          />
                        )}
                        {authType() === "hard" && (
                          <>
                            <PasswordInput
                              name={"hardTokenPin"}
                              label={"Enter Hard Token PIN"}
                              placeHolder={""}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              value={values.hardTokenPin}
                              hasError={
                                errors?.hardTokenPin && touched?.hardTokenPin
                              }
                              error={errors?.hardTokenPin}
                              iconStyle={{
                                wrapper: "",
                                icon: "w-[3.5vh] h-[3.5vh]",
                              }}
                              maxLength={4}
                            />
                            <TextInput
                              id="token_input"
                              name={"hardTokenOtp"}
                              type={"password"}
                              label={"Enter Hard Token OTP"}
                              placeHolder={""}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              value={values?.hardTokenOtp}
                              hasError={
                                errors?.hardTokenOtp && touched?.hardTokenOtp
                              }
                              error={errors?.hardTokenOtp}
                              maxLength={6}
                            />
                          </>
                        )}
                      </div>
                      <div className="relative w-full py-6 mb-2  flex items-center justify-center">
                        <FormikButton
                          id="proceed"
                          labelText="Proceed"
                          isDisabled={!isValid && !dirty}
                          containerVariant={`w-full h-[48px] rounded-md flex justify-center item-center`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ReusableCard>
    </>
  );
};

export default SummaryForm;
