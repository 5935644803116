import DashboardWrapper from "components/Layouts/DashboardWrapper";
import PageHeader from "components/reusables/Page/PageHeader";
import ChangeCardPinTab from "./Components/debitCard/ChangeCardPin";
import { TabsRef, Tabs, Flowbite } from "flowbite-react";
import { useRef } from "react";
import { tabTheme } from "utils/tailwindTheme";
import { useNavigate } from "react-router-dom";
import UtilityCardPinChange from "./Components/utilityCard/UtilityCardPinChange";

const ChangeCardPin = () => {
  const navigate = useNavigate();
  const activeTab = window.location.pathname;
  const tabsRef = useRef<TabsRef>(null);

  const theme = tabTheme;
  return (
    <DashboardWrapper>
      <div className="container-fluid 2xl:container mx-auto ">
        <div className="container-fluid 2xl:container mx-auto">
          <main className="h-full">
            <div className="py-8">
              <div className="max-w-6xl mx-auto">
                <div className="rounded-3xl mb-5">
                  <div className="ml-4 pt-4">
                    <PageHeader title="Change Card PIN" />
                  </div>
                  {/* Change Card PIN Tab */}
                  <Flowbite theme={{ theme }}>
                    <Tabs
                      aria-label="Default tabs"
                      // eslint-disable-next-line react/style-prop-object
                      variant="underline"
                      ref={tabsRef}
                      onActiveTabChange={(
                        tab: React.SetStateAction<number>
                      ) => {
                        let url = "/card/card-pin";
                        if (tab === 1) url = "/card/card-pin/utility";
                        navigate(url);
                      }}
                      className=" !border-[#E4DFDF] w-full"
                    >
                      <Tabs.Item
                        active={
                          activeTab.split("/")[
                            activeTab.split("/").length - 1
                          ] === "card-pin"
                        }
                        title="Corporate Debit Cards"
                        className=""
                        id="corporate-debit-card_tab"
                      >
                        <ChangeCardPinTab />
                      </Tabs.Item>

                      <Tabs.Item
                        active={
                          activeTab.split("/")[
                            activeTab.split("/").length - 1
                          ] === "utility"
                        }
                        title="Utility Cards"
                        className=" !bg-purple-800"
                        id="utility-cards_tab"
                      >
                        <UtilityCardPinChange />
                      </Tabs.Item>
                    </Tabs>
                  </Flowbite>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default ChangeCardPin;
