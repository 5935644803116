import { useCallback, useEffect, useState } from "react";
import { ToastNotify } from "../../../helpers/toastNotify";
import { useApiRequest } from "../../useApiRequest";
import { GET_SME_LOAN_BLACKLISTED_STATES } from "../../../utils/apis/routes";
import useReverseGeocoding from "use-reverse-geocoding";

export const useSmeLoansBlacklistData = () => {
  const makeRequest = useApiRequest();
  // const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isBlacklisted, setIsBlacklisted] = useState(false);
  const [currentAddress, setCurrentAddress] = useState<string>("");
  const [blacklistedStates, setBlacklistedStates] = useState<string[]>([]);
  const [geoLocationError, setGeoLocationError] = useState<string>("");
  const [coordinates, setCoordinates] = useState<{ lat: number; long: number }>(
    {
      lat: 0,
      long: 0,
    }
  );

  const { loading, error, address } = useReverseGeocoding(
    coordinates?.lat,
    coordinates?.long
  );

  // Get Geo Location
  const handleGeoLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setGeoLocationError("");

          setCoordinates({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
        },
        (error) => {
          const err = error?.message;
          setGeoLocationError(err);
        }
      );
    } else {
      ToastNotify({
        type: "error",
        message: "Geolocation is not supported by your browser.",
      });
    }
  };

  // Check if it's blacklisted
  const handleBlacklistCheck = useCallback(() => {
    if (!error && !loading && (!!address || currentAddress)) {
      // Address comes at least once, it is saved in state once it comes
      const isNotSameAddress = address && address !== currentAddress;
      if (isNotSameAddress) {
        setCurrentAddress(address);
      }
      const lowerCaseAddress = (address || currentAddress).toLowerCase();
      const blacklisted = blacklistedStates.some((state) => {
        // Match exact state name followed by "state" ignoring spaces/punctuation
        const regex = new RegExp(`\\b${state}\\s+state\\b`, "i");
        return regex.test(lowerCaseAddress);
      });
      return setIsBlacklisted(blacklisted);
    } else {
      setIsBlacklisted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, loading, address, blacklistedStates]);

  useEffect(() => {
    // Validate blacklisted states
    if (!loading) {
      handleBlacklistCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, loading, error, blacklistedStates]);

  const handleFetchBlacklistedStates = async () => {
    setIsLoading(true);
    // Fetch Geo location
    handleGeoLocation();
    try {
      const response = await makeRequest({
        method: "get",
        route: GET_SME_LOAN_BLACKLISTED_STATES,
        originType: "adhocservice",
      });
      if (response?.data?.httpStatusCode === 200) {
        const states = response?.data?.data;
        setBlacklistedStates(states);
      } else {
        setBlacklistedStates([]);
        ToastNotify({
          type: "error",
          message:
            response?.data?.status === 500
              ? "Oops! Something went wrong"
              : `${response?.data?.data?.responseMessage?.title}. ${response?.data?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }
    } catch (err: any) {
      setBlacklistedStates([]);
      if (err?.response?.status === 500) {
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        ToastNotify({
          type: "error",
          message:
            err?.response?.data?.responseMessage?.title ||
            err?.response?.data?.responseMessage?.actionMessage ||
            "",
          position: "top-right",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  const generalLoading = isLoading || loading;
  return {
    handleFetchBlacklistedStates,
    generalLoading,
    isBlacklisted,
    geoLocationError,
  };
};
