import React, { useEffect } from "react";
import { ActionCard } from "./ActionCard";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { ToastNotify } from "helpers/toastNotify";
import { useFetchPrequalifiedAmount } from "hooks/apis/Loans/useFetchPrequalifiedAmount";
import { useLoansState } from "react-wrappers/stores/redux/loans/slices";
import PageLoader from "components/Loader/PageLoader";
import { ReactComponent as MoneyBagIcon } from "assets/svgs/prequalified-loan-bag.svg";
import { ReactComponent as RightDirectionArrow } from "assets/svgs/direction-right.svg";
import { formatNumberToCurrency } from "utils/helperFunctions";
import { useSmeLoansBlacklistData } from "hooks/apis/Loans/useSmeLoansBlacklist";

const QuickActions = ({ quickActionList }: { quickActionList: object[] }) => {
  // Redux State
  const authState = useAuthState();
  const navigate = useNavigate();

  const { handleFetchPrequalifiedAmount } = useFetchPrequalifiedAmount();
  const { prequalifiedLoanAmount, loading } = useLoansState();
  const isSoleProprietor = authState?.user?.role === "SoleProprietor";

  const { handleFetchBlacklistedStates, geoLocationError, isBlacklisted } =
    useSmeLoansBlacklistData();

  useEffect(() => {
    if (isSoleProprietor) {
      handleFetchPrequalifiedAmount();
      handleFetchBlacklistedStates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSoleProprietor]);

  const isLoading = loading?.includes("fetch-prequalified-amount");

  const showLoanCard =
    !!prequalifiedLoanAmount && !isBlacklisted && !geoLocationError;

  return (
    <>
      {isLoading && <PageLoader message="" />}
      <div className="bg-white rounded-lg p-4 relative">
        <div className="w-full h-full space-y-2">
          <div className="text-xl font-semibold flex justify-between">
            <p className="text-left">Quick Actions</p>
            {/* <p className="text-right text-xs text-lib-alat-dark-red cursor-pointer font-bold">EDIT</p> */}
          </div>
          <div className="grid grid-cols-3 mt-7">
            {quickActionList.map((item: any, index: number) => (
              // eslint-disable-next-line
              <div key={index}>
                <ActionCard
                  id={item.id}
                  clickEvent={() => {
                    if (
                      item.title === "Send Money" &&
                      authState.userRole !== "initiator" &&
                      authState.userRole !== "soleproprietor" &&
                      authState.userRole !== "initiatorapprover"
                    ) {
                      return ToastNotify({
                        type: "error",
                        message:
                          "This account role is not enabled to do transfer",
                        position: "top-center",
                      });
                    }

                    // Privileges for account statement : "soleproprietor | approver | initiatorapprover | initiator"

                    // if (
                    //   item.title === "Account Statement" &&
                    //   (authState?.userRole === "verifier" ||
                    //     authState?.userRole === "initiator" ||
                    //     authState?.userRole === "viewer")
                    // ) {
                    //   // console.log("dont allow")
                    //   return ToastNotify({
                    //     type: "error",
                    //     message:
                    //       "This account role is not enabled to request for account statement",
                    //     position: "top-center",
                    //   });
                    // }

                    item.path && navigate(item.path);
                  }}
                  title={item.title}
                  info={item.info}
                  icon={<img src={item.icon} alt="trf" width="48rem" />}
                />
              </div>
            ))}
          </div>
          {["soleproprietor"]?.includes(authState?.userRole?.toLowerCase()) && (
            <div
              onClick={() => navigate("/loans/current")}
              className={`${showLoanCard ? "flex" : "hidden"} cursor-pointer items-center justify-between  w-full rounded-[10px] px-[34px] py-[52px] h-[157px] border border-lib-alat-red`}
            >
              <div className="flex w-full gap-4 items-center">
                <div className="">
                  <MoneyBagIcon />
                </div>
                <div className="flex justify-center flex-col gap-2">
                  <p className="font-semibold text-[14px] leading-[16.94px] tracking-[-5%]">
                    Pre-approved Loan Offer
                  </p>
                  <div className="gap-1">
                    <p className="text-[#606060] text-[12px] leading-[18px] tracking-[-2%]">
                      You are currently qualified for a loan offer of{" "}
                    </p>
                    <p className="text-[#606060] text-[12.09px] leading-[17.5px]">
                      {formatNumberToCurrency({
                        number: prequalifiedLoanAmount,
                        precision: 0,
                      })}
                    </p>
                  </div>
                </div>
              </div>
              <RightDirectionArrow />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default QuickActions;
