import { useState } from "react";
import { useApiRequest } from "../../../useApiRequest";
import { GET_CARD_UTILITY_CARDS } from "utils/apis/routes";
import { handleErrors } from "utils/helperFunctions";

export const useGetUtilityCards = () => {
  const makeRequest = useApiRequest();
  const [status, setStatus] = useState<boolean>(false);
  const [responseData, setResponseData] = useState({
    utilityCardResponses: null,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleGetUtilityCards = async () => {
    setIsLoading(true);
    setStatus(false);

    try {
      const response = await makeRequest({
        method: "get",
        route: GET_CARD_UTILITY_CARDS,
        originType: "identity",
      });

      setResponseData(response?.data?.data);
      if (response?.data?.httpStatusCode === 200) {
        setStatus(true);
      } else {
        setStatus(false);
        const hasErrors = response?.data?.data?.errors;
        const errorMsg = hasErrors
          ? Object.values(hasErrors)
          : response?.data?.data?.responseMessage?.title;
        handleErrors(errorMsg);
      }
    } catch (err: any) {
      if (err?.response?.status === 500) {
        handleErrors();
      } else {
        handleErrors(
          err?.response?.data?.responseMessage?.title ||
            err?.response?.data?.responseMessage?.actionMessage
        );
      }
    } finally {
      setIsLoading(false);
    }
  };
  return {
    handleGetUtilityCards,
    status,
    responseData,
    isLoading,
  };
};
