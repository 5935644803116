import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "flowbite-react";
import { ReactComponent as PenIcon } from "../../assets/svgs/pen-lg.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svgs/delete-lg.svg";
import { tableOptions } from "../../pages/Dashboard/dropdownItems";
import { PrimaryButton } from "../../alat-components-library";
import CenteredModal from "alat-components-library/Modal/CenterModal";
import { setCenteredModal } from "react-wrappers/stores/redux/app/slices";
import { useAppDispatch } from "hooks/useReduxHook";
import {
  resetAdminData,
  setDeleteUser,
  useAdminState,
} from "react-wrappers/stores/redux/admin/slices";
import { ReactComponent as SuccessIcon } from "../../alat-components-library/assets/images/modals/success-icon.svg";
import { CustomerDetails } from "react-wrappers/stores/redux/interfaces";
import { useDeleteUser } from "hooks/apis/Admin/useDeleteUser";
import { resetProfileData } from "react-wrappers/stores/redux/profile/slices";
import { POST_FETCH_USERS_ACTION } from "react-wrappers/stores/redux/profile/actions";
import PageLoader from "components/Loader/PageLoader";

interface UserListBodyProps {
  list: any[];
}

export const UsersListBody = ({ list }: UserListBodyProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { deleteUser, loading } = useAdminState();
  const [currentIndex, setCurrentIndex] = useState<any>();
  const [showOptions, setShowOptions] = useState(false);
  const [selectedUser, setSelectedUser] = useState<CustomerDetails>({});
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const { handleDeleteUser } = useDeleteUser();
  // const [showSuccess, setShowSuccess] = useState(deleteUser?.successStatus);

  //Function Handler
  const handleDropDown = (e: any, index: number) => {
    // now this part stops the click from propagating
    if (e.stopPropagation) e.stopPropagation();
    // let update = index;
    if (currentIndex === index) setShowOptions(!showOptions);
    setCurrentIndex(index);
  };
  return (
    <>
      {loading?.includes("delete-user") && <PageLoader />}
      {list?.length ? (
        <>
          {list?.map((item: any, index) => (
            <Table.Row
              key={item.id}
              className="h-14 hover:bg-afb-gray-one dark:hover:bg-afb-gray-one"
            >
              <Table.Cell className="whitespace-nowrap text-lib-alat-black flex flex-col text-sm">
                <p id={`fullname_${index + 1}_text`}>{item?.fullname}</p>
                <p className="text-xs" id={`username_${index + 1}_text`}>
                  {item?.username}
                </p>
              </Table.Cell>
              <Table.Cell
                className="text-lib-alat-black text-sm"
                id={`staffId_${index + 1}_text`}
              >
                {item?.staffId}
              </Table.Cell>
              <Table.Cell
                className="text-lib-alat-black text-sm"
                id={`mobileNo_${index + 1}_text`}
              >
                {item?.mobileNo}
              </Table.Cell>
              <Table.Cell
                className="text-lib-alat-black text-sm"
                id={`mappedStatus_${index + 1}_text`}
              >
                {item?.mappedStatus}
              </Table.Cell>
              <Table.Cell className="w-44">
                {item?.lockStatus?.toLowerCase() === "locked" && (
                  <div
                    style={{ background: "rgba(255, 153, 0, 0.2)" }}
                    className="text-[#FF9900] cursor-pointer border border-[#FF9900] px-2 py-1 text-sm text-center rounded"
                    id={`locked_status_${index + 1}_text`}
                  >
                    Locked
                  </div>
                )}
                {item?.lockStatus?.toLowerCase() === "unlocked" && (
                  <div
                    style={{ background: "rgba(59, 181, 74, 0.2)" }}
                    className="text-[#3BB54A] cursor-pointer border border-[#3BB54A] px-2 py-1 text-sm text-center rounded"
                    id={`unlocked_status_${index + 1}_text`}
                  >
                    Unlocked
                  </div>
                )}
                {item?.lockStatus?.toLowerCase() === "deactivated" && (
                  <div
                    style={{ background: "rgba(169, 8, 54, 0.2)" }}
                    className="text-[#A90836] cursor-pointer border border-[#A90836] px-2 py-1 text-sm text-center rounded"
                    id={`deactivated_status_${index + 1}_text`}
                  >
                    Deactivated
                  </div>
                )}
              </Table.Cell>
              <Table.Cell
                className="text-lib-alat-black text-sm"
                id={`role_${index + 1}_text`}
              >
                {item?.role?.toUpperCase()}
              </Table.Cell>

              <Table.Cell>
                <div className="flex w-full items-center gap-4">
                  <div
                    title="Edit user"
                    className="cursor-pointer"
                    onClick={() => {
                      navigate(`/view?username=${item?.username}`);
                    }}
                  >
                    <PenIcon />
                  </div>
                  <div
                    title="Delete user"
                    className="cursor-pointer"
                    onClick={() => {
                      setSelectedUser(item);
                      setShowDeleteModal(true);
                    }}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </>
      ) : (
        <>
          <div className="h-96  flex justify-center items-center ">
            <div className="text-center text-sm w-full absolute left-0 ">
              <div className="flex flex-col items-center gap-3">
                <p className="leading-4 text-[#C4C4C4]" id={`no_users_text`}>
                  You do not have any users
                </p>
                <PrimaryButton
                  id="add_new_user_btn"
                  labelText={"Add new"}
                  handleClick={() => navigate("/create-user")}
                  variant={"text-white"}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {showDeleteModal && (
        <CenteredModal
          id="delete_modal"
          idButton1="modal-proceed_btn"
          idCloseModal="modal-close_btn"
          title="Delete User?"
          info={""}
          btnText={"Proceed"}
          btnText2={"Cancel"}
          handleClick={() => {
            handleDeleteUser({
              customerId: selectedUser?.customerId,
            });
            dispatch(
              setCenteredModal({
                status: "",
                title: "",
                info: "",
              })
            );
          }}
          handleClick2={() => {
            setShowDeleteModal(false);
            dispatch(
              setCenteredModal({
                status: "",
                title: "",
                info: "",
              })
            );
          }}
          handleClose={(e: any) => {
            ("");
            setShowDeleteModal(false);
            dispatch(
              setCenteredModal({
                status: "",
                title: "",
                info: "",
              })
            );
          }}
          showCloseIcon
        />
      )}
      {deleteUser?.successStatus && (
        <CenteredModal
          id="success_modal"
          idButton1="modal-okay_btn"
          idCloseModal="modal-close_btn"
          icon={<SuccessIcon />}
          title="Success!"
          info={`${deleteUser?.responseMessage?.title}. ${deleteUser?.responseMessage?.actionMessage}`}
          btnText={"Okay"}
          handleClick={() => {
            dispatch(
              setDeleteUser({
                successStatus: false,
                responseMessage: {},
              })
            );
            dispatch(resetProfileData());
            // dispatch(resetAdminData());
            dispatch(
              POST_FETCH_USERS_ACTION({
                searchValue: "",
                requestFilter: 1,
                pageSize: 10,
                pageNumber: 1,
              })
            );
            dispatch(
              setCenteredModal({
                status: "",
                title: "",
                info: "",
              })
            );
          }}
          handleClose={(e: any) => {
            dispatch(
              setDeleteUser({
                successStatus: false,
                responseMessage: {},
              })
            );
            dispatch(
              setCenteredModal({
                status: "",
                title: "",
                info: "",
              })
            );
          }}
        />
      )}
    </>
  );
};
