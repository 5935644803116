import React, { useState } from "react";
import { ToastNotify } from "../../../helpers/toastNotify";
import { useApiRequest } from "../../useApiRequest";
import { useAppDispatch } from "../../useReduxHook";
import {
  setLoadingState,
  setNotificationsDetails,
} from "react-wrappers/stores/redux/notifications/slices";
import {
  GET_NOTIFICATION_TRANSACTION_DETAILS,
  GET_NOTIFICATION_USER_DETAILS,
  GET_NOTIFICATION_SOFT_TOKEN_DETAILS,
  GET_STAMPED_STATEMENT_DETAILS,
  GET_CHEQUE_CONFIRMATION_DETAILS,
} from "utils/apis/routes/notifications";

export const useFetchNotificationDetails = () => {
  const makeRequest = useApiRequest();
  const dispatch = useAppDispatch();
  const [userDetail, setUserDetail] = useState<any>([]);
  const [transactionDetail, setTransactionDetail] = useState<any>();
  const [softTokenDetail, setSoftTokenDetail] = useState<any>();

  const handleFetchUserDetails = async (payload: any) => {
    dispatch(setLoadingState(["fetch-notification-details"]));
    try {
      const response = await makeRequest({
        method: "get",
        route: GET_NOTIFICATION_USER_DETAILS(
          payload.correlationId,
          payload.notificationType
        ),
        originType: "identity",
      });
      if (response?.data?.httpStatusCode === 200) {
        dispatch(setNotificationsDetails(response?.data?.data));
        setUserDetail(response?.data?.data);
      } else {
        dispatch(setNotificationsDetails({}));
        ToastNotify({
          type: "error",
          message:
            response?.status === 500
              ? "Oops! Something went wrong"
              : `${response?.data?.data?.responseMessage?.title}. ${response?.data?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }
      dispatch(setLoadingState([]));
    } catch (err: any) {
      dispatch(setLoadingState([]));
      if (err?.response?.status === 500) {
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        ToastNotify({
          type: "error",
          message:
            err?.response?.data?.responseMessage?.title ||
            err?.response?.data?.responseMessage?.actionMessage,
          position: "top-right",
        });
      }
    }
  };

  const handleFetchTransactionDetails = async (correlationId: any) => {
    dispatch(setLoadingState(["fetch-notification-details"]));
    try {
      const response = await makeRequest({
        method: "get",
        route: GET_NOTIFICATION_TRANSACTION_DETAILS(correlationId),
        originType: "transferservice",
      });
      if (response?.data?.httpStatusCode === 200) {
        dispatch(setNotificationsDetails(response?.data?.data));
        setTransactionDetail(response?.data?.data);
      } else {
        dispatch(setNotificationsDetails({}));
        ToastNotify({
          type: "error",
          message:
            response?.status === 500
              ? "Oops! Something went wrong"
              : `${response?.data?.data?.responseMessage?.title}. ${response?.data?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }
      dispatch(setLoadingState([]));
    } catch (err: any) {
      dispatch(setLoadingState([]));
      if (err?.response?.status === 500) {
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        ToastNotify({
          type: "error",
          message:
            err?.response?.data?.responseMessage?.title ||
            err?.response?.data?.responseMessage?.actionMessage,
          position: "top-right",
        });
      }
    }
  };

  const handleFetchSoftTokenDetails = async (correlationId: any) => {
    dispatch(setLoadingState(["fetch-notification-details"]));
    try {
      const response = await makeRequest({
        method: "get",
        route: GET_NOTIFICATION_SOFT_TOKEN_DETAILS(correlationId),
        originType: "identity",
      });
      if (response?.data?.httpStatusCode === 200) {
        dispatch(setNotificationsDetails(response?.data?.data));
        setSoftTokenDetail(response?.data?.data);
      } else {
        dispatch(setNotificationsDetails({}));
        ToastNotify({
          type: "error",
          message:
            response?.status === 500
              ? "Oops! Something went wrong"
              : `${response?.data?.data?.responseMessage?.title}. ${response?.data?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }
      dispatch(setLoadingState([]));
    } catch (err: any) {
      dispatch(setLoadingState([]));
      if (err?.response?.status === 500) {
        ToastNotify({
          type: "error",
          message: "Oops! something went wrong",
          position: "top-right",
        });
      } else {
        ToastNotify({
          type: "error",
          message:
            err?.response?.data?.responseMessage?.title ||
            err?.response?.data?.responseMessage?.actionMessage,
          position: "top-right",
        });
      }
    }
  };
  const handleFetchStampedStatementDetails = async (correlationId: string) => {
    dispatch(setLoadingState(["fetch-notification-details"]));
    try {
      const response = await makeRequest({
        method: "post",
        route: GET_STAMPED_STATEMENT_DETAILS,
        originType: "transferservice",
        body: { correlationId },
      });
      if (response?.data?.httpStatusCode === 200) {
        dispatch(setNotificationsDetails(response?.data?.data));
      } else {
        dispatch(setNotificationsDetails({}));
        ToastNotify({
          type: "error",
          message:
            response?.status === 500
              ? "Oops! something went wrong"
              : `${response?.data?.data?.responseMessage?.title}. ${response?.data?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }
      dispatch(setLoadingState([]));
    } catch (err: any) {
      dispatch(setLoadingState([]));
      if (err?.response?.status === 500) {
        ToastNotify({
          type: "error",
          message: "Oops! something went wrong",
          position: "top-right",
        });
      } else {
        ToastNotify({
          type: "error",
          message:
            err?.response?.data?.responseMessage?.title ||
            err?.response?.data?.responseMessage?.actionMessage,
          position: "top-right",
        });
      }
    }
  };
  const handleFetchChequeConfirmationDetails = async (
    correlationId: string
  ) => {
    dispatch(setLoadingState(["fetch-notification-details"]));
    try {
      const response = await makeRequest({
        method: "post",
        route: GET_CHEQUE_CONFIRMATION_DETAILS,
        originType: "transferservice",
        body: { correlationId },
      });
      if (response?.data?.httpStatusCode === 200) {
        dispatch(setNotificationsDetails(response?.data?.data));
      } else {
        dispatch(setNotificationsDetails({}));
        ToastNotify({
          type: "error",
          message:
            response?.status === 500
              ? "Oops! something went wrong"
              : `${response?.data?.data?.responseMessage?.title}. ${response?.data?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }
      dispatch(setLoadingState([]));
    } catch (err: any) {
      dispatch(setLoadingState([]));
      if (err?.response?.status === 500) {
        ToastNotify({
          type: "error",
          message: "Oops! something went wrong",
          position: "top-right",
        });
      } else {
        ToastNotify({
          type: "error",
          message:
            err?.response?.data?.responseMessage?.title ||
            err?.response?.data?.responseMessage?.actionMessage,
          position: "top-right",
        });
      }
    }
  };
  return {
    handleFetchUserDetails,
    handleFetchTransactionDetails,
    handleFetchSoftTokenDetails,
    handleFetchStampedStatementDetails,
    handleFetchChequeConfirmationDetails,
    userDetail,
    transactionDetail,
    softTokenDetail,
  };
};
