import { lazy } from "react";
import { Storage } from "utils/inAppstorage";

const ViewUserDetails = lazy(() =>
  import("pages/Admin/ViewUserDetails").then(({ ViewUserDetails }) => ({
    default: ViewUserDetails,
  }))
);
const EditUserDetails = lazy(() =>
  import("pages/Admin/EditUserDetails").then(({ EditUserDetails }) => ({
    default: EditUserDetails,
  }))
);
const UserAccountComponentIntro = lazy(() =>
  import("pages/Admin/UsersAccounts/UserAccountComponentsIntro").then(
    ({ UserAccountComponentIntro }) => ({ default: UserAccountComponentIntro })
  )
);

const isProtected = Storage.getItem("afb-app-token");

export const routes = [
  {
    path: "/view",
    isAuthenticated: true,
    element: <ViewUserDetails />,
    allowedRoles: ["admin"],
  },
  {
    path: "/edit-user",
    isAuthenticated: true,
    element: <EditUserDetails />,
    allowedRoles: ["admin"],
  },
  {
    path: "/users/accounts/:tabPath",
    isAuthenticated: true,
    element: <UserAccountComponentIntro />,
    allowedRoles: ["admin"],
  },
];

const unProtectedRoutes: any = [];

export const adminRoutes = isProtected ? routes : unProtectedRoutes;
