import { useAuthState } from "../../react-wrappers/stores/redux/auth/slices";
import {
  setBulkTranferIntra,
  setPrevBulkTransferDestinationsLength,
  setBulkTransferPayloadReferenceIntra,
  // setBulkListPageNumber,
  usePaymentState,
} from "../../react-wrappers/stores/redux/payments/slices";
import { useAppDispatch } from "../../hooks/useReduxHook";
import { ToastNotify } from "../../helpers/toastNotify";
import { IntraTransferFormType } from "types/transfer";
import { TransactionTypeEnum } from "utils/enums";
import { useMultipleAccountNameEnquiry } from "hooks/apis/Payment/useMultipleAccountNameEnquiry";

export type ExcelSheetData = {
  rowId: number;
  beneficiaryAccountNumber: string;
  beneficiaryName: string;
  beneficiaryBankName: string;
  beneficiaryBankCode: string;
  amount: number;
  beneficiaryCurrency: string;
  narration: string;
  transactionFee: number;
  refCode: string;
  status?: string;
}[];

export const useIntraTransferController = (setActiveTab: any) => {
  // Redux State
  const paymentState = usePaymentState();
  const dispatch = useAppDispatch();
  const { user, userRole } = useAuthState();
  const { handleMultipleAccountNameEnquiry } = useMultipleAccountNameEnquiry({
    setActive: setActiveTab,
  });

  const bulkTransferTypes = [
    {
      id: 0,
      label: "Transfer to only Wema bank accounts",
      value: "Wema",
    },
    {
      id: 1,
      label: "Transfer to other bank accounts",
      value: "others",
    },
    {
      id: 2,
      label:
        "Transfer to both Wema and other bank accounts (Multiple debits only)",
      value: "both",
    },
  ];

  // Variable
  const initialValueData = {
    bulkTransferType: "Wema",
    narrationIntra: "",
    excelSheetintra: {
      file: {
        name: "",
      },
      cols: [],
      data: [],
    },
  };
  const sortExcelData = (excel: ExcelSheetData) => {
    let sheetData: ExcelSheetData = [];
    excel?.map(
      (
        item: {
          beneficiaryAccountNumber: string;
          beneficiaryName: string;
          beneficiaryBankName: string;
          beneficiaryBankCode: string;
          amount: number;
          beneficiaryCurrency: string;
          narration: string;
          transactionFee: number;
          refCode: string;
        },
        index
      ) => {
        return sheetData.push({
          rowId: index + 1,
          beneficiaryAccountNumber: item?.beneficiaryAccountNumber?.trim(),
          beneficiaryName: item?.beneficiaryName?.trim() ?? "",
          beneficiaryBankName: item?.beneficiaryBankName?.trim(),
          beneficiaryBankCode: item?.beneficiaryBankCode?.trim(),
          amount: item?.amount ?? 0,
          beneficiaryCurrency: item?.beneficiaryCurrency?.trim(),
          narration: item?.narration?.trim(),
          transactionFee: item?.transactionFee ?? 0,
          refCode: item?.refCode?.trim(),
          status: "Pending",
        });
      }
    );
    return sheetData;
  };

  const accountEnquiryPayload = (excel: ExcelSheetData) => {
    let accountsEnquired: {
      rowId: number;
      acctNum: string;
      bankCode: string;
      acctName: string;
      bank: string;
      desc: string;
      refCode: string;
      amount: number;
      fee: number;
    }[] = [];
    excel?.map(
      (
        item: {
          rowId: number;
          beneficiaryAccountNumber: string;
          beneficiaryName: string;
          beneficiaryBankName: string;
          beneficiaryBankCode: string;
          amount: number;
          beneficiaryCurrency: string;
          narration: string;
          transactionFee: number;
          refCode: string;
        },
        index
      ) => {
        return accountsEnquired.push({
          rowId: index + 1,
          acctNum: item?.beneficiaryAccountNumber?.trim(),
          bankCode: item?.beneficiaryBankCode?.trim(),
          acctName: item?.beneficiaryName.trim() ?? "",
          bank: item?.beneficiaryBankName?.trim(),
          desc: item?.narration?.trim(),
          refCode: item?.refCode?.trim(),
          amount: item?.amount,
          fee: item?.transactionFee,
        });
      }
    );
    return accountsEnquired;
  };

  const handleUploadExcelSubmit = async (values: IntraTransferFormType) => {
    // console.log("values", values);
    let transactionType;
    if (values?.bulkTransferType?.toLowerCase() === "wema")
      transactionType = TransactionTypeEnum.SingleSignatoryBulkIntraTransfer;
    if (
      ["initiator", "initiatorapprover"]?.includes(userRole?.toLowerCase()) &&
      values?.bulkTransferType?.toLowerCase() === "wema"
    )
      transactionType = TransactionTypeEnum.MultipleSignatoryBulkIntraTransfer;

    if (values?.bulkTransferType?.toLowerCase() === "others")
      transactionType = TransactionTypeEnum.SingleSignatoryBulkInterTransfer;
    if (
      ["initiator", "initiatorapprover"]?.includes(userRole?.toLowerCase()) &&
      values?.bulkTransferType?.toLowerCase() === "others"
    )
      transactionType = TransactionTypeEnum.MultipleSignatoryBulkInterTransfer;

    if (values?.bulkTransferType?.toLowerCase() === "both")
      transactionType =
        TransactionTypeEnum.SingleSignatoryBulkCombinationTransfer;
    if (
      ["initiator", "initiatorapprover"]?.includes(userRole?.toLowerCase()) &&
      values?.bulkTransferType?.toLowerCase() === "both"
    )
      transactionType =
        TransactionTypeEnum.MultipleSignatoryBulkCombinationTransfer;

    //Validate
    if (values.excelSheetintra.data.length <= 0) {
      return ToastNotify({
        type: "error",
        message: "Please upload a bulk transfer document to continue.",
        position: "top-right",
      });
    }

    // console.log("test", transactionType)

    const payload = {
      transactionType,
      transferDestinations: sortExcelData(values.excelSheetintra.data),
      narration: values.narrationIntra,
      isSoftToken: user?.hasSoftToken,
    };

    const enquiryPayload = {
      accountsEnquired: accountEnquiryPayload(values.excelSheetintra.data),
    };

    // dispatch(
    //   setPrevBulkTransferDestinationsLength(
    //     sortExcelData(values.excelSheetintra.data)?.length
    //   )
    // );
    // dispatch(setBulkListPageNumber(1));
    // Update the Bulk Trasnfer Payload
    dispatch(
      setBulkTranferIntra({
        ...paymentState.bulkTransferPayloadIntra,
        ...payload,
      })
    );
    // Update the reference Bulk Transfer Payload
    dispatch(
      setBulkTransferPayloadReferenceIntra({
        ...paymentState.bulkTransferPayloadIntra,
        ...payload,
      })
    );

    // console.log("tetsadf", {
    //   ...paymentState.bulkTransferPayloadIntra,
    //   ...payload,
    // });

    if (paymentState.validateBulkTransferAccounts) {
      handleMultipleAccountNameEnquiry(enquiryPayload, {
        ...paymentState.bulkTransferPayloadIntra,
        ...payload,
      });
    } else {
      //Redirect to excel list screen
      setActiveTab(`excel-list-intra-tab`);
    }

    // dispatch(POST_BULK_TRANSFER_ACTION(payload));
  };

  return {
    initialValueData,
    handleUploadExcelSubmit,
    bulkTransferTypes,
    accountEnquiryPayload,
  };
};
