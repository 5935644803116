import { lazy } from "react";
import { Storage } from "utils/inAppstorage";

const InitiateRequestForm = lazy(() =>
  import("pages/POSRequest/InitiateRequestForm").then(
    ({ InitiateRequestForm }) => ({
      default: InitiateRequestForm,
    })
  )
);
const CompleteRequestForm = lazy(() =>
  import("pages/POSRequest/CompleteRequestForm").then(
    ({ CompleteRequestForm }) => ({
      default: CompleteRequestForm,
    })
  )
);
const RequestSuccessful = lazy(() =>
  import("pages/POSRequest/RequestSuccessful").then(
    ({ RequestSuccessful }) => ({
      default: RequestSuccessful,
    })
  )
);
const RequestFailed = lazy(() =>
  import("pages/POSRequest/RequestFailed").then(({ RequestFailed }) => ({
    default: RequestFailed,
  }))
);

const isProtected = Storage.getItem("afb-app-token");

export const POS_REQUEST_ROUTES = {
  INITIATE_REQUEST: "/request-pos/initiate",
  COMPLETE_REQUEST: "/request-pos/complete",
  SUCCESSFUL_REQUEST: "/request-pos/success",
  FAILED_REQUEST: "/request-pos/failure",
};

export const routes = [
  {
    path: POS_REQUEST_ROUTES.INITIATE_REQUEST,
    isAuthenticated: true,
    element: <InitiateRequestForm />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: POS_REQUEST_ROUTES.COMPLETE_REQUEST,
    isAuthenticated: true,
    element: <CompleteRequestForm />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: POS_REQUEST_ROUTES.SUCCESSFUL_REQUEST,
    isAuthenticated: true,
    element: <RequestSuccessful />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: POS_REQUEST_ROUTES.FAILED_REQUEST,
    isAuthenticated: true,
    element: <RequestFailed />,
    allowedRoles: ["soleproprietor"],
  },
];

const unProtectedRoutes: any = [];

export const posRequestRoutes = isProtected ? routes : unProtectedRoutes;
