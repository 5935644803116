import { lazy } from "react";
import { Storage } from "utils/inAppstorage";

const FlightsIntro = lazy(() =>
  import("pages/Flights/FlightsIntro").then(({ FlightsIntro }) => ({
    default: FlightsIntro,
  }))
);

const AvailableFlights = lazy(() =>
  import("pages/Flights/AvailableFlights").then(({ AvailableFlights }) => ({
    default: AvailableFlights,
  }))
);
const FlightDetails = lazy(() =>
  import("pages/Flights/FlightDetails").then(({ FlightDetails }) => ({
    default: FlightDetails,
  }))
);
const FareRules = lazy(() =>
  import("pages/Flights/FareRules").then(({ FareRules }) => ({
    default: FareRules,
  }))
);
const PassengerDetails = lazy(() =>
  import("pages/Flights/PassengerDetails").then(({ PassengerDetails }) => ({
    default: PassengerDetails,
  }))
);
const PassengerDetailsForm = lazy(() =>
  import("pages/Flights/PassengerDetailsForm").then(
    ({ PassengerDetailsForm }) => ({
      default: PassengerDetailsForm,
    })
  )
);
const AuthenticationScreen = lazy(() =>
  import("pages/Flights/AuthenticationScreen").then(
    ({ AuthenticationScreen }) => ({
      default: AuthenticationScreen,
    })
  )
);
const OtpScreen = lazy(() =>
  import("pages/Flights/OtpScreen").then(({ OtpScreen }) => ({
    default: OtpScreen,
  }))
);
const PaymentPendingScreen = lazy(() =>
  import("pages/Flights/PaymentPendingScreen").then(
    ({ PaymentPendingScreen }) => ({
      default: PaymentPendingScreen,
    })
  )
);
const PaymentSuccessScreen = lazy(() =>
  import("pages/Flights/PaymentSuccessScreen").then(
    ({ PaymentSuccessScreen }) => ({
      default: PaymentSuccessScreen,
    })
  )
);
const PaymentFailedScreen = lazy(() =>
  import("pages/Flights/PaymentFailedScreen").then(
    ({ PaymentFailedScreen }) => ({
      default: PaymentFailedScreen,
    })
  )
);
const PaymentReceipt = lazy(() =>
  import("pages/Flights/PaymentReceipt").then(({ PaymentReceipt }) => ({
    default: PaymentReceipt,
  }))
);

const isProtected = Storage.getItem("afb-app-token");

export const routes = [
  {
    path: "/flights/:tabPath",
    isAuthenticated: true,
    element: <FlightsIntro />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/flights/available-flights",
    isAuthenticated: true,
    element: <AvailableFlights />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/flights/details",
    isAuthenticated: true,
    element: <FlightDetails />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/flights/fare-rules",
    isAuthenticated: true,
    element: <FareRules />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/flights/passenger-details",
    isAuthenticated: true,
    element: <PassengerDetails />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/flights/passenger-details/form",
    isAuthenticated: true,
    element: <PassengerDetailsForm />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/flights/booking/authentication",
    isAuthenticated: true,
    element: <AuthenticationScreen />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/flights/booking/enter-otp",
    isAuthenticated: true,
    element: <OtpScreen />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/flights/booking/pending",
    isAuthenticated: true,
    element: <PaymentPendingScreen />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/flights/booking/success",
    isAuthenticated: true,
    element: <PaymentSuccessScreen />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/flights/booking/failed",
    isAuthenticated: true,
    element: <PaymentFailedScreen />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/flights/booking/receipt",
    isAuthenticated: true,
    element: <PaymentReceipt />,
    allowedRoles: ["soleproprietor"],
  },
];

const unProtectedRoutes: any = [];

export const flightsRoutes = isProtected ? routes : unProtectedRoutes;
