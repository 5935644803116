import { useEffect, useState } from "react";
import {
  useBranches,
  useCities,
  useGetStates,
  useLgas,
} from "hooks/apis/FetchLocation/useBankLocation";
import { Formik, Form, useFormikContext } from "formik";
import { FormikButton, TextInput } from "alat-components-library";
import { StateDropdown } from "components/Dashboard/components/StateDropdown";
import PageLoader from "components/Loader/PageLoader";
import { ReusableCard } from "components/reusables/Page/ReusableCard";
import { CardRequestPayload } from "types/global";
import { Checkbox } from "components/reusables/Inputs/Checkbox/Checkbox";
import { cardDeliveryValidationSchema } from "pages/Cards/validations/cardDelivery.validations";

// This component sets default state for card
const DefaultCardState = ({
  cardRequestPayload,
}: {
  cardRequestPayload: CardRequestPayload | null;
}) => {
  const { setFieldValue } = useFormikContext();

  // Set the value card has default state
  useEffect(() => {
    if (setFieldValue && !!cardRequestPayload) {
      setFieldValue("address", cardRequestPayload?.streetAddress, false);
      setFieldValue(
        "deliverToAlternative",
        cardRequestPayload?.deliverToAlternative,
        false
      );
      setFieldValue(
        "alternativeName",
        cardRequestPayload?.alternativeName,
        false
      );
      setFieldValue(
        "alternativePhone",
        cardRequestPayload?.alternativePhone,
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardRequestPayload]);

  return null;
};

interface CardPayload {
  address: string;
  state: string;
  lga: string;
  city: string;
  preferredBranch: string;
  deliverToAlternative: boolean;
  alternativeName: string;
  alternativePhone: string;
}
const PickupDeliveryForm = ({
  cardRequestPayload,
  handlePrev,
  onNext,
}: {
  cardRequestPayload: CardRequestPayload | null;
  handlePrev: () => void;
  onNext: (data: CardRequestPayload) => void;
}) => {
  const [selectedCity, setSelectedCity] = useState({ name: "" });
  const [selectedLGA, setSelectedLGA] = useState({ name: "" });
  const [address, setAddress] = useState("");
  const [branch, setBranch] = useState({ name: "", id: "127" });
  const [selectedState, setSelectedState] = useState<{
    id: string | null;
    name: string;
    code: string | null;
    key: string;
  }>({ id: "", name: "", key: "", code: "" });

  const { statesData, statesLoading } = useGetStates();
  const { lgasData, lgasLoading } = useLgas(selectedState?.id);
  const { citiesData, citiesLoading } = useCities(selectedState?.id);
  const { branchesData, branchesLoading } = useBranches(selectedState?.code);

  const getBranches = () => {
    return (
      branchesData.length > 0 &&
      branchesData?.map((option: any) => ({
        name: option?.branchName,
        id: option?.id,
        key: option?.id,
      }))
    );
  };

  const getStates = () => {
    return (
      statesData.length > 0 &&
      statesData?.map((option: any) => ({
        name: option?.name,
        id: option?.stateId,
        code: option?.finacleCode,
        key: option?.stateId,
      }))
    );
  };

  const getCities = () => {
    return (
      citiesData.length > 0 &&
      citiesData?.map((option: any) => ({
        name: option?.name,
        id: option?.id,
        key: option?.id,
      }))
    );
  };

  const getLga = () => {
    return (
      lgasData.length > 0 &&
      lgasData?.map((option: any) => ({
        name: option?.name,
        id: option?.lgaId,
        key: option?.lgaId,
      }))
    );
  };
  const handleSubmit = (values: CardPayload) => {
    const data = {
      streetAddress: values.address,
      stateName: values.state,
      lgaName: values.lga,
      cityName: values.city,
      cardTypeRequest: 2,
      deliverToAlternative: values?.deliverToAlternative,
      alternativeName: values?.alternativeName,
      alternativePhone: values?.alternativePhone,
      branchId: branch?.id,
    };
    onNext({ ...cardRequestPayload!, ...data });
  };
  const isLoading =
    statesLoading || branchesLoading || lgasLoading || citiesLoading;

  return (
    <div className="w-full">
      {isLoading && <PageLoader />}
      <ReusableCard
        cardTitle={
          cardRequestPayload?.deliveryOption === 2
            ? "Office Delivery"
            : "Branch Pick up"
        }
        className="mt-0 border-b"
        variant="md:w-[48rem] w-full mx-auto bg-white border border-alat-gray-input-border rounded-lg"
        handleBackNavigation={handlePrev}
        showGoBackIcon
      >
        <div className="max-w-[409px] mx-auto">
          <Formik
            initialValues={{
              address: "",
              state: "",
              lga: "",
              city: "",
              preferredBranch: "",
              deliverToAlternative: false,
              alternativeName: "",
              alternativePhone: "",
            }}
            onSubmit={(values) => handleSubmit(values)}
            validateOnChange
            validationSchema={cardDeliveryValidationSchema(
              cardRequestPayload?.deliveryOption
            )}
          >
            {({
              handleChange,
              handleBlur,
              errors,
              touched,
              isValid,
              dirty,
              values,
              setFieldValue,
            }) => {
              return (
                <Form>
                  {/* Set default card state  */}
                  <DefaultCardState cardRequestPayload={cardRequestPayload} />
                  <div>
                    <div className="flex flex-wrap justify-center gap-4 ">
                      <div className="text-alat-grey relative w-full lg:max-w-sm mb-2 pb-5">
                        {cardRequestPayload?.deliveryOption === 2 && (
                          <div className="relative w-full lg:max-w-sm my-4">
                            <TextInput
                              id="address_input"
                              label="Address"
                              name="address"
                              placeHolder="Enter address"
                              value={
                                address || cardRequestPayload?.streetAddress
                              }
                              handleChange={(value: any) => {
                                const val = value.target.value;
                                setAddress(val);
                                setFieldValue("address", val);
                              }}
                              handleBlur={handleBlur}
                              autoComplete="off"
                              hasError={errors?.address && touched?.address}
                              error={errors?.address}
                            />
                          </div>
                        )}

                        <div className="relative w-full lg:max-w-sm my-4">
                          <StateDropdown
                            id="state_dropdown"
                            label="State"
                            placeholder="--Select State--"
                            options={getStates()}
                            name="state"
                            value={values.state}
                            onChange={handleChange}
                            showSearchInput
                            onSelectOption={(option) => {
                              setFieldValue("city", null, false);
                              setFieldValue("lga", null, false);
                              setFieldValue("preferredBranch", null, false);
                              setSelectedCity({ name: "" });
                              setSelectedLGA({ name: "" });
                              setBranch({ name: "", id: "127" });
                              setSelectedState(option);
                            }}
                            error={errors?.state}
                          />
                        </div>

                        <div className="relative w-full lg:max-w-sm my-4">
                          <StateDropdown
                            id="city_dropdown"
                            label="City"
                            placeholder="--Select City--"
                            options={getCities()}
                            name="city"
                            showSearchInput
                            value={selectedCity.name}
                            onChange={handleChange}
                            onSelectOption={(option) => {
                              setSelectedCity(option);
                              setFieldValue("preferredBranch", null, false);
                            }}
                            error={errors?.city}
                          />
                        </div>
                        {cardRequestPayload?.deliveryOption === 2 && (
                          <>
                            <div className="relative w-full lg:max-w-sm my-4">
                              <StateDropdown
                                id="lga_dropdown"
                                label="LGA"
                                placeholder="--Select LGA--"
                                options={getLga()}
                                name="lga"
                                showSearchInput
                                value={selectedLGA.name}
                                onChange={handleChange}
                                onSelectOption={(option) =>
                                  setSelectedLGA(option)
                                }
                                error={errors?.lga}
                              />
                            </div>
                          </>
                        )}

                        {cardRequestPayload?.deliveryOption === 1 && (
                          <div className="relative w-full lg:max-w-sm my-4">
                            <StateDropdown
                              id="preferredbranch_dropdown"
                              label="Preferred Branch"
                              name="preferredBranch"
                              showSearchInput
                              placeholder="-- Select Branch --"
                              options={getBranches()}
                              value={values.preferredBranch}
                              onChange={handleChange}
                              onSelectOption={(option) => setBranch(option)}
                              disabled={selectedState.id === null}
                              error={errors?.preferredBranch}
                            />
                          </div>
                        )}
                        {cardRequestPayload?.deliveryOption === 2 && (
                          <>
                            <div className="flex items-center mt-10 mb-8">
                              <Checkbox
                                handleChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  const value = event.target.checked;
                                  setFieldValue("deliverToAlternative", value);
                                }}
                                checked={values.deliverToAlternative}
                              >
                                <p className="text-sm font-medium text-black mt-1">
                                  Deliver to an alternative person
                                </p>
                              </Checkbox>
                            </div>
                            {values.deliverToAlternative && (
                              <div className="">
                                <div className="pb-4">
                                  <TextInput
                                    id="name_input"
                                    label="Enter name"
                                    name="alternativeName"
                                    placeHolder="Enter alternative person name here"
                                    value={values.alternativeName}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    hasError={
                                      errors?.alternativeName &&
                                      touched?.alternativeName
                                    }
                                    error={errors?.alternativeName}
                                  />
                                </div>
                                <div className="pb-4">
                                  <TextInput
                                    id="phone_input"
                                    label="Phone Number"
                                    name="alternativePhone"
                                    placeHolder="Enter phone number"
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    maxLength={11}
                                    value={values?.alternativePhone}
                                    hasError={
                                      errors?.alternativePhone &&
                                      touched?.alternativePhone
                                    }
                                    error={errors?.alternativePhone}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                        <div className="relative w-full py-6 mb-2  flex flex-col items-center justify-center">
                          {/* Show when deliverToAlternative is selected  */}
                          {values.deliverToAlternative && (
                            <div>
                              <p className="pb-5 text-center text-xs text-[#4E4E4E]">
                                By clicking on proceed, You agree to indemnify
                                the bank
                              </p>
                            </div>
                          )}
                          <FormikButton
                            id="proceed"
                            labelText="Proceed"
                            isDisabled={!isValid && !dirty}
                            containerVariant={`w-full h-[48px] rounded-md flex justify-center item-center`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </ReusableCard>
    </div>
  );
};

export default PickupDeliveryForm;
