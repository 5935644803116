import {
  AdminNavItems,
  navItems,
  accountsSubMenuLinks,
  paymentSubMenuLinks,
  cardSubMenuLinks,
  loansSubMenuLinks,
  moreSubMenuLinks,
} from "components/Navbar/navItems";
import React, { useState } from "react";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { ReactComponent as Close } from "assets/svgs/Menu/Vector.svg";
import { ReactComponent as ChevronIcon } from "assets/svgs/Menu/Icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "hooks/useReduxHook";
import { setShowMobileSidebar } from "react-wrappers/stores/redux/app/slices";

type MainMenuItems = {
  id: string;
  icon: React.ReactNode;
  text: string;
  link: string;
  hasDropdown: boolean;
}[];

type SubMenuLinks = {
  id: string;
  key: string;
  text: string;
  route: string;
}[];

// Menu items for Mobile sidebar

export const MenuItems = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authState = useAuthState();
  const dispatch = useAppDispatch();
  const [show, setShow] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string>("");
  const isAdmin = authState?.userRole?.toLowerCase() === "admin";
  const mainMenuItems: MainMenuItems = isAdmin ? AdminNavItems : navItems;
  const subMenuLinks: SubMenuLinks = [
    ...accountsSubMenuLinks,
    ...paymentSubMenuLinks,
    ...cardSubMenuLinks,
    ...loansSubMenuLinks,
    ...moreSubMenuLinks,
  ];
  return (
    <div className=" divide-y-[0.5px] divide-[#606060] w-full">
      {mainMenuItems?.map((item) => {
        if (
          item?.text?.toLowerCase() === "payments" &&
          !["initiator", "soleproprietor", "initiatorapprover"].includes(
            authState?.userRole
          )
        ) {
          return false;
        }
        if (
          item?.text?.toLowerCase() === "loans" &&
          !["soleproprietor"].includes(authState?.userRole)
        ) {
          return false;
        }
        if (
          item?.text?.toLowerCase() === "cards" &&
          !["soleproprietor"].includes(authState?.userRole)
        ) {
          return false;
        }
        if (
          item?.text?.toLowerCase() === "more" &&
          !["soleproprietor"].includes(authState?.userRole)
        ) {
          return false;
        }
        if (item?.hasDropdown) {
          return (
            <div
              key={item?.id}
              className={` w-full bg-lib-alat-black transition-[height] duration-1000 ease-in-out
                            ${!show ? "max-h-16 overflow-hidden" : "max-h-auto"}`}
            >
              <div
                className="flex items-center justify-between w-full h-full py-4"
                onClick={() => {
                  setShow(!show);
                  setSelectedItem(item?.id);
                }}
              >
                <div className="font-medium text-sm text-white">
                  {item?.text}
                </div>
                <div>
                  <div className="w-6 flex flex-col gap-3 cursor-pointer">
                    <ChevronIcon
                      className={`${show && selectedItem === item?.id ? "" : "-rotate-180"}`}
                    />
                    {/* <ChevronIcon /> */}
                  </div>
                </div>
              </div>
              {show && selectedItem === item?.id && (
                <div className="w-full px-4 py-4 flex flex-col gap-5">
                  {subMenuLinks
                    ?.filter((x) => x?.key === selectedItem)
                    ?.map((link) => {
                      if (
                        link?.id === "airtime_btn" &&
                        !["soleproprietor"].includes(authState?.userRole)
                      ) {
                        return false;
                      }
                      return (
                        <p
                          key={link?.id}
                          onClick={() => {
                            navigate(link?.route);
                            dispatch(setShowMobileSidebar(false));
                          }}
                          className={`${location?.pathname === link?.route ? "text-lib-alat-red" : "text-white"} cursor-pointer font-medium text-[14px] leading-[16px]`}
                        >
                          {link?.text}
                        </p>
                      );
                    })}
                </div>
              )}
            </div>
          );
        }
        return (
          <div
            key={item?.id}
            onClick={() => {
              navigate(item?.link);
              dispatch(setShowMobileSidebar(false));
            }}
            className={`cursor-pointer bg-lib-alat-black flex py-4 w-full`}
          >
            <p
              className={`${location?.pathname === item?.link ? "text-lib-alat-red" : "text-white"} font-medium text-sm`}
            >
              {item?.text}
            </p>
          </div>
        );
      })}
    </div>
  );
};
