import React, { useCallback, useEffect } from "react";
import { ReactSuspenceWrapper } from "../../alat-components-library";
import Navbar from "../Navbar/Navbar";
import { TopHeader } from "../TopHeader";
import { useAppSelector } from "hooks/useReduxHook";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { CompletePaymentModal } from "../../pages/Onboarding/Components/CompletePaymentModal";
import { OnboardingStageEnum } from "types/global";
import PendingDudChequesModal from "pages/Home/DudCheque/components/PendingDudChequesModal";
import { useWindowSize } from "hooks/useWindowSize";
import { isMobile } from "third-party/facecapture/logic/deviceType";
import {
  setShowMobileSidebar,
  useAppState,
} from "react-wrappers/stores/redux/app/slices";
import { Sidebar } from "components/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastNotify } from "helpers/toastNotify";
import { allRoutes } from "routers";

const DashboardWrapper = ({ children }: any) => {
  const { showMobileSidebar } = useAppState();
  const { user, userRole } = useAuthState();
  const navigate = useNavigate();
  const isClient = useWindowSize();
  const alatPay = useAppSelector((state) => state.onboarding.alatPay);
  const location = useLocation();
  const isAdmin = userRole?.toLowerCase() === "admin";

  const mobileScreen =
    (isClient?.width ?? 0) <= 768 || isMobile(navigator.userAgent);

  const protectedRoutes = allRoutes?.filter((x) => x?.isAuthenticated);

  // console.log("protected routes", protectedRoutes);
  // console.log("location", location?.pathname);

  const matchingRoutes = protectedRoutes?.find((x) => {
    if (x?.path?.includes(":tabPath") || x?.path?.includes(":correlationId")) {
      const splitPath = x?.path?.split("/");
      splitPath?.splice(splitPath?.length - 1, 1);
      // console.log("split", splitPath);
      const reformedPath = splitPath?.join("/");
      // console.log("reformed route", reformedPath);
      const currentRoute = location?.pathname?.split("/");
      currentRoute?.splice(currentRoute?.length - 1, 1);
      // console.log("currentRoute");
      return currentRoute?.join("/") === reformedPath;
    }
    return x?.path?.includes(location?.pathname);
  });

  const checkAccess = () => {
    // console.log("matching routes", matchingRoutes);

    if (!matchingRoutes?.allowedRoles?.includes(userRole?.toLowerCase())) {
      ToastNotify({
        type: "error",
        message: "You have no permission to view this page.",
        position: "top-right",
      });
      if (isAdmin) {
        return navigate("/admin-dashboard");
      }
      return navigate("/user-dashboard");
    }
    return;
  };

  useEffect(() => {
    checkAccess();
  }, []);

  // console.log("mobileScreen", mobileScreen);
  return (
    <ReactSuspenceWrapper>
      {user?.onboardingStage === OnboardingStageEnum.AlatPay && !alatPay ? (
        <CompletePaymentModal />
      ) : user?.onboardingStage === OnboardingStageEnum.DudChequeFailed ? (
        <PendingDudChequesModal />
      ) : (
        <div className="bg-[#F5F5F5] min-h-screen w-full pb-20 overflow-hidden">
          <div className="container-fluid mx-auto">
            <TopHeader />
            <Navbar />
            {children}
          </div>
        </div>
      )}
      <div
        className={`${showMobileSidebar ? `flex` : "hidden"} md:hidden h-full flex w-full relative`}
      >
        <Sidebar />
      </div>
    </ReactSuspenceWrapper>
  );
};

export default DashboardWrapper;
