import * as Yup from "yup";

export const cardDeliveryValidationSchema = (deliveryOption?: number) => {
  const isPickup = deliveryOption === 1;
  return Yup.object({
    state: Yup.string().required("Please select state"),
    preferredBranch: isPickup
      ? Yup.string().required("Please select preferred branch")
      : Yup.string().notRequired(),
    city: Yup.string().required("Please select city"),
    lga: isPickup
      ? Yup.string().notRequired()
      : Yup.string().required("Please select lga"),
    deliverToAlternative: Yup.boolean().notRequired(),
    address: isPickup
      ? Yup.string().notRequired()
      : Yup.string().required("Please enter address"),
    alternativeName: Yup.string().when(
      "deliverToAlternative",
      (deliverToAlternative, cardDeliveryValidationSchema) => {
        const isActive = Array.isArray(deliverToAlternative)
          ? deliverToAlternative[0]
          : false;
        return isActive
          ? cardDeliveryValidationSchema.required(
              "Please enter alternative name"
            )
          : cardDeliveryValidationSchema.nonNullable().notRequired();
      }
    ),

    alternativePhone: Yup.string().when(
      "deliverToAlternative",
      (deliverToAlternative, cardDeliveryValidationSchema) => {
        const isActive = Array.isArray(deliverToAlternative)
          ? deliverToAlternative[0]
          : false;
        return isActive
          ? cardDeliveryValidationSchema
              .trim()
              .required("Please enter alternative phone")
              .matches(/^[\d+\s]{11,}$/, "Enter a valid phone number")
              .test(
                "No empty phonenumber",
                `Please enter a valid phone number`,
                (value) => {
                  return value !== null;
                }
              )
          : cardDeliveryValidationSchema.nonNullable().notRequired();
      }
    ),
  });
};
