import BuyAirtime from "pages/AirtimeAndData/BuyAirtime";
import BuyNewAirtime from "pages/AirtimeAndData/BuyNewAirtime";
import NewAirtime from "pages/AirtimeAndData/NewAirTime";
import AirtimePurchase from "pages/AirtimeAndData/AirtimePurchase";
import BuyData from "pages/AirtimeAndData/BuyData";
import NewData from "pages/AirtimeAndData/NewData";
import NewDataPurchase from "pages/AirtimeAndData/NewDataPurchase";
import DataPurchase from "pages/AirtimeAndData/DataPurchase";
import OtpVerification from "pages/AirtimeAndData/OtpVerification";
import ConfirmOtp from "pages/AirtimeAndData/ConfirmOtp";
import ConfirmTrans from "pages/AirtimeAndData/ConfirmTrans";
import FailedTrans from "pages/AirtimeAndData/FailedTrans";
import FailedTransData from "pages/AirtimeAndData/FailedTrans";
import ConfirmDataOTP from "pages/AirtimeAndData/ConfirmDataOTP";
import ConfirmDataTransaction from "pages/AirtimeAndData/ConfirmDataTransaction";

import { Storage } from "utils/inAppstorage";

const isProtected = Storage.getItem("afb-app-token");

export const routes = [
  {
    path: "/Airtime/BuyAirtime", //Buy airtime and data
    isAuthenticated: true,
    element: <BuyAirtime />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/Airtime/BuyNewAirtime", //Buy airtime and data
    isAuthenticated: true,
    element: <BuyNewAirtime />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/Airtime/NewAirTime", //Buy airtime and data
    isAuthenticated: true,
    element: <NewAirtime />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/Airtime/AirtimePurchase", //Buy airtime and data
    isAuthenticated: true,
    element: <AirtimePurchase />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/Airtime/OtpVerification", //Buy airtime and data
    isAuthenticated: true,
    element: <OtpVerification />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/Airtime/ConfirmOtp", //Buy airtime and data
    isAuthenticated: true,
    element: <ConfirmOtp />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/Airtime/ConfirmTrans", //Buy airtime and data
    isAuthenticated: true,
    element: <ConfirmTrans />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/Airtime/FailedTrans", //Buy airtime and data
    isAuthenticated: true,
    element: <FailedTrans />,
    allowedRoles: ["soleproprietor"],
  },

  {
    path: "/Data/NewData", //Buy airtime and data
    isAuthenticated: true,
    element: <NewData />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/Data/BuyNewData", //Buy airtime and data
    isAuthenticated: true,
    element: <BuyData />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/Data/NewDataPuchase", //Buy airtime and data
    isAuthenticated: true,
    element: <NewDataPurchase />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/Data/DataPurchase", //Buy airtime and data
    isAuthenticated: true,
    element: <DataPurchase />,
    allowedRoles: ["soleproprietor"],
  },

  {
    path: "/Data/ConfirmOtp", //Buy airtime and data
    isAuthenticated: true,
    element: <ConfirmDataOTP />,
    allowedRoles: ["soleproprietor"],
  },

  //Confirm Data Trans
  {
    path: "/Data/ConfirmTrans", //Buy airtime and data
    isAuthenticated: true,
    element: <ConfirmDataTransaction />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/Data/FailedTrans", //Buy airtime and data
    isAuthenticated: true,
    element: <FailedTransData />,
    allowedRoles: ["soleproprietor"],
  },
];

const unProtectedRoutes: any = [];

export const airtimeAndDataRoutes = isProtected ? routes : unProtectedRoutes;
