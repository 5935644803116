import QuickTransfer from "../../assets/svgs/quick-transfer-icon.svg";
import QuickFx from "../../assets/svgs/quick-fx-icon.svg";
import QuickSme from "../../assets/svgs/quick-sme-icon.svg";
import QuickBta from "../../assets/svgs/quick-bta-icon.svg";
import QuickReceipts from "../../assets/svgs/quick-receipts-icon.svg";
import QuickLimits from "../../assets/svgs/quick-limits-icon.svg";
import SendMoney from "../../assets/svgs/send-money-icon.svg";
import AccountStatement from "../../assets/svgs/account-statement-icon.svg";
import Settings from "../../assets/svgs/settings-icon.svg";

export const quickActionOne = [
  {
    id: "",
    title: "Funds Transfer",
    info: "Transfer funds instantly to anyon",
    path: "",
    icon: QuickTransfer,
  },
  {
    id: "",
    title: "FX Transfer",
    info: "Save beneficiary for later",
    path: "",
    icon: QuickFx,
  },
  {
    id: "",
    title: "SME Form Q",
    info: "Save beneficiary for later",
    path: "",
    icon: QuickSme,
  },
  {
    id: "",
    title: "BTA",
    info: "Save beneficiary for later",
    path: "",
    icon: QuickBta,
  },
  {
    id: "",
    title: "Print Receipts",
    info: "Save beneficiary for later",
    path: "",
    icon: QuickReceipts,
  },
  {
    id: "",
    title: "Transaction Limit",
    info: "Save beneficiary for later",
    path: "",
    icon: QuickLimits,
  },
];

export const quickActionTwo = [
  {
    id: "quickactions_sendmoney_btn",
    title: "Send Money",
    info: "",
    path: "/payments/funds-transfer",
    icon: SendMoney,
  },
  {
    id: "quickactions_accountstatement_btn",
    title: "Account Statement",
    info: "",
    path: "/payments/account-statement",
    icon: AccountStatement,
  },
  {
    id: "quickactions_settings_btn",
    title: "Settings",
    info: "",
    path: "/profile/account-settings",
    icon: Settings,
  },
];
