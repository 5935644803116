import { Storage } from "utils/inAppstorage";
import { AdminDashboard, UserDashboard } from "../pages";
import { lazy } from "react";

const ViewAllAccounts = lazy(() =>
  import("pages/Dashboard/ViewAllAccounts").then(({ ViewAllAccounts }) => ({
    default: ViewAllAccounts,
  }))
);

const ViewAccountManager = lazy(() =>
  import("pages/Dashboard/ViewAccountManager").then(
    ({ ViewAccountManager }) => ({ default: ViewAccountManager })
  )
);

const ViewAllAccountsAdmin = lazy(() =>
  import("pages/Dashboard/ViewAllAccountsAdmin").then(
    ({ ViewAllAccountsAdmin }) => ({
      default: ViewAllAccountsAdmin,
    })
  )
);

const GenerateReceipts = lazy(() =>
  import("pages/MockReceiptGeneration/EnterReceiptDetails").then(
    ({ EnterReceiptDetails }) => ({
      default: EnterReceiptDetails,
    })
  )
);

const isProtected = Storage.getItem("afb-app-token");

const protectedRoutes: any = [
  {
    path: "/user-dashboard",
    isAuthenticated: true,
    element: <UserDashboard />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },
  {
    path: "/admin-dashboard",
    isAuthenticated: true,
    element: <AdminDashboard />,
    allowedRoles: ["admin"],
  },
  {
    path: "/user-dashboard/accounts",
    isAuthenticated: true,
    element: <ViewAllAccounts />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },
  {
    path: "/dashboard/account-manager",
    isAuthenticated: true,
    element: <ViewAccountManager />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
      "admin",
    ],
  },
  {
    path: "/admin-dashboard/accounts",
    isAuthenticated: true,
    element: <ViewAllAccountsAdmin />,
    allowedRoles: ["admin"],
  },
  {
    path: "/admin/generate-receipts",
    isAuthenticated: true,
    element: <GenerateReceipts />,
    allowedRoles: ["admin"],
  },
];

const unProtectedRoutes: any = [];

export const dashboardRoutes = isProtected
  ? protectedRoutes
  : unProtectedRoutes;
