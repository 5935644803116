import { ToastNotify } from "helpers/toastNotify";
import { useState } from "react";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { UtilityCardRequest } from "types/global";

const deleveryOptionList = [
  {
    id: 1,
    label: "Branch Pick up",
    name: "Branch Pick up",
  },
  {
    id: 2,
    label: "Office Delivery",
    name: "Office Delivery",
  },
];

const charges = [
  {
    cardFees: 1000,
    vat: 75,
    deliveryFee: 0,
  },
  {
    cardFees: 1000,
    vat: 75,
    deliveryFee: 1000,
  },
];
export const useUtilityCard = () => {
  const [accountToDebit, setAccountToDebit] = useState({
    accountId: "",
    currency: "",
    balance: 0,
    accountName: "",
    accountNo: "",
    schemeCode: "",
  });
  const [preferredName, setPreferredName] = useState({ name: "", error: "" });

  const authState = useAuthState();

  const accounts = authState.user?.accounts;
  const activeAccounts = accounts?.filter(
    (account: { accountStatus: string }) => account.accountStatus === "Active"
  );

  // Get selected account
  const handleSelectedAccount = (accountNo: string) => {
    let selectedAccount = authState?.user?.accounts?.find(
      (x: { accountNo: string }) => x?.accountNo === accountNo
    );
    return selectedAccount;
  };

  const handleAccountError = (
    payload: UtilityCardRequest,
    isNewCard?: boolean
  ) => {
    //Validate if account
    if (accountToDebit.accountId === "") {
      ToastNotify({
        type: "error",
        message: "Please select an account",
        position: "top-center",
      });
      return true;
    }
    //Validate preferred name for new card request
    if (!payload.preferredName && isNewCard) {
      ToastNotify({
        type: "error",
        message: "Preferred name cannot be empty",
        position: "top-center",
      });
      return true;
    }
    //Validate if account is no money
    if (accountToDebit.balance === 0) {
      ToastNotify({
        type: "error",
        message: "Account is empty, please select another account",
        position: "top-center",
      });
      return true;
    }
    //Validate if delivery option is selected
    if (payload.deliveryOption.label === "") {
      ToastNotify({
        type: "error",
        message: "Please select a delivery option",
        position: "top-center",
      });
      return true;
    }
    return false;
  };
  const handlePreferredNameValidation = (name: string) => {
    const isValid = name.length <= 21;
    return isValid ? "" : "Name is too long";
  };

  // const accSelectErrorMessage = useMemo(
  //   () => handleAccountError(airtimeData?.amount, accountToDebit),
  //   [airtimeData?.amount, accountToDebit?.accountNo]
  // );
  return {
    handleAccountError,
    activeAccounts,
    deleveryOptionList,
    setAccountToDebit,
    accountToDebit,
    preferredName,
    setPreferredName,
    handlePreferredNameValidation,
    charges,
    handleSelectedAccount,
  };
};
