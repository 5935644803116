import { ToastNotify } from "helpers/toastNotify";
import { useAppDispatch } from "hooks/useReduxHook";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { POST_SUBMIT_CARD_REQUEST_ACTION } from "react-wrappers/stores/redux/cards/actions";
import {
  useCardState,
  setCardRequestPayload,
  setActiveCardRequestScreen,
} from "react-wrappers/stores/redux/cards/slices";
import { POST_VALIDATE_BILL_REFERENCE_ACTION } from "react-wrappers/stores/redux/payments/actions";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";
import { TransactionTypeEnum } from "utils/enums";

export const useCorporateDebitCardController = (
  address?: any,
  selectedState?: any,
  selectedCity?: any,
  selectedLGA?: any
) => {
  const location = useLocation();
  const authState = useAuthState();
  const { amount, cardRequestPayload } = useCardState();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAuthState();

  const accounts = authState.user?.accounts;
  const airtimeData = location?.state?.airtimeData;
  // const deleveryOptionList = activeSelectedSubBill.packages?.map((arr: any) => {
  //     // console.log(arr);
  //     return {
  //         ...arr,
  //         label: arr.name,
  //     };
  // });

  const [accountToDebit, setAccountToDebit] = useState({
    accountId: "",
    currency: "",
    balance: 0,
    accountName: "",
    accountNo: "",
    schemeCode: "",
  });

  const deleveryOptionList = [
    {
      id: 1,
      label: "Branch Pick up",
      name: "Branch Pick up",
    },
    {
      id: 2,
      label: "Office Delivery",
      name: "Office Delivery",
    },
  ];

  const activeAccounts = accounts?.filter(
    (account: any) => account.accountStatus === "Active"
  );

  const handleAccountError = (airtimeAmount: number, accountBalance: any) => {
    return accountBalance.accountNo !== "" &&
      airtimeAmount > accountBalance.balance
      ? "insufficient balance"
      : "";
  };

  const accSelectErrorMessage = useMemo(
    () => handleAccountError(airtimeData?.amount, accountToDebit),
    [airtimeData?.amount, accountToDebit?.accountNo]
  );

  const handleSubmit = (payload: any) => {
    // console.log("payload", payload );

    //Validate if account
    if (accountToDebit.accountId === "") {
      return ToastNotify({
        type: "error",
        message: "Please select an account",
        position: "top-center",
      });
    }
    //Validate if account is no money
    if (accountToDebit.balance === 0) {
      return ToastNotify({
        type: "error",
        message: "Account is empty, please select another account",
        position: "top-center",
      });
    }
    //Validate if delivery option is selected
    if (payload.deliveryOption.label === "") {
      return ToastNotify({
        type: "error",
        message: "Please select a delivery option",
        position: "top-center",
      });
    }

    dispatch(
      setCardRequestPayload({
        sourceAccountName: accountToDebit.accountName,
        sourceAccountCurrencyCode: accountToDebit.currency,
        sourceAccountNumber: accountToDebit.accountNo,
        sourceAccountNumberSchemeCode: accountToDebit.schemeCode,
        // transactionType: TransactionTypeEnum.SingleSignatoryCardRequest,
        transactionType: 1,
        deliveryOption: payload.deliveryOption.id,
        totalDebitAmount:
          amount[payload.deliveryOption.id - 1].cardFees +
          amount[payload.deliveryOption.id - 1].deliveryFee +
          amount[payload.deliveryOption.id - 1].vat,
      })
    );

    dispatch(setActiveCardRequestScreen(1));
  };

  const handleSubmitBranchPickup = (payload: any) => {
    // console.log("payload", payload);

    if (selectedState.id === null) {
      return ToastNotify({
        type: "error",
        message: "Please select a state",
        position: "top-center",
      });
    }

    const data = {
      ...cardRequestPayload,
      ...{
        streetAddress: address,
        stateName: selectedState.name,
        lgaName: selectedLGA.name,
        cityName: selectedCity.name,
      },
    };

    dispatch(setCardRequestPayload(data));
    dispatch(setActiveCardRequestScreen(2));
  };

  const handleFinalSubmit = (values: any) => {
    // "sourceAccountName": "string",
    // "sourceAccountCurrencyCode": "str",
    // "transactionType": 1,
    // "transactionPin": "string",
    // "token": "string",
    // "isSoftToken": true,
    // "sourceAccountNumber": "stringstri",
    // "sourceAccountNumberSchemeCode": "string",
    // "preferredName": "string",
    // "deliveryOption": 1,
    // "branchId": 0,
    // "stateName": "string",
    // "lgaName": "string",
    // "cityName": "string",
    // "streetAddress": "string",
    // "totalDebitAmount": 0
    const data = {
      ...cardRequestPayload,
      cardTypeRequest: 1,
      isSoftToken: auth?.user?.hasSoftToken,
      transactionPin: values.pin !== "" ? values.pin : values.hardTokenPin,
      token: auth?.user?.hasSoftToken ? values.softToken : values.hardTokenOtp,
    };

    // console.log("data", data);

    //Call API to Validate the refrence or transaction ID
    dispatch(POST_SUBMIT_CARD_REQUEST_ACTION(data))
      .unwrap()
      .then((res: any) => {
        // console.log(res)
        if (res.status === 200) {
          dispatch(setActiveCardRequestScreen(3));
        }
      });
  };

  return {
    accountToDebit,
    accSelectErrorMessage,
    activeAccounts,
    deleveryOptionList,
    handleSubmit,
    handleSubmitBranchPickup,
    handleFinalSubmit,
    setAccountToDebit,
  };
};
