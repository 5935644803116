import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { FormikButton } from "alat-components-library";
import { Card } from "flowbite-react";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  getAllAccounts,
  getAccountStatementTypes,
} from "utils/helperFunctions";
import { useAccountStatementController } from "controllers/AccountStatementController";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import { DateInput } from "components/Inputs";
import { AccountStatementDetailValidation } from "utils/validations/transfer";
import PageLoader from "components/Loader/PageLoader";
import { useAppDispatch, useAppSelector } from "hooks/useReduxHook";
import { SelectDrop } from "components/Dashboard/components/SelectDrop";
import { useEffect } from "react";
import { GET_ALL_ACCOUNTS_ACTION } from "react-wrappers/stores/redux/accounts/actions";
import { accountStatementTypeEnum } from "utils/enums";
import { ToastNotify } from "helpers/toastNotify";
import PageHeader from "components/reusables/Page/PageHeader";
import { Checkbox } from "components/reusables/Inputs/Checkbox/Checkbox";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";

const handleToast = (message: string, type: string = "error") =>
  ToastNotify({
    type,
    message,
    position: "top-right",
  });

const Detail = () => {
  const dispatch = useAppDispatch();
  const authState = useAuthState();

  const {
    paymentState,
    initialValueForDetail,
    handleDetailSubmit,
    handleChequeClearanceSubmit,
  } = useAccountStatementController();

  const navigate = useNavigate();
  const userAccounts = useAppSelector((state) => state.accounts);
  const isViewer = authState?.user?.role === "Viewer";

  const handleSubmit = (value: any) => {
    const isStamped = value?.isStampedStatement;

    if (value.statementType === "") {
      return handleToast("Please select statement type to continue", "error");
    }

    if (isStamped && isViewer) {
      return handleToast(
        "You are not permitted to carry out this action. Kindly contact your admin",
        "error"
      );
    }

    if (value.statementType === accountStatementTypeEnum.AccountStatement) {
      handleDetailSubmit(value);
    }
    if (value.statementType === accountStatementTypeEnum.ChequeClearance) {
      handleChequeClearanceSubmit(value);
    }
  };

  useEffect(() => {
    dispatch(GET_ALL_ACCOUNTS_ACTION());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading =
    paymentState.loading.includes("POST_CREATE_ACCOUNT_STATEMENT") ||
    paymentState.loading.includes("POST_CREATE_CHEQUE_CLEARANCE_STATEMENT") ||
    userAccounts?.loading?.includes("GET_ALL_ACCOUNTS");

  return (
    <DashboardWrapper>
      {isLoading && <PageLoader message="Please wait ..." />}
      <div className="w-full space-y-8 px-2 md:px-0">
        <div className="max-w-6xl mt-4 mx-auto">
          <PageHeader title="Account Statement" />
        </div>

        <Card className="md:w-[768px] mx-auto w-full bg-white pb-14 dark:bg-white dark:border-[#EDEDED] border-[#EDEDED] dark:shadow-white">
          <div className="flex items-center justify-between">
            <ChevronLeftIcon
              id="back_btn"
              className="h-6 w-6 text-[#333333] cursor-pointer"
              onClick={() => navigate("/user-dashboard")}
            />
            <div className="text-[#4D4D4D] text-xl font-bold text-center">
              Details
            </div>
            <div></div>
          </div>
          <hr />
          <Formik
            initialValues={initialValueForDetail}
            validateSchema={AccountStatementDetailValidation}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              dirty,
              isValid,
              setFieldValue,
            }) => (
              <Form>
                <div className="md:w-[400px] w-full mx-auto space-y-4">
                  <div className="w-full">
                    <SelectDrop
                      id="accountStatement-statementType_dropdown"
                      idList="accountStatement-statementType_text"
                      variant="h-12 w-full text-sm"
                      name="statementType"
                      label="Select Statement Type"
                      placeholder="--Select--"
                      value={values?.statementType}
                      options={getAccountStatementTypes()}
                      onChange={(name: string, value: any) => {
                        setFieldValue("statementType", value.value);
                        setFieldValue("isStampedStatement", false);
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <SelectDrop
                      id="accountStatement-account_dropdown"
                      idList="accountStatement-account_text"
                      variant="h-12 w-full text-sm"
                      name="accountNumberDebit"
                      label="Select Account"
                      placeholder="--Select--"
                      value={values?.account?.accountNo}
                      options={getAllAccounts(userAccounts?.allAccounts ?? [])}
                      onChange={(name: string, value: any) =>
                        setFieldValue("account", JSON.parse(value.raw))
                      }
                    />
                  </div>
                  <div className="flex justify-between  items-center gap-2">
                    <div className="w-full">
                      <DateInput
                        id="startDate_input"
                        name="startDate"
                        label="Start Date"
                        variant="border text-black w-full h-[48px] text-lg px-5"
                        handleChange={(e) => e && handleChange(e)}
                        value={values.startDate}
                        isMaxBlocked={true}
                      />
                    </div>
                    <div className="w-full">
                      <DateInput
                        id="endDate_input"
                        name="endDate"
                        label="End Date"
                        variant="border text-black w-full h-[48px] text-lg px-5"
                        handleChange={(e) => e && handleChange(e)}
                        value={values.endDate}
                        isMaxBlocked={true}
                      />
                    </div>
                  </div>
                  {Number(values.statementType) === 1 && !isViewer && (
                    <div className="flex items-center mt-5">
                      <Checkbox
                        handleChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          setFieldValue(
                            "isStampedStatement",
                            event.target.checked
                          )
                        }
                        checked={values.isStampedStatement}
                      >
                        <p className="text-sm font-medium text-secondary dark:text-gray-300 mt-1">
                          Generate Stamped statement
                        </p>
                      </Checkbox>
                    </div>
                  )}
                  <div className="flex justify-center">
                    <FormikButton
                      id="send_btn"
                      labelText={"Generate Statement"}
                      containerVariant={
                        "w-full h-[45px] my-[3vh] rounded-md flex justify-center"
                      }
                      isDisabled={!isValid && !dirty}
                      isLoading={
                        !!paymentState.loading.includes(
                          "POST_CREATE_ACCOUNT_STATEMENT"
                        )
                      }
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </div>
    </DashboardWrapper>
  );
};

export default Detail;
