import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Card, Label } from "flowbite-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDataBundleController } from "controllers/AirtimeAndData/AirtimeController";
import Spinners from "components/Spinner/Spinner";
import { Formik, Form } from "formik";
import { FormikButton } from "alat-components-library";
import { dataValidationSchema } from "./airtimeValidation/airtimeValidation";
import { StateDropdown } from "components/Dashboard/components/StateDropdown";
import PageLoader from "components/Loader/PageLoader";

const NewData = () => {
  const {
    navigate,
    selectedNetwork,
    setSelectedNetwork,
    selectedBundle,
    setSelectedBundle,
    getBundleOptions,
    getNetworkOptions,
    handleDataRequest,
    DataBundleLoading,
    getBeneficiaryBundles,
  } = useDataBundleController();
  const location = useLocation();
  const beneficiary = location?.state?.beneficiary;

  const bundleList = () =>
    getBundleOptions()?.length > 0
      ? getBundleOptions()
      : getBeneficiaryBundles(beneficiary?.networkName);

  return (
    <div>
      <Card className="box-border w-full md:w-[48rem] mx-auto bg-white dark:bg-white dark:border-transparent border-transparent">
        <div>
          <div className="flex items-center justify-between">
            <ChevronLeftIcon
              onClick={() => navigate(-1)}
              id="back_btn"
              className="h-6 w-6 text-[#333333] cursor-pointer"
            />
            <div
              className="text-[#4D4D4D] text-xl text-center"
              id="banktransfer_heading"
            >
              Buy Data
            </div>
            <div></div>
          </div>
        </div>
        <hr />
        {DataBundleLoading && <PageLoader />}
        <Formik
          initialValues={{
            amount: beneficiary?.amount || 0,
            phonenumber: beneficiary?.phoneNumber || "",
            networkProvidersName: beneficiary?.networkName || "",
            networkbundle: beneficiary?.dataPackageName || "",
            networkId: beneficiary?.networkProviderId || "",
            dataPackageId: beneficiary?.dataPackageId || "",
          }}
          onSubmit={handleDataRequest}
          validationSchema={dataValidationSchema}
          validateOnChange
          validateOnBlur
        >
          {({
            handleChange,
            handleBlur,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
            values,
            setFieldValue,
          }) => (
            <Form>
              <div className="flex flex-wrap justify-center gap-4">
                <div className="text-alat-grey w-full md:max-w-sm mb-2">
                  <div className="mb-2 block text-left">
                    <Label
                      htmlFor="phoneNumber"
                      value="Phone Number"
                      className="!text-[#464646] font-normal text-lg leading-[1rem]"
                    />
                  </div>
                  <div className="relative w-full">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"></div>
                    <input
                      name="phonenumber"
                      type="text"
                      className="pl-6 w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm"
                      onChange={handleChange}
                      value={values?.phonenumber}
                      onBlur={handleBlur}
                      maxLength={11}
                    />
                    {touched?.phonenumber && errors?.phonenumber && (
                      <span className="text-sm text-red-600">
                        {typeof errors?.phonenumber == "string"
                          ? errors?.phonenumber
                          : ""}
                      </span>
                    )}
                    {""}
                  </div>

                  <div className="relative w-full mb-2">
                    <div className="mb-2 block text-left text-[#666666]">
                      <Label
                        value="Select Network"
                        className="!text-[#464646] font-normal text-lg leading-[1rem]"
                      />
                    </div>
                    <div className="relative w-full">
                      <StateDropdown
                        name="networkProvidersName"
                        id="selectnetwork"
                        onChange={handleChange}
                        variant="h-12 w-full text-sm"
                        value={values?.networkProvidersName}
                        options={getNetworkOptions()}
                        onSelectOption={(e) => {
                          setFieldValue("networkbundle", "");
                          setFieldValue("amount", 0);
                          setFieldValue("networkId", e?.id);
                          setSelectedNetwork(e?.dataPackages);
                        }}
                        placeholder="Select Network"
                      />
                      {touched?.networkProvidersName &&
                        errors?.networkProvidersName && (
                          <span className="text-sm text-red-600">
                            {typeof errors?.networkProvidersName == "string"
                              ? errors?.networkProvidersName
                              : ""}
                          </span>
                        )}
                    </div>
                    {""}
                  </div>
                  <div className="relative w-full mb-2">
                    <div className="mb-2 block text-left text-[#666666]">
                      <Label
                        value="Select Data Bundle"
                        className="!text-[#464646] font-normal text-lg leading-[1rem]"
                      />
                    </div>
                    <div className="relative w-full">
                      <StateDropdown
                        name="networkbundle"
                        onChange={handleChange}
                        variant="h-12 w-full text-sm"
                        options={bundleList()}
                        onSelectOption={(e) => {
                          setSelectedBundle(e);
                          setFieldValue("amount", e?.amount);
                          setFieldValue("dataPackageId", e?.id);
                        }}
                        placeholder="Select Bundle"
                        value={values?.networkbundle}
                        selectedLabel={false}
                      />

                      {touched?.networkbundle && errors?.networkbundle && (
                        <span className="text-sm text-red-600">
                          {typeof errors?.networkbundle == "string"
                            ? errors?.networkbundle
                            : ""}
                        </span>
                      )}
                      {""}
                    </div>
                  </div>
                  <div className="relative w-full mb-2">
                    <div className="mb-2 block text-left">
                      <Label
                        htmlFor="amount"
                        value="Amount"
                        className="!text-[#464646] font-normal text-lg leading-[1rem]"
                      />
                    </div>
                    <div className="relative w-full">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-[#666666]">₦</span>
                      </div>
                      <input
                        name="amount"
                        type="number"
                        value={values?.amount}
                        id="dataInput"
                        className="pl-6 w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm"
                        readOnly
                      />
                    </div>
                    <input
                      name="networkId"
                      value={values?.networkId}
                      readOnly
                      disabled
                      hidden
                    />
                    <input
                      name="dataPackageId"
                      value={values?.dataPackageId}
                      readOnly
                      disabled
                      hidden
                    />

                    {touched?.amount && errors?.amount && (
                      <span className="text-sm text-red-600">
                        {typeof errors?.amount == "string"
                          ? errors?.amount
                          : ""}
                      </span>
                    )}
                  </div>
                </div>
                <div className="relative w-full md:max-w-sm mb-2 flex items-center justify-center">
                  <FormikButton
                    id="proceed-to-summary"
                    labelText="Proceed"
                    isDisabled={!isValid && !dirty}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default NewData;
