import RemitaPayment from "pages/Remita/RemitaPayment";
import WithRRR from "pages/Remita/WithRRR";
import { Storage } from "utils/inAppstorage";

const isProtected = Storage.getItem("afb-app-token");

export const routes = [
  {
    path: "/remita/withRRR",
    isAuthenticated: true,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
    element: <WithRRR />,
  },
  {
    path: "/remita/payment",
    isAuthenticated: true,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
    element: <RemitaPayment />,
  },
];

const unProtectedRoutes: any = [];

export const remitaRoutes = isProtected ? routes : unProtectedRoutes;
