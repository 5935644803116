import {
  BackedUpTransactionDetailsPayload,
  TransactionHistoryPayload,
  TransactionReceiptByCorrelationIdPayload,
  TransactionReceiptPayload,
} from "types/global";

//Security Questions / Forgot Password
export const GET_SECURITY_QUESTIONS: string = `info/security-questions`;
export const POST_SEND_OTP = "info/send-otp";
export const POST_RESET_SECURITY_QUESTION =
  "customer-management/reset-security-questions-outapp";
export const POST_RESET_SECURITY_QUESTION_IN_APP =
  "customer-management/reset-security-questions-inapp";

export const POST_SECURITY_QUESTION_VIA_USERNAME: string = `customer/security-questions`;
export const POST_ADMIN_PASSWORD_RESET: string = `customer-management/AdminPasswordReset`;
export const POST_RETRIEVE_USERNAME: string =
  "customer-management/retrieve-customer-username";
export const POST_RESET_USERNAME_SECURITY_QUESTION: string =
  "customer-management/reset-security-questions-outapp-with-accountnumber";

export const GET_VERIFY_USERNAME = (username: string, queryPurpose: number) => {
  if (queryPurpose === 0) return `customer/${username}`;
  return `customer/${username}?UsernameQueryPurpose=${queryPurpose}`;
};
export const GET_VERIFY_ACCOUNT_NUMBER = (accountNumber: string) => {
  return `customer/get-customer-with-accountnumber/${accountNumber}`;
};
export const GET_RESEND_OTP = (username: string) =>
  `info/ResendOtpAdmin?username=${username}`;

// Transaction History
export const GET_TRANSACTION_HISTORY = (payload: TransactionHistoryPayload) =>
  `transaction/history?accountNumber=${payload.accountNumber}&from=${payload.from}&to=${payload.to}&page=${payload.page}&pageSize=${payload.pageSize}&transactionOperation=${payload.transactionOperation}&currencyCode=${payload?.currencyCode}`;

export const GET_TRANSACTION_RECEIPT = (payload: TransactionReceiptPayload) =>
  `v${payload.version}/Transfer/GetReceiptDetails?AccountNumber=${payload.accountNumber}&TransReference=${payload.transReference}`;

export const GET_TRANSACTION_RECEIPT_BY_CORRELATION_ID = (
  payload: TransactionReceiptByCorrelationIdPayload
) =>
  `v${payload.version}/Transfer/ReceiptDetailsByCorrelationId/${payload.correlationId}`;

export const GET_BACKED_UP_TRANSACTION_DETAILS = (
  payload: BackedUpTransactionDetailsPayload
) =>
  `v${payload.version}/Transfer/TransactionsbackUpDetails?TransactionStan=${payload.transactionStan}`;
//Bulk Transfer
export const post_INTER_BANK_UPLOAD_DOCUMENT = `document/documentupload`;
export const post_INTRA_BANK_UPLOAD_DOCUMENT = `document/documentupload`;

export const GET_STATES = `card/states`;
export const GET_CITIES = (stateId: string) => `card/cities?StateId=${stateId}`;
export const GET_LGAS = (stateId: string) => `card/lgas?StateId=${stateId}`;
export const GET_BRANCHES = (stateId: string) =>
  `card/branches?StateId=${stateId}`;

// Export Account List
export const GET_EXPORT_ACCOUNT_LIST = (exportType: number) =>
  `customer/ExportAccountList?exportType=${exportType}`;

// POS request
export const POST_POS_REQUEST = "PointofSale(POS)/PosRequest";

// Cheque Confirmation
export const POST_CHEQUE_CONFIRMATION =
  "ChequeConfirmations/ChequeConfirmation";
