import { ReactComponent as BackArrow } from "alat-components-library/assets/images/icons/back-arrow-two.svg";
import { Textarea } from "flowbite-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useNotificationState } from "react-wrappers/stores/redux/notifications/slices";
import { NotificationStatusEnum } from "utils/enums";
import { NotificationStatusBadge } from "./NotificationStatusBadge";
import { ApproverBadge } from "./approverBadge";
import {
  handleAmount,
  ListItem,
} from "pages/Payments/AccountStatement/PendingStatementDetail";
import dayjs from "dayjs";

export const ViewChequeConfirmation = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");

  const { notificationsDetails } = useNotificationState();

  const amount = Number(notificationsDetails?.amount || 0);

  const remarks = notificationsDetails?.remark;
  const approversNames = notificationsDetails?.approversNames || [];
  const verifiersNames = notificationsDetails?.verifiersNames || [];
  const beneficiary = notificationsDetails?.beneficiaryName;
  const chequeNumber = notificationsDetails?.chequeNumber;
  const transactionType = notificationsDetails?.type;
  const date = notificationsDetails?.dateCreated;

  const isApprover = !!approversNames?.length;
  const adminName = isApprover ? approversNames.join() : verifiersNames.join();

  const handleDate = (dateString: string) => {
    if (!dateString) return "";
    const [date] = dateString.split(" ");
    const newDate = new Date(dateString);
    const isInvalid = String(newDate) === "Invalid Date";

    if (isInvalid) {
      return date;
    }
    return dayjs(newDate).format("DD/MM/YYYY");
  };

  return (
    <div className="lg:px-20 space-y-4 mt-5 px-4 lg:px-0">
      <div className="flex items-center">
        <h2 className="lg:text-2xl text-xl font-bold">
          Cheque Confirmation Request
        </h2>
      </div>
      <div className="mx-auto bg-white md:pb-20 pb-10 md:px-10 px-2 pt-5">
        <div className="flex py-2 justify-between items-center">
          <BackArrow
            id="back_btn"
            className="ml-5 cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <h2 className="lg:text-xl text-lg">Overview</h2>
          <div></div>
        </div>
        <hr />

        <div className="space-y-8">
          <div className="md:w-[671px] w-full mx-auto flex-col">
            <div className="w-full flex items-center justify-between p-3">
              <p className="text-md font-semibold leading-[21.96px] tracking-[2%] text-lib-alat-black">
                Cheque Confirmation
              </p>
              <NotificationStatusBadge status={status} />
            </div>

            {status == NotificationStatusEnum["Approved"] ? (
              <ApproverBadge
                label={isApprover ? "Approver:" : "Verifier:"}
                approverFullName=""
                approverUserName={adminName}
              />
            ) : status == NotificationStatusEnum["Rejected"] ? (
              <ApproverBadge
                label={isApprover ? "Approver:" : "Verifier:"}
                approverFullName=""
                approverUserName={adminName}
              />
            ) : (
              ""
            )}

            <div className=" text-base text-lib-alat-black font-medium py-5">
              Beneficiary details
            </div>
            <ListItem name="Beneficiary name:" value={beneficiary} />
            <ListItem name="Cheque number:" value={chequeNumber} />
            <div className=" text-base text-lib-alat-black font-medium py-5">
              Transaction Summary
            </div>
            <ListItem name="TRANSACTION TYPE:" value={transactionType} />
            <ListItem name="AMOUNT " value={handleAmount(amount)} />
            <ListItem name="DATE" value={handleDate(date)} />

            {/* <div className="w-full border-b border-b-[#E2E8F0] flex items-center justify-between p-3">
              <p className="text-[12.92px] leading-[21.96px] tracking-[2%] text-lib-alat-black">
                EMAIL
              </p>
              <p
                className="text-[15.28px] leading-[21.96px] tracking-[2%] text-lib-alat-black"
                id="email_text"
              >
                {notificationsDetails?.email}
              </p>
            </div> */}

            {status == NotificationStatusEnum["Rejected"] && (
              <div>
                <hr className="h-px  bg-gray-300 border-1 dark:bg-gray-700" />

                <p className="text-md leading-[21.96px] tracking-[2%] mt-2 p-3">
                  REASON FOR REJECTION
                </p>

                <Textarea
                  disabled
                  value={remarks}
                  rows={4}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
