import { Field, useField } from "formik";
import { useState, useRef, useEffect } from "react";
import { HiChevronDown } from "react-icons/hi";
import ErrorIcon from "../../../assets/icons/error-icon.svg";

interface Option {
  id?: number | string;
  label: string;
  value: any;
}

interface DropdownProps {
  id?: string;
  idButton?: string;
  name: string;
  options: Option[];
  placeholder?: string;
  label?: string;
  onChange: (name: string, value: string) => void;
  onClick?: any;
  // onBlur?: () => void;
  touched?: any;
  value?: any;
  error?: string;
  hasError?: boolean | any;
  height?: string;
  onSelectOption?: (option: any) => void;
}

export const SelectDropdown = ({
  id,
  idButton,
  value,
  name,
  options,
  placeholder = "Select an option",
  label,
  error,
  hasError = false,
  height = "h-auto",
  onSelectOption,
  onClick,
  onChange,
}: DropdownProps) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(
    options.find((option) => option.value === value) || null
  );
  const [isOpen, setIsOpen] = useState(false);
  const [touched, setTouched] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [field, meta, helpers] = useField(name);

  const handleOptionSelect = (option: Option) => {
    setSelectedOption(option);
    helpers.setValue(option.value); // set the Formik field value
    setIsOpen(false);
    onChange(name, option.value); // call onChange with the updated value
    onSelectOption && onSelectOption(option);
  };

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
    setTouched(!touched);
    // onClick();
    // console.log("to", touched);
    // onBlur && onBlur();
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
      // onClick();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Field name={name} id={id}>
      {({ field }: { field: any }) => (
        <div ref={dropdownRef} className="relative">
          {label && <p className="text-sm mb-2.5 text-black">{label}</p>}
          <div className="relative">
            <div className="relative">
              <button
                type="button"
                id={idButton}
                className={`w-full px-4 py-3 text-left truncate ${
                  !selectedOption?.label && "text-sm"
                } text-sm  rounded-[5px] border ${
                  meta.error && "border-red-500 focus:border-red-500"
                } border-gray-300 bg-gray-100  focus:bg-white focus:outline-none ${
                  selectedOption?.label ? "text-black" : "text-gray-300"
                }`}
                onClick={handleDropdownClick}
              >
                {selectedOption?.label || placeholder}
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <HiChevronDown className="h-5 w-5" />
                </span>
              </button>
            </div>

            {/* {
              error && (
                <p className="text-[#ff0000] text-xs h-auto pt-2 flex items-center font-normal gap-1">
                  <ErrorIcon />{error}
                </p>
              )
            } */}
            {isOpen && (
              <ul
                className={`absolute z-10 w-full ${height} py-1 mt-1 bg-gray-50 overflow-auto focus:bg-white rounded-lg shadow-lg`}
              >
                {options.map((option, index) => (
                  <li
                    id={`${id}-${index}_btn`}
                    key={option.value}
                    className="px-4 py-2 hover:bg-gray-50 cursor-pointer text-sm"
                    onClick={() => handleOptionSelect(option)}
                  >
                    {option.label}
                  </li>
                ))}
              </ul>
            )}
            {hasError && (
              <div className="flex gap-2 mt-1">
                <img src={ErrorIcon} alt="Error" />
                <p className="text-red-500  text-xs h-auto">
                  {error ?? meta.error}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </Field>
  );
};
