import { lazy } from "react";
import { Storage } from "utils/inAppstorage";

const LoansIntro = lazy(() =>
  import("pages/Loans/LoansIntro").then(({ LoansIntro }) => ({
    default: LoansIntro,
  }))
);

const isProtected = Storage.getItem("afb-app-token");

export const routes = [
  {
    path: "/loans/:tabPath",
    isAuthenticated: true,
    element: <LoansIntro />,
    allowedRoles: ["soleproprietor"],
  },
];

const unProtectedRoutes: any = [];

export const loansRoutes = isProtected ? routes : unProtectedRoutes;
