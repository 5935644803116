import { Formik, Form } from "formik";
import {
  FormikButton,
  PasswordInput,
  PrimaryButton,
  TextInput,
} from "alat-components-library";
import PageLoader from "components/Loader/PageLoader";
import { ReusableCard } from "components/reusables/Page/ReusableCard";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { CardActivationPayload2 } from ".";
import { useEffect } from "react";
import { useHotListCard } from "hooks/apis/Cards/utilityCards/useHotListCard";
import { SelectDropdown } from "components/Dashboard/components/SelectDropdown";
import { useCardState } from "react-wrappers/stores/redux/cards/slices";
import { cardHotlistValidationSchema } from "pages/Cards/validations/cardHotlist.validations";

interface CardPayload {
  softToken: string;
  hardTokenOtp: string;
  hardTokenPin: string;
  pin: string;
  reasonCode: string;
  comment: string;
}

const CardHotListForm = ({
  handlePrev,
  onNext,
  cardPayload,
}: {
  handlePrev: () => void;
  cardPayload?: CardActivationPayload2 | null;
  onNext: (msg?: string) => void;
}) => {
  const { isLoading, handleHotList, responseData, status } = useHotListCard();
  const auth = useAuthState();
  const { loading, hotlist_reasons } = useCardState();

  const authType = () => {
    if (auth?.user?.hasTransactionPin) return "pin";
    if (auth?.user?.hasSoftToken && !auth?.user?.hasTransactionPin)
      return "soft";
    return "hard";
  };

  const handleSubmit = (values: CardPayload) => {
    const isToken = authType() === "soft";
    const card = cardPayload?.card;

    const payload = {
      isSoftToken: isToken,
      cardRouteId: cardPayload?.card?.cardRouteId,
      maskedPan: card?.maskedPan,
      expiryDate: card?.expiryDate,
      accountNumber: card?.linkedAccounts?.[0],
      reasonCode: values.reasonCode,
      comment: values.comment,
      token: values.softToken || values.hardTokenOtp,
      transactionPin: values.pin || values.hardTokenPin,
      cardTypeRequest: 2,
    };
    handleHotList(payload);
  };

  // Navigate to success screen
  useEffect(() => {
    if (status) {
      const msg = responseData;
      onNext(msg);
      onNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
  const hotlistLoading = loading.includes("POST_SUBMIT_CARD_HOTLIST");

  return (
    <div className="w-full">
      {(isLoading || hotlistLoading) && <PageLoader />}
      <ReusableCard
        cardTitle="Details"
        className="mt-0 border-b"
        variant="md:w-[48rem] w-full mx-auto bg-white border border-alat-gray-input-border rounded-lg"
        handleBackNavigation={handlePrev}
        showGoBackIcon
      >
        <div className="max-w-[409px] mx-auto">
          <Formik
            initialValues={{
              softToken: "",
              hardTokenOtp: "",
              hardTokenPin: "",
              pin: "",
              reasonCode: "",
              comment: "",
            }}
            onSubmit={handleSubmit}
            validateOnChange
            validationSchema={cardHotlistValidationSchema(authType())}
          >
            {({
              handleChange,
              handleBlur,
              errors,
              touched,
              isValid,
              dirty,
              values,
              setFieldValue,
            }) => (
              <Form>
                <div>
                  <div className="flex flex-wrap justify-center gap-4 ">
                    <div className="text-alat-grey relative w-full lg:max-w-sm mb-2 pb-5">
                      <p className="text-sm py-4 text-[#606060] text-center">
                        Fill the details below to hotlist your card
                      </p>
                      <div className="relative w-full lg:max-w-sm pb-4">
                        <SelectDropdown
                          id="reason_dropdown"
                          options={hotlist_reasons?.map((r: any) => ({
                            id: r.id,
                            value: r.code,
                            label: r.description,
                          }))}
                          name="reasonCode"
                          label="Reason"
                          onSelectOption={(val) => {
                            setFieldValue("comment", val.label);
                          }}
                          value={values?.reasonCode}
                          placeholder="-- Select Reason--"
                          onChange={(e, value) => {
                            handleChange(value);
                          }}
                          hasError={errors?.reasonCode && touched?.reasonCode}
                          error={errors?.reasonCode}
                        />
                      </div>
                      {/* Disabled by PO (Kemi and Mr. David) because others does not exist  */}

                      {/* {values?.reasonCode && (
                        <div className="relative w-full lg:max-w-sm ">
                          <TextArea
                            rows={7}
                            cols={7}
                            label="Provide a Reason"
                            handleChange={handleChange}
                            wordLength={
                              values?.comment !== ""
                                ? values?.comment?.length?.toString()
                                : "0"
                            }
                            name="comment"
                            placeholder="Type your reason.."
                            value={values?.comment}
                            labelVariant="pt-5 pb-2"
                            hasError={
                              errors?.comment && touched?.comment ? true : false
                            }
                            error={errors?.comment}
                          />
                        </div>
                      )} */}

                      {authType() === "pin" && (
                        <div className="pb-4">
                          <PasswordInput
                            name={"pin"}
                            label={"Enter AFB PIN"}
                            placeHolder={""}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.pin}
                            hasError={errors?.pin && touched?.pin}
                            error={errors?.pin}
                            iconStyle={{
                              wrapper: "",
                              icon: "w-[3.5vh] h-[3.5vh]",
                            }}
                            maxLength={4}
                          />
                        </div>
                      )}
                      {authType() === "soft" && (
                        <div className="pb-4">
                          <TextInput
                            id="token_input"
                            name={"softToken"}
                            type={"password"}
                            label={"Enter Soft Token"}
                            placeHolder={""}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.softToken}
                            hasError={errors?.softToken && touched?.softToken}
                            error={errors?.softToken}
                            maxLength={6}
                          />
                        </div>
                      )}

                      {authType() === "hard" && (
                        <>
                          <div className="pb-4">
                            <PasswordInput
                              name={"hardTokenPin"}
                              label={"Enter Hard Token PIN"}
                              placeHolder={""}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              value={values.hardTokenPin}
                              hasError={
                                errors?.hardTokenPin && touched?.hardTokenPin
                              }
                              error={errors?.hardTokenPin}
                              iconStyle={{
                                wrapper: "",
                                icon: "w-[3.5vh] h-[3.5vh]",
                              }}
                              maxLength={4}
                            />
                          </div>
                          <div className="pb-4">
                            <TextInput
                              id="token_input"
                              name={"hardTokenOtp"}
                              type={"password"}
                              label={"Enter Hard Token OTP"}
                              placeHolder={""}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              value={values?.hardTokenOtp}
                              hasError={
                                errors?.hardTokenOtp && touched?.hardTokenOtp
                              }
                              error={errors?.hardTokenOtp}
                              maxLength={6}
                            />
                          </div>
                        </>
                      )}
                      <div className="relative w-full py-6 mb-2  flex gap-4 items-center justify-center">
                        <PrimaryButton
                          id="cance_btn"
                          labelText={"Cancel"}
                          variant="font-medium text-xs text-lib-alat-red"
                          containerVariant="w-full h-[48px] rounded-md flex justify-center border border-lib-alat-red cursor-pointer bg-white py-3 hover:bg-[#f2f5fc]"
                          handleClick={handlePrev}
                        />
                        <FormikButton
                          id="proceed"
                          labelText="Hot List"
                          isDisabled={!isValid && !dirty}
                          containerVariant={`w-full h-[48px] !py-0 rounded-md flex justify-center items-center`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ReusableCard>
    </div>
  );
};

export default CardHotListForm;
