import { XMarkIcon } from "@heroicons/react/24/solid";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import PageHeader from "components/reusables/Page/PageHeader";
import { Button, Card } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import FundsTransferModalTab from "./FundTransferModalTab";

const InitiatorReject = () => {
  const navigate = useNavigate();

  return (
    <DashboardWrapper>
      <div className="container-fluid 2xl:container mx-auto ">
        <div className="container-fluid 2xl:container mx-auto">
          <main className="max-h-screen overflow-auto">
            <div className="pr-6 py-8">
              <div className="max-w-6xl mx-auto">
                <div className="rounded-3xl mb-5">
                  <div className="ml-4">
                    <PageHeader title="Funds Transfer" />
                  </div>
                  <FundsTransferModalTab active={1}>
                    <Card className="w-[768px] bg-white dark:border-gray-200 mx-auto">
                      <div>
                        <div className="text-center flex justify-center mt-10">
                          <div className="flex justify-center items-center w-16 h-16 bg-red-200 rounded-full">
                            <div className="flex justify-center items-center w-10 h-10 bg-red-600 rounded-full">
                              <div className=" flex justify-center items-center w-8 h-8 bg-red-600 rounded-full">
                                <div className=" flex justify-center items-center w-6 h-6 bg-red-600 rounded-full">
                                  <XMarkIcon className="mx-auto w-6 h-6 text-white opacity-100 light:text-gray-200" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-center text-3xl font-semibold mt-6">
                          Transaction Failedl!
                        </div>
                        <h3 className="text-center mb-5 text-lg font-normal mt-10">
                          Please try again
                        </h3>
                        <div className="flex justify-center flex-row gap-4 mb-40 mt-10">
                          <Button
                            color="gray"
                            onClick={() => navigate("/user-dashboard")}
                            className="hover:text-white dark:hover:!text-primary dark:hover:bg-white dark:border-gray-200 dark:text-primary border-primary text-primary  w-[188px] bg-primary hover:bg-primary focus:ring-1 focus:ring-primary disabled:hover:bg-primary dark:bg-primary  dark:focus:ring-primary dark:disabled:hover:bg-primary focus:text-primary focus:bg-white"
                          >
                            Return to dashboard
                          </Button>
                          <Button
                            color="gray"
                            onClick={() => navigate("/payments/funds-transfer")}
                            className="hover:text-white dark:hover:!text-primary dark:hover:bg-white dark:border-gray-200 dark:text-primary border-primary text-primary  w-[188px] bg-primary hover:bg-primary focus:ring-1 focus:ring-primary disabled:hover:bg-primary dark:bg-primary  dark:focus:ring-primary dark:disabled:hover:bg-primary focus:text-primary focus:bg-white"
                          >
                            Try again
                          </Button>
                        </div>
                      </div>
                    </Card>
                  </FundsTransferModalTab>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default InitiatorReject;
