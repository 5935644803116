import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import {
  FormikButton,
  PasswordInput,
  PrimaryButton,
  TextInput,
} from "alat-components-library";
import AccountCardTwo from "alat-components-library/Cards/AccountCardOne/AccountCardTwo";
import PageLoader from "components/Loader/PageLoader";
import { SelectDropdown } from "components/Dashboard/components/SelectDropdown";
import { useCardActivation } from "controllers/Cards/CardActivation";
import { useCardChangePin } from "controllers/Cards/CardChangePin";
import { Card } from "flowbite-react";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { useCardState } from "react-wrappers/stores/redux/cards/slices";

const ChangeCardPinScreen = ({ setScreen }: any) => {
  const navigate = useNavigate();
  const auth = useAuthState();
  const { loading, selectedActiveCard } = useCardState();

  // Local State
  const { handleSubmit } = useCardChangePin(setScreen);

  const authType = () => {
    if (auth?.user?.hasTransactionPin) return "pin";
    if (auth?.user?.hasSoftToken && !auth?.user?.hasTransactionPin)
      return "soft";
    return "hard";
  };

  return (
    <div className="w-full">
      {!!loading.includes("GET_ACCOUNT_CARDS") && (
        <PageLoader message={`Processing card details, please wait...`} />
      )}

      {!!loading.includes("POST_SUBMIT_CARD_CHANGE_PIN") && (
        <PageLoader message={`Processing card change PIN, please wait...`} />
      )}

      <Card className=" bg-white md:w-[58rem] w-full mx-auto dark:bg-white dark:border-transparent border-transparent">
        <div className="flex items-center justify-between">
          <ChevronLeftIcon
            onClick={() => navigate(-1)}
            id="back_btn"
            className="h-6 w-6 text-[#333333] cursor-pointer"
          />
          <div
            className="text-[#4D4D4D] text-xl text-center"
            id="banktransfer_heading"
          >
            Details
          </div>
          <div></div>
        </div>
        <div className="flex items-center justify-center">
          {/* <AccountCardOne /> */}
          <AccountCardTwo
            cardName={selectedActiveCard?.nameOnCard}
            cardNumber={selectedActiveCard?.pan}
            isActive={true}
            expiryDate={selectedActiveCard?.expiryDate}
            handleClick={() => ""}
          />
        </div>

        <hr />
        <Formik
          initialValues={{
            currentPin: "",
            newCardPin: "",
            confirmNewCardPin: "",
            softToken: "",
            hardTokenOtp: "",
            hardTokenPin: "",
            pin: "",
            oldPin: "",
            newPin: "",
            confirmNewPin: "",
          }}
          onSubmit={handleSubmit}
          validateOnChange
        >
          {({
            handleChange,
            handleBlur,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
            values,
            setFieldValue,
          }) => (
            <Form>
              <div>
                <div className="flex flex-wrap justify-center gap-4 ">
                  <div className="text-alat-grey relative w-full lg:max-w-sm mb-2 pb-5">
                    <p className="text-sm py-4 text-[#606060] text-center">
                      Fill the details below to change card pin
                    </p>
                    <div className="relative w-full lg:max-w-sm my-4">
                      <TextInput
                        id="current_pin"
                        type={"password"}
                        label="Enter Current PIN"
                        name="oldPin"
                        placeHolder="Enter Current PIN"
                        value={values.oldPin}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        autoComplete="off"
                        maxLength={4}
                        isPin
                      />
                    </div>

                    <div className="relative w-full lg:max-w-sm my-4">
                      <TextInput
                        id="new_pin"
                        type={"password"}
                        label="Enter New PIN"
                        name="newPin"
                        placeHolder="Enter New PIN"
                        value={values.newPin}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        autoComplete="off"
                        maxLength={4}
                        isPin
                      />
                    </div>

                    <div className="relative w-full lg:max-w-sm my-4">
                      <TextInput
                        id="new_pin"
                        type={"password"}
                        label="Confirm New PIN"
                        name="confirmNewPin"
                        placeHolder="Confirm New PIN"
                        value={values.confirmNewPin}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        autoComplete="off"
                        maxLength={4}
                        isPin
                      />
                    </div>

                    {authType() === "pin" && (
                      <PasswordInput
                        name={"pin"}
                        label={"Enter AFB PIN"}
                        placeHolder={""}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.pin}
                        hasError={errors?.pin && touched?.pin}
                        error={errors?.pin}
                        iconStyle={{
                          wrapper: "",
                          icon: "w-[3.5vh] h-[3.5vh]",
                        }}
                        maxLength={4}
                      />
                    )}
                    {authType() === "soft" && (
                      <div className="py-4">
                        <TextInput
                          id="token_input"
                          name={"softToken"}
                          type={"password"}
                          label={"Enter Soft Token"}
                          placeHolder={""}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values?.softToken}
                          hasError={errors?.softToken && touched?.softToken}
                          error={errors?.softToken}
                          maxLength={6}
                        />
                      </div>
                    )}

                    {authType() === "hard" && (
                      <>
                        <div className="py-4">
                          <PasswordInput
                            name={"hardTokenPin"}
                            label={"Enter Hard Token PIN"}
                            placeHolder={""}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.hardTokenPin}
                            hasError={
                              errors?.hardTokenPin && touched?.hardTokenPin
                            }
                            error={errors?.hardTokenPin}
                            iconStyle={{
                              wrapper: "",
                              icon: "w-[3.5vh] h-[3.5vh]",
                            }}
                            maxLength={4}
                          />
                        </div>
                        <div className="py-4">
                          <TextInput
                            id="token_input"
                            name={"hardTokenOtp"}
                            type={"password"}
                            label={"Enter Hard Token OTP"}
                            placeHolder={""}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.hardTokenOtp}
                            hasError={
                              errors?.hardTokenOtp && touched?.hardTokenOtp
                            }
                            error={errors?.hardTokenOtp}
                            maxLength={6}
                          />
                        </div>
                      </>
                    )}
                    <div className="relative w-full py-6 mb-2  flex gap-4 items-center justify-center">
                      <PrimaryButton
                        id="delete_btn"
                        labelText={"Cancel"}
                        variant="font-medium text-xs text-lib-alat-red"
                        containerVariant="w-full h-[48px] rounded-md flex justify-center border border-lib-alat-red cursor-pointer bg-white py-3 hover:bg-[#f2f5fc]"
                        handleClick={() => navigate(-1)}
                      />
                      <FormikButton
                        id="proceed"
                        labelText="Change Pin"
                        isDisabled={!isValid && !dirty}
                        containerVariant={`w-full h-[48px] rounded-md flex justify-center item-center`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default ChangeCardPinScreen;
