import { Formik, Form } from "formik";
import {
  FormikButton,
  PasswordInput,
  TextInput,
} from "alat-components-library";
import PageLoader from "components/Loader/PageLoader";
import { ReusableCard } from "components/reusables/Page/ReusableCard";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { useActivateCard } from "hooks/apis/Cards/utilityCards/useActivateCard";
import { CardActivationPayload2 } from ".";
import { useEffect } from "react";
import { cardActivationValidationSchema } from "pages/Cards/validations/cardActivation.validations";

interface CardPayload {
  cardNumber: string;
  cardPin: string;
  confirmCardPin: string;
  softToken: string;
  hardTokenOtp: string;
  hardTokenPin: string;
  pin: string;
}
const ActivationForm = ({
  handlePrev,
  onNext,
  cardPayload,
}: {
  handlePrev: () => void;
  cardPayload?: CardActivationPayload2 | null;
  onNext: (msg?: string) => void;
}) => {
  const { isLoading, handleActivateCard, responseData, status } =
    useActivateCard();
  const auth = useAuthState();
  const authType = () => {
    if (auth?.user?.hasTransactionPin) return "pin";
    if (auth?.user?.hasSoftToken && !auth?.user?.hasTransactionPin)
      return "soft";
    return "hard";
  };
  const handleSubmit = (values: CardPayload) => {
    const isToken = authType() === "soft";
    const card = cardPayload?.card;
    const sanitizedCardNumber = values.cardNumber.replace(/\s/g, "");
    const payload = {
      isSoftToken: isToken,
      fullPan: sanitizedCardNumber,
      expiryDate: card?.expiryDate,
      accountNumber: card?.linkedAccounts?.[0],
      newPin: values.cardPin,
      cardRouteId: cardPayload?.card?.cardRouteId,
      confirmNewPin: values.confirmCardPin,
      token: values.softToken || values.hardTokenOtp,
      transactionPin: values.pin || values.hardTokenPin,
      cardTypeRequest: 2,
    };
    handleActivateCard(payload);
  };

  // Navigate to success screen
  useEffect(() => {
    if (status) {
      const msg = responseData;
      onNext(msg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <div className="w-full">
      {isLoading && <PageLoader />}
      <ReusableCard
        cardTitle="Details"
        className="mt-0 border-b"
        variant="md:w-[48rem] w-full mx-auto bg-white border border-alat-gray-input-border rounded-lg"
        handleBackNavigation={handlePrev}
        showGoBackIcon
      >
        <div className="max-w-[409px] mx-auto">
          <Formik
            initialValues={{
              cardNumber: "",
              cardPin: "",
              confirmCardPin: "",
              softToken: "",
              hardTokenOtp: "",
              hardTokenPin: "",
              pin: "",
            }}
            onSubmit={handleSubmit}
            validateOnChange
            validationSchema={cardActivationValidationSchema(authType())}
          >
            {({
              handleChange,
              handleBlur,
              errors,
              touched,
              isValid,
              dirty,
              values,
              setFieldValue,
            }) => (
              <Form>
                <div>
                  <div className="flex flex-wrap justify-center gap-4 ">
                    <div className="text-alat-grey relative w-full lg:max-w-sm mb-2 pb-5">
                      <p className="text-sm py-4 text-[#606060] text-center">
                        Fill the details below to activate your card
                      </p>

                      <div className="relative w-full lg:max-w-sm my-4">
                        <TextInput
                          id="card_number"
                          type="text"
                          label="Enter Card Number"
                          name="cardNumber"
                          isOnlyNumber
                          placeHolder="Enter Card Number"
                          value={values.cardNumber}
                          handleChange={(e) => {
                            let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                            value = value.replace(/(.{4})/g, "$1 ").trim();

                            setFieldValue("cardNumber", value);
                          }}
                          handleBlur={handleBlur}
                          hasError={errors?.cardNumber && touched?.cardNumber}
                          error={errors?.cardNumber}
                          autoComplete="new-password"
                          maxLength={23}
                        />
                      </div>
                      <div className="relative w-full lg:max-w-sm mb-4">
                        <TextInput
                          id="card_pin"
                          label="Set 4 Digit Card PIN"
                          name="cardPin"
                          type={"password"}
                          placeHolder="Set 4 Digit Card PIN"
                          value={values.cardPin}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          hasError={errors?.cardPin && touched?.cardPin}
                          error={errors?.cardPin}
                          autoComplete="new-password"
                          maxLength={4}
                        />
                      </div>
                      <div className="relative w-full lg:max-w-sm mb-4">
                        <TextInput
                          id="confirm_pin"
                          label="Confirm PIN"
                          name="confirmCardPin"
                          type={"password"}
                          placeHolder="Confirm PIN"
                          value={values.confirmCardPin}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          hasError={
                            errors?.confirmCardPin && touched?.confirmCardPin
                          }
                          error={errors?.confirmCardPin}
                          autoComplete="off"
                          maxLength={4}
                        />
                      </div>
                      {authType() === "pin" && (
                        <div className="pb-4">
                          <PasswordInput
                            name={"pin"}
                            label={"Enter AFB PIN"}
                            placeHolder={""}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.pin}
                            hasError={errors?.pin && touched?.pin}
                            error={errors?.pin}
                            autoComplete="off"
                            iconStyle={{
                              wrapper: "",
                              icon: "w-[3.5vh] h-[3.5vh]",
                            }}
                            maxLength={4}
                          />
                        </div>
                      )}
                      {authType() === "soft" && (
                        <div className="pb-4">
                          <TextInput
                            id="token_input"
                            name={"softToken"}
                            type={"password"}
                            label={"Enter Soft Token"}
                            placeHolder={""}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.softToken}
                            hasError={errors?.softToken && touched?.softToken}
                            error={errors?.softToken}
                            autoComplete="off"
                            maxLength={6}
                          />
                        </div>
                      )}

                      {authType() === "hard" && (
                        <>
                          <div className="pb-4">
                            <PasswordInput
                              name={"hardTokenPin"}
                              label={"Enter Hard Token PIN"}
                              placeHolder={""}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              value={values.hardTokenPin}
                              hasError={
                                errors?.hardTokenPin && touched?.hardTokenPin
                              }
                              autoComplete="off"
                              error={errors?.hardTokenPin}
                              iconStyle={{
                                wrapper: "",
                                icon: "w-[3.5vh] h-[3.5vh]",
                              }}
                              maxLength={4}
                            />
                          </div>
                          <div className="pb-4">
                            <TextInput
                              id="token_input"
                              name={"hardTokenOtp"}
                              type={"password"}
                              label={"Enter Hard Token OTP"}
                              placeHolder={""}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              autoComplete="off"
                              value={values?.hardTokenOtp}
                              hasError={
                                errors?.hardTokenOtp && touched?.hardTokenOtp
                              }
                              error={errors?.hardTokenOtp}
                              maxLength={6}
                            />
                          </div>
                        </>
                      )}
                      <div className="relative w-full py-6 mb-2  flex items-center justify-center">
                        <FormikButton
                          id="proceed"
                          labelText="Proceed"
                          isDisabled={!isValid && !dirty}
                          containerVariant={`w-full h-[48px] !py-0 rounded-md flex justify-center items-center`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ReusableCard>
    </div>
  );
};

export default ActivationForm;
