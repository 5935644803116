import { lazy } from "react";
import { ViewProfile } from "../pages/Profile/ViewProfile";
import { Storage } from "utils/inAppstorage";

const PinManagementContainer = lazy(() =>
  import("pages/Profile/PinManagement/PinManagementContainer").then(
    ({ PinManagementContainer }) => ({ default: PinManagementContainer })
  )
);
const PinSettings = lazy(() =>
  import("pages/Profile/PinSettings").then(({ PinSettings }) => ({
    default: PinSettings,
  }))
);
const MySettings = lazy(() =>
  import("pages/Profile/MySettings").then(({ MySettings }) => ({
    default: MySettings,
  }))
);
const SettingsManagementIntro = lazy(() =>
  import("pages/Profile/SettingsManagement/SettingsManagementIntro").then(
    ({ SettingsManagementIntro }) => ({
      default: SettingsManagementIntro,
    })
  )
);
const EnterNewPassword = lazy(() =>
  import("pages/Profile/SettingsManagement/EnterNewPassword").then(
    ({ EnterNewPassword }) => ({ default: EnterNewPassword })
  )
);
// const FundingSource = lazy(() =>
//   import("pages/Profile/TokenMgmt/FundingSource").then(({ FundingSource }) => ({
//     default: FundingSource,
//   }))
// );
const TokenManagementSettings = lazy(() =>
  import("pages/Profile/TokenManagementSettings").then(
    ({ TokenManagementSettings }) => ({ default: TokenManagementSettings })
  )
);
const RequestTokenContainer = lazy(() =>
  import("pages/Profile/TokenMgmt/RequestTokenContainer").then(
    ({ RequestTokenContainer }) => ({ default: RequestTokenContainer })
  )
);
const TakeSelfieForTokenRequest = lazy(() =>
  import("pages/Profile/TokenMgmt/TakeSelfieForTokenRequest").then(
    ({ TakeSelfieForTokenRequest }) => ({
      default: TakeSelfieForTokenRequest,
    })
  )
);
const VerifyIdentityForTokenRequest = lazy(() =>
  import("pages/Profile/TokenMgmt/VerifyIdentityForTokenRequest").then(
    ({ VerifyIdentityForTokenRequest }) => ({
      default: VerifyIdentityForTokenRequest,
    })
  )
);
const ForgotPinVerifyIdentity = lazy(() =>
  import("pages/Profile/PinManagement/ForgotPinVerifyIdentity").then(
    ({ ForgotPinVerifyIdentity }) => ({
      default: ForgotPinVerifyIdentity,
    })
  )
);
const TakeSelfieForForgotPin = lazy(() =>
  import("pages/Profile/PinManagement/TakeSelfieForForgotPin").then(
    ({ TakeSelfieForForgotPin }) => ({
      default: TakeSelfieForForgotPin,
    })
  )
);
const CreatePinVerifyIdentity = lazy(() =>
  import("pages/Profile/PinManagement/CreatePinVerifyIdentity").then(
    ({ CreatePinVerifyIdentity }) => ({
      default: CreatePinVerifyIdentity,
    })
  )
);
const CreatePinTakeSelfie = lazy(() =>
  import("pages/Profile/PinManagement/CreatePinTakeSelfie").then(
    ({ CreatePinTakeSelfie }) => ({
      default: CreatePinTakeSelfie,
    })
  )
);
const FailureScreen = lazy(() =>
  import("pages/Profile/SettingsManagement/FailureScreen").then(
    ({ FailureScreen }) => ({ default: FailureScreen })
  )
);
const AccountTypes = lazy(() =>
  import("pages/Profile/AccountTypes").then(({ AccountTypes }) => ({
    default: AccountTypes,
  }))
);
const ContactSupport = lazy(() =>
  import("../pages/Profile/ContactSupport").then(({ ContactSupport }) => ({
    default: ContactSupport,
  }))
);
const ReportError = lazy(() =>
  import("../pages/ContactSupport/ReportErrorOptions").then(
    ({ ReportErrorOptions }) => ({
      default: ReportErrorOptions,
    })
  )
);
const SecurityQuestion = lazy(() =>
  import("pages/Profile/SettingsManagement/SecurityQuestion").then(
    ({ SecurityQuestion }) => ({
      default: SecurityQuestion,
    })
  )
);
const SecurityQuestionDimec = lazy(() =>
  import("pages/Profile/SettingsManagement/SecurityQuestionDimec").then(
    ({ SecurityQuestionDimec }) => ({
      default: SecurityQuestionDimec,
    })
  )
);
const AccountsManagementIntro = lazy(() =>
  import("pages/Profile/AccountsManagement/AccountsManagementIntro").then(
    ({ AccountsManagementIntro }) => ({ default: AccountsManagementIntro })
  )
);
const ResetSecurityQuestionsVerifyIdentity = lazy(() =>
  import(
    "pages/Profile/SettingsManagement/ResetSecurityQuestionsVerifyIdentity"
  ).then(({ ResetSecurityQuestionsVerifyIdentity }) => ({
    default: ResetSecurityQuestionsVerifyIdentity,
  }))
);
const ResetSecurityQuestionsDaon = lazy(() =>
  import("pages/Profile/SettingsManagement/ResetSecurityQuestionsDaon").then(
    ({ ResetSecurityQuestionsDaon }) => ({
      default: ResetSecurityQuestionsDaon,
    })
  )
);

export const PROFILE_URL = {
  REPORT_ERROR: "/profile/report-error",
  CONTACT_SUPPORT: "/profile/get-support",
  RESET_SECURITY_QUESTIONS: "/profile/account-settings/reset-security-question",
  RESET_SECURITY_QUESTIONS_SELFIE:
    "/profile/reset-security-questions/take-selfie",
  RESET_SECURITY_QUESTIONS_VERIFY_ID:
    "/profile/reset-security-questions/verify-identity",
};
export const routes = [
  {
    path: "/profile",
    isAuthenticated: true,
    element: <ViewProfile />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },
  {
    path: PROFILE_URL.CONTACT_SUPPORT,
    isAuthenticated: true,
    element: <ContactSupport />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },
  {
    path: PROFILE_URL.REPORT_ERROR,
    isAuthenticated: true,
    element: <ReportError />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },
  {
    path: "/profile/account-settings",
    isAuthenticated: true,
    element: <MySettings />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },
  {
    path: "/profile/security-question",
    isAuthenticated: true,
    element: <SecurityQuestion />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },
  {
    path: PROFILE_URL.RESET_SECURITY_QUESTIONS,
    isAuthenticated: true,
    element: <SecurityQuestionDimec />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },
  {
    path: PROFILE_URL.RESET_SECURITY_QUESTIONS_VERIFY_ID,
    element: <ResetSecurityQuestionsVerifyIdentity />,
    isAuthenticated: true,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },
  {
    path: PROFILE_URL.RESET_SECURITY_QUESTIONS_SELFIE,
    element: <ResetSecurityQuestionsDaon />,
    isAuthenticated: true,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },
  {
    path: "/profile/account-settings/:tabPath",
    isAuthenticated: true,
    element: <SettingsManagementIntro />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },
  {
    path: "/profile/pin-settings",
    isAuthenticated: true,
    element: <PinSettings />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },
  {
    path: "/profile/change-password",
    isAuthenticated: true,
    element: <EnterNewPassword />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },

  {
    path: "/profile/change-password/failed",
    isAuthenticated: true,
    element: <FailureScreen />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },
  {
    path: "/profile/pin-management/:tabPath", // forgot pin
    isAuthenticated: true,
    element: <PinManagementContainer />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },
  {
    path: "/forgot-pin/verify-identity",
    isAuthenticated: true,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
    element: <ForgotPinVerifyIdentity />,
  },
  {
    path: "/create-pin/verify-identity",
    isAuthenticated: true,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
    element: <CreatePinVerifyIdentity />,
  },
  {
    path: "/forgot-pin/take-selfie",
    isAuthenticated: true,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
    element: <TakeSelfieForForgotPin />,
  },
  {
    path: "/create-pin/take-selfie",
    element: <CreatePinTakeSelfie />,
    isAuthenticated: true,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },
  {
    path: "/profile/token-management",
    isAuthenticated: true,
    element: <TokenManagementSettings />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },
  {
    path: "/profile/token-management/:tabPath",
    isAuthenticated: true,
    element: <RequestTokenContainer />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },
  {
    path: "/profile/accounts/:tabPath",
    isAuthenticated: true,
    element: <AccountsManagementIntro />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/profile/token-management/request-soft-token/take-selfie",
    isAuthenticated: true,
    element: <TakeSelfieForTokenRequest />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },
  {
    path: "/profile/token-management/request-soft-token/verify-identity",
    isAuthenticated: true,
    element: <VerifyIdentityForTokenRequest />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },
  // {
  //   path: "/profile/token-management/request-soft-token/funding-source",
  //   isAuthenticated: true,
  //   element: <FundingSource />,
  // },

  // Accounts
  {
    path: "/profile/accounts",
    isAuthenticated: true,
    element: <AccountTypes />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/profile/accounts/:tabPath",
    isAuthenticated: true,
    element: <AccountsManagementIntro />,
    allowedRoles: ["soleproprietor"],
  },
];

const isProtected = Storage.getItem("afb-app-token");
const unProtectedRoutes: any = [];

export const profileRoutes = isProtected ? routes : unProtectedRoutes;
