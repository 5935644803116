import React, { useState } from "react";
import { ToastNotify } from "../../../helpers/toastNotify";
import { useApiRequest } from "../../useApiRequest";
import { useAppDispatch } from "../../useReduxHook";
import {
  setLoadingState,
  setPrequalifiedLoanAmount,
} from "../../../react-wrappers/stores/redux/loans/slices";
import { GET_PREQUALIFIED_LOAN_AMOUNT } from "utils/apis/routes/loans";

export const useFetchPrequalifiedAmount = () => {
  const makeRequest = useApiRequest();
  const dispatch = useAppDispatch();
  const [prequalifiedAmount, setPrequalifiedAmount] = useState<number>(0);
  const [prequalifiedErrorMsg, setPrequalifiedErrorMsg] = useState<string>("");

  const handleFetchPrequalifiedAmount = async () => {
    dispatch(setLoadingState(["fetch-prequalified-amount"]));
    try {
      const response = await makeRequest({
        method: "get",
        route: GET_PREQUALIFIED_LOAN_AMOUNT,
        originType: "adhocservice",
      });
      // console.log("response", response);
      if (response?.data?.httpStatusCode === 200) {
        dispatch(setPrequalifiedLoanAmount(response?.data?.data?.amount));
        setPrequalifiedAmount(response?.data?.data?.amount);
      } else {
        dispatch(setPrequalifiedLoanAmount(0));
        setPrequalifiedErrorMsg(
          `${response?.data?.data?.responseMessage?.title}. ${response?.data?.data?.responseMessage?.actionMessage}`
        );
        response?.status === 500 &&
          ToastNotify({
            type: "error",
            message: "Oops! Something went wrong",
            position: "top-right",
          });
      }
      dispatch(setLoadingState([]));
    } catch (err: any) {
      dispatch(setLoadingState([]));
      if (err?.response?.status === 500) {
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        ToastNotify({
          type: "error",
          message:
            err?.response?.data?.responseMessage?.title ||
            err?.response?.data?.responseMessage?.actionMessage ||
            "",
          position: "top-right",
        });
      }
    }
  };
  return {
    handleFetchPrequalifiedAmount,
    prequalifiedAmount,
    prequalifiedErrorMsg,
  };
};
