import { Button, Card } from "flowbite-react";
import React, { useState } from "react";
import Mtn from "assets/svgs/mtn.svg";
import ButtonImage from "assets/svgs/icons8-delete.svg";
import mobile from "assets/svgs/9Mobile-Telecom-Logo.svg";
import airtellogo from "assets/svgs/airtel-logo.svg";
import glo from "assets/svgs/glo-logo.svg";
import { useSavedBeneficiaryController } from "controllers/AirtimeAndData/AirtimeController";
import Spinners from "components/Spinner/Spinner";

import { ReactComponent as FourUserIcon } from "assets/svgs/phoneIcon.svg";
import { useNavigate } from "react-router-dom";
import Cards from "./Cards";
import PageLoader from "components/Loader/PageLoader";

const BuyAirtimeMain = () => {
  const {
    SavedBeneficiaryData,
    SavedBeneficiaryLoading,
    handleAirtimeBeneficiary,
    setSavedBeneficiaryData,
  } = useSavedBeneficiaryController();

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredBeneficiaries, setFilteredBeneficiaries] = useState([]);

  const handleChange = (e: any) => {
    setSearchTerm(e?.target?.value);
    const filteredBeneficiaries = SavedBeneficiaryData?.beneficiaries?.filter(
      (beneficiary: any) =>
        beneficiary?.alias
          .toLowerCase()
          .includes(e?.target?.value?.toLowerCase()) ||
        beneficiary?.phoneNumber?.includes(e.target.value)
    );
    setFilteredBeneficiaries(filteredBeneficiaries);
  };

  const navigate = useNavigate();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const handleDeleteCard = (beneficiary: any, e: React.MouseEvent) => {
    e.stopPropagation();

    // Filter out the deleted beneficiary card from the SavedBeneficiaryData state
    const updatedBeneficiaries = SavedBeneficiaryData?.beneficiaries?.filter(
      (b: any) => b?.id !== beneficiary?.id
    );

    // Update the SavedBeneficiaryData state with the updated beneficiaries
    setSavedBeneficiaryData({
      ...SavedBeneficiaryData,
      beneficiaries: updatedBeneficiaries,
    });
  };

  const handleCardClick = (beneficiary: any) => {
    handleAirtimeBeneficiary(beneficiary);
  };

  return (
    <div className="w-full">
      {SavedBeneficiaryLoading && <PageLoader />}
      <div>
        <div className="flex justify-start mb-4">
          {/* Aligns content to the start */}
          <Button
            id="proceed-to-dashboard_btn"
            onClick={() => navigate("/Airtime/NewAirtime")}
            className="hover:text-white dark:hover:!text-white dark:hover:bg-primary md:w-[188px] w-full bg-primary hover:bg-red-700 focus:ring-1 focus:ring-primary disabled:hover:bg-primary dark:bg-primary dark:focus:ring-primary dark:disabled:hover:bg-primary hover:border hover:boder-primary focus:text-white"
          >
            Buy New Airtime
          </Button>
        </div>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={searchTerm}
            onChange={handleChange}
            placeholder="Search for beneficiary"
            className="w-full h-[60px] rounded-[8px] px-5 py-3 border-none box-border bg-white "
          />
        </form>

        {/* Beneficiaries */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: "10px",
          }}
        >
          {/* <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: '20px',
              height: '97px',
            }}
          ></div> */}

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {searchTerm ? (
              filteredBeneficiaries?.length ? (
                filteredBeneficiaries?.map(
                  (beneficiary: any, index: number) => {
                    let logoPath;

                    switch (beneficiary?.networkName) {
                      case "MTN":
                        logoPath = Mtn;
                        break;
                      case "GLO":
                        logoPath = glo;
                        break;
                      case "_9MOBILE":
                        logoPath = mobile;
                        break;
                      case "AIRTEL":
                        logoPath = airtellogo;
                        break;
                      default:
                        logoPath = ButtonImage;
                        break;
                    }

                    return (
                      <div
                        key={index}
                        style={{
                          flexBasis: "33%",
                          marginBottom: "10px",
                          boxSizing: "border-box",
                        }}
                      >
                        <Cards
                          title={beneficiary?.alias}
                          description={beneficiary?.phoneNumber}
                          logo={logoPath}
                          onClick={() => handleCardClick(beneficiary)} // Pass beneficiary as an argument
                          onDelete={(e: any) =>
                            handleDeleteCard(beneficiary, e)
                          }
                        />
                      </div>
                    );
                  }
                )
              ) : (
                <p>No matching beneficiaries found.</p>
              )
            ) : SavedBeneficiaryData?.beneficiaries?.length ? (
              SavedBeneficiaryData?.beneficiaries?.map(
                (beneficiary: any, index: number) => {
                  let logoPath;

                  switch (beneficiary?.networkName) {
                    case "MTN":
                      logoPath = Mtn;
                      break;
                    case "GLO":
                      logoPath = glo;
                      break;
                    case "_9MOBILE":
                      logoPath = mobile;
                      break;
                    case "AIRTEL":
                      logoPath = airtellogo;
                      break;
                    default:
                      logoPath = ButtonImage;
                      break;
                  }
                  return (
                    <div
                      key={index}
                      style={{
                        flexBasis: "33%",
                        marginBottom: "10px",
                        boxSizing: "border-box",
                      }}
                    >
                      <Cards
                        title={beneficiary?.alias}
                        description={beneficiary?.phoneNumber}
                        logo={logoPath}
                        onClick={() => handleCardClick(beneficiary)} // Pass beneficiary as an argument
                        onDelete={(e: any) => handleDeleteCard(beneficiary, e)}
                      />
                    </div>
                  );
                }
              )
            ) : (
              <div className="w-full">
                <Card className=" h-auto mx-auto bg-white pb-14  dark:bg-white dark:border-[#EDEDED] border-[#EDEDED] dark:shadow-white">
                  <div className="grid grid-cols-1 gap-4 text-alat-grey pt-40 mx-20">
                    <div className="flex justify-center">
                      <FourUserIcon fill="inherit" />
                    </div>
                    <div className="flex justify-center mt-3">
                      No Saved airtime recharge
                    </div>
                    <div className="flex justify-center">
                      {/* <Button
                          id="proceed-to-dashboard_btn"
                          onClick={() =>
                            navigate('/Bills/Airtime/BuyNewAirtime')
                          }
                          className="hover:text-white dark:hover:!text-white dark:hover:bg-primary  w-[188px] bg-primary hover:bg-red-700 focus:ring-1 focus:ring-primary disabled:hover:bg-primary dark:bg-primary  dark:focus:ring-primary dark:disabled:hover:bg-primary hover:border hover:boder-primary  focus:text-white"
                        >
                          Buy Airtime
                        </Button> */}
                    </div>
                  </div>
                </Card>
              </div>

              // <p>No saved beneficiaries found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default BuyAirtimeMain;
