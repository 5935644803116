import { lazy } from "react";
import { Storage } from "utils/inAppstorage";
import { PendingRequests } from "pages/Dashboard/PendingRequests";
import BulkTransfer from "pages/Payments/BulkTransfer";
import PendingTransactions from "pages/Payments/PendingTransactions";
import TransactionDetail from "pages/Payments/TransactionDetail";
import CollectionDetail from "pages/Payments/CollectionDetail";
import { BankTransfer, FundTransfer, MultipleTransfer } from "../pages";
import TransactionHistoryTab from "pages/Payments/components/TransactionHistoryTab";
import TransactionReceipt from "pages/Payments/TransactionReceipt";
import AccountStatementDetail from "pages/Payments/AccountStatement/Details";
import AccountStatementPaymentSummary from "pages/Payments/AccountStatement/PaymentSummary";
import AccountStatementSuccess from "pages/Payments/modals/AccountStatementSuccess";
import AccountStatementPending from "pages/Payments/modals/AccountStatementPending";

import NewBills from "pages/BillPayment/NewBills";
import SelectedBill from "pages/BillPayment/SelectedBill";
import PayBill from "pages/BillPayment/PayBill";
import RecipentList from "pages/Payments/RecipentList";
import SelectedCollection from "pages/Collections/SelectedCollection";
import SelectedMerchant from "pages/Collections/SelectedMerchant";
import CollectionForm from "pages/Collections/CollectionForm";
import CollectionPayment from "pages/Collections/CollectionPayment";
import BillsPaymentDetail from "pages/Payments/BillsPaymentDetail";

const FxTransferIntro = lazy(() =>
  import("pages/Payments/FxTransfer/FxTransferIntro").then(
    ({ FxTransferIntro }) => ({ default: FxTransferIntro })
  )
);
const FxPendingRequestDetails = lazy(() =>
  import("pages/Dashboard/Fx/FxPendingRequestDetails").then(
    ({ FxPendingRequestDetails }) => ({ default: FxPendingRequestDetails })
  )
);

const FxSalesIntro = lazy(() =>
  import("pages/Payments/FxSales/FxSalesIntro").then(({ FxSalesIntro }) => ({
    default: FxSalesIntro,
  }))
);
const FxSalesConversionIntro = lazy(() =>
  import("pages/Payments/FxSales/ConvertFx/FxSalesConversionIntro").then(
    ({ FxSalesConversionIntro }) => ({
      default: FxSalesConversionIntro,
    })
  )
);
const ReviewFxSalesTransaction = lazy(() =>
  import("pages/Payments/FxSales/Review/ReviewFxSalesTransaction").then(
    ({ ReviewFxSalesTransaction }) => ({
      default: ReviewFxSalesTransaction,
    })
  )
);
const FxSalesViewReceipt = lazy(() =>
  import("pages/Payments/FxSales/ReceiptDetails/FxSalesViewReceipt").then(
    ({ FxSalesViewReceipt }) => ({
      default: FxSalesViewReceipt,
    })
  )
);
const FxSalesOtpVerification = lazy(() =>
  import("pages/Payments/FxSales/Otp/FxSalesOtpVerification").then(
    ({ FxSalesOtpVerification }) => ({
      default: FxSalesOtpVerification,
    })
  )
);

const SuccessfulBillPayment = lazy(() =>
  import("components/BillsPayment/SuccessfulBillPayment").then(
    ({ SuccessfulBillPayment }) => ({ default: SuccessfulBillPayment })
  )
);

const BillsPaymentFailureScreen = lazy(() =>
  import("components/BillsPayment/BillsPaymentFailureScreen").then(
    ({ BillsPaymentFailureScreen }) => ({ default: BillsPaymentFailureScreen })
  )
);
const BillPurchaseReceipt = lazy(() =>
  import("components/BillsPayment/BillPurchaseReceipt").then(
    ({ BillPurchaseReceipt }) => ({ default: BillPurchaseReceipt })
  )
);

const ChequeConfirmation = lazy(() =>
  import("pages/Payments/ChequeConfirmation").then(
    ({ ChequeConfirmation }) => ({ default: ChequeConfirmation })
  )
);
const ReportTransactionError = lazy(() =>
  import("pages/Payments/ReportTransactionError").then(
    ({ ReportTransactionError }) => ({ default: ReportTransactionError })
  )
);

const isProtected = Storage.getItem("afb-app-token");

export const PAYMENT_URL = {
  PAYMENT_ERROR_REPORT: "/payment/error-report",
};
export const routes = [
  {
    path: "/payments/funds-transfer",
    isAuthenticated: true,
    element: <FundTransfer />,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
  },

  {
    path: "/payments/funds-transfer/:tabPath", //bank-transfer, fund-my-account, bulk-transfer
    isAuthenticated: true,
    element: <FundTransfer />,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
  },

  {
    path: "/payments/bank-transfer",
    isAuthenticated: true,
    element: <BankTransfer />,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
  },
  {
    path: "/payments/multiple-transfer",
    isAuthenticated: true,
    element: <MultipleTransfer />,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
  },
  {
    path: "/payments/transaction/details",
    isAuthenticated: true,
    element: <TransactionDetail />,
    allowedRoles: [
      "verifier",
      "approver",
      "initiatorapprover",
      "superapprover",
    ],
  },
  {
    path: "/payments/collections/details",
    isAuthenticated: true,
    element: <CollectionDetail />,
    allowedRoles: [
      "approver",
      "verifier",
      "initiatorapprover",
      "superapprover",
    ],
  },

  {
    path: "/payments/requests/details/:correlationId",
    isAuthenticated: true,
    element: <PendingRequests />,
    allowedRoles: [
      "verifier",
      "approver",
      "initiatorapprover",
      "superapprover",
    ],
  },
  {
    path: "/payments/fx/details/:correlationId",
    isAuthenticated: true,
    element: <FxPendingRequestDetails />,
    allowedRoles: [
      "verifier",
      "approver",
      "initiatorapprover",
      "superapprover",
    ],
  },
  {
    path: "/payments/pending-transactions",
    isAuthenticated: true,
    element: <PendingTransactions />,
    allowedRoles: [
      "verifier",
      "approver",
      "initiatorapprover",
      "superapprover",
    ],
  },
  {
    path: "/payments/bulk/recipent-list",
    isAuthenticated: true,
    element: <RecipentList />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "verifier",
      "approver",
      "superapprover",
    ],
  },
  {
    path: "/payments/bulk-transfer/:tabPath", //intra-transfer, inter-transfer, transaction-history
    isAuthenticated: true,
    element: <BulkTransfer />,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
  },
  {
    path: "/payments/transaction-history",
    isAuthenticated: true,
    element: <TransactionHistoryTab />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "approver",
      "verifier",
      "superapprover",
    ],
  },
  {
    path: PAYMENT_URL.PAYMENT_ERROR_REPORT,
    isAuthenticated: true,
    element: <ReportTransactionError />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "approver",
      "verifier",
      "superapprover",
    ],
  },
  {
    path: "/payments/transaction-receipt",
    isAuthenticated: true,
    element: <TransactionReceipt />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "verifier",
      "approver",
      "superapprover",
    ],
  },
  {
    path: "/payments/account-statement", //intra-transfer, inter-transfer, transaction-history
    isAuthenticated: true,
    element: <AccountStatementDetail />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "verifier",
      "approver",
      "superapprover",
    ],
  },
  {
    path: "/payments/account-statement/payment-summary",
    isAuthenticated: true,
    element: <AccountStatementPaymentSummary />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "verifier",
      "approver",
      "superapprover",
    ],
  },
  {
    path: "/payments/account-statement/success",
    isAuthenticated: true,
    element: <AccountStatementSuccess />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "verifier",
      "approver",
      "superapprover",
    ],
  },
  {
    path: "/payments/account-statement/pending",
    isAuthenticated: true,
    element: <AccountStatementPending />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "verifier",
      "approver",
      "superapprover",
    ],
  },
  {
    path: "/payments/bills/details",
    isAuthenticated: true,
    element: <BillsPaymentDetail />,
    allowedRoles: [
      "verifier",
      "approver",
      "initiatorapprover",
      "superapprover",
    ],
  },

  // CHEQUE CONFIRMATION
  {
    path: "/payments/cheque-confirmation",
    isAuthenticated: true,
    element: <ChequeConfirmation />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
    ],
  },

  //#region  FX - TRANSFER
  {
    path: "/payments/fx-transfer/:tabPath",
    isAuthenticated: true,
    element: <FxTransferIntro />,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
  },

  //#region  FX - SALES
  {
    path: "/payments/fx-sales",
    isAuthenticated: true,
    element: <FxSalesIntro />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/payments/fx-sales/convert",
    isAuthenticated: true,
    element: <FxSalesConversionIntro />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/payments/fx-sales/review",
    isAuthenticated: true,
    element: <ReviewFxSalesTransaction />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/payments/fx-sales/transaction-receipt",
    isAuthenticated: true,
    element: <FxSalesViewReceipt />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/payments/fx-sales/verify",
    isAuthenticated: true,
    element: <FxSalesOtpVerification />,
    allowedRoles: ["soleproprietor"],
  },

  //Bills Payment
  {
    path: "/Bills/Payment", //Buy airtime and data
    isAuthenticated: true,
    element: <NewBills />,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
  },

  {
    path: "/bills/payment/selected-bill",
    isAuthenticated: true,
    element: <SelectedBill />,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
  },
  {
    path: "/bills/payment/pay-bill",
    isAuthenticated: true,
    element: <PayBill />,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
  },
  {
    path: "/bills/payment/pay-bill-confirmation",
    isAuthenticated: true,
    element: <PayBill />,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
  },
  {
    path: "/bills/payment/pay-bill/otp",
    isAuthenticated: true,
    element: <PayBill />,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
  },
  {
    path: "/bills/payment/pay-bill/status",
    isAuthenticated: true,
    element: <PayBill />,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
  },
  {
    path: "/bills/payment/pay-bill/success",
    isAuthenticated: true,
    element: <SuccessfulBillPayment />,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
  },
  {
    path: "/bills/payment/pay-bill/failure",
    isAuthenticated: true,
    element: <BillsPaymentFailureScreen />,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
  },
  {
    path: "/bills/payment/pay-bill/receipt",
    isAuthenticated: true,
    element: <BillPurchaseReceipt />,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
  },
  {
    path: "/bills/collections/select-category",
    isAuthenticated: true,
    element: <SelectedCollection />,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
  },
  {
    path: "/bills/collections/category-item",
    isAuthenticated: true,
    element: <SelectedMerchant />,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
  },
  {
    path: "/bills/collections/form",
    isAuthenticated: true,
    element: <CollectionForm />,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
  },
  {
    path: "/bills/collections/simple-form",
    isAuthenticated: true,
    element: <CollectionForm />,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
  },
  {
    path: "/bills/collections/success",
    isAuthenticated: true,
    element: <CollectionForm />,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
  },
  {
    path: "/bills/collections/payment",
    isAuthenticated: true,
    element: <CollectionPayment />,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
  },
];

const unProtectedRoutes: any = [];

export const paymentsRoutes = isProtected ? routes : unProtectedRoutes;
