import OtpApprove from "pages/Payments/modals/OtpApprove";
import OtpReject from "pages/Payments/modals/OtpReject";
import InitiatorApprove from "pages/Payments/modals/InitiatorApprove";
import InitiatorReject from "pages/Payments/modals/InitiatorReject";
import { Storage } from "utils/inAppstorage";
import ApproverReject from "pages/Payments/modals/ApproverReject";

const isProtected = Storage.getItem("afb-app-token");

export const routes = [
  {
    path: "/payments/initiator/transaction/approved",
    isAuthenticated: true,
    element: <InitiatorApprove />,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
  },
  {
    path: "/payments/initiator/transaction/reject",
    isAuthenticated: true,
    element: <InitiatorReject />,
    allowedRoles: ["soleproprietor", "initiator", "initiatorapprover"],
  },
  {
    path: "/payments/verify/transaction/approved",
    isAuthenticated: true,
    element: <OtpApprove />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "verifier",
      "approver",
      "superapprover",
    ],
  },
  {
    path: "/payments/verify/transaction/reject",
    isAuthenticated: true,
    element: <OtpReject />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "verifier",
      "approver",
      "superapprover",
    ],
  },
  {
    path: "/payments/verify/approver/reject",
    isAuthenticated: true,
    element: <ApproverReject />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "verifier",
      "approver",
      "superapprover",
    ],
  },
];

const unProtectedRoutes: any = [];

export const modalsRoutes = isProtected ? routes : unProtectedRoutes;
