export enum NotificationTypeEnum {
  Transaction = "1",
  UserCreation = "2",
  UserModification = "3",
  SoftTokenRequest = "4",
  StampedStatement = "5",
  ChequeConfirmation = "6",
}

export enum NotificationStatusEnum {
  Approved = "1",
  Rejected = "2",
  Successful = "3",
  Failed = "4",
  Pending = "5",
  Verified = "6",
  PendingApproval = "7",
  PendingVerification = "8",
}
