import React, { useState } from "react";
import SuccessScreen from "components/Cards/CardActivation/SuccessScreen";
import CardSelection from "components/Cards/CardSelection";
import ChangeCardPinScreen from "components/Cards/ChangeCardPinScreen";

const ChangeCardPinTab = () => {
  const [screen, setScreen] = useState(0);

  return (
    <div className="w-full">
      {screen === 0 && (
        <CardSelection
          setScreen={setScreen}
          isActiveCards
          type={"change pin"}
        />
      )}
      {screen === 1 && <ChangeCardPinScreen setScreen={setScreen} />}
      {screen === 2 && (
        <SuccessScreen pageName={"Card Change PIN"} setScreen={setScreen} />
      )}
    </div>
  );
};

export default ChangeCardPinTab;
