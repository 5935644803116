import React from "react";
import { ReactComponent as PendingIcon } from "../../../../assets/svgs/notification-icon.svg";
import { PrimaryButton } from "alat-components-library";

const OptionModal = ({
  handleProceed,
}: {
  handleProceed: (isNewCard: boolean) => void;
}) => {
  return (
    <div
      className="fixed top-0 left-0 flex justify-center items-center w-full h-full bg-black/80 p-5 z-[2000] overflow-hidden backdrop-filter backdrop-blur-sm"
      id="alert_modal"
    >
      <center className="flex justify-center items-center w-full">
        <div className=" h-auto max-w-[568px] w-full mx-auto bg-white fixed border z-1000 rounded-md py-8">
          <div className="max-w-[276px] w-full mx-auto justify-center items-center w-full ">
            <div className="mx-auto w-[53px] h-[53px]">
              <PendingIcon />
            </div>
            <h2 className="text-base font-semibold pt-3 pb-2">
              Utility Card Request{" "}
            </h2>
            <p className="text-sm text-center">
              It appears you already have a hotlisted card associated with this
              account. Would you like to continue using this account or create a
              new one to proceed?
            </p>

            <PrimaryButton
              labelText="Proceed with hotlisted card"
              id="proceed_btn"
              handleClick={() => handleProceed(false)}
              variant="w-full py-[13px] text-sm font-semibold rounded-md bg-lib-alat-red hover:bg-lib-alat-red-hover text-white flex flex-row justify-center item-center"
              containerVariant="relative w-full mt-8 cursor-pointer rounded-md"
            />
            <PrimaryButton
              labelText="Create a new card"
              id="new_btn"
              handleClick={() => handleProceed(true)}
              variant="w-full py-[13px] text-sm font-semibold rounded-md text-lib-alat-red  flex flex-row justify-center item-center"
              containerVariant="relative w-full mt-1 cursor-pointer rounded-md"
            />
          </div>
        </div>
      </center>
    </div>
  );
};

export default OptionModal;
