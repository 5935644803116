import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { PrimaryButton } from "alat-components-library";
import { useLocation, useNavigate } from "react-router-dom";
import { Card } from "flowbite-react";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import PendingIcon from "../../../assets/gifs/refresh-modal.gif";
import { useTransactionStatus } from "hooks/apis/Payment/AccountStatement/useTransactionStatus";
import { useEffect } from "react";
import { ReactComponent as ErrorIcon } from "../../../assets/icons/error-two-icon.svg";

const FailedTransaction = ({
  goToDashboard,
  handleBack,
  resMessage,
}: {
  goToDashboard: () => void;
  handleBack: () => void;
  resMessage?: string;
}) => (
  <div className="max-w-[405px] mx-auto">
    <div className="text-center flex justify-center mt-10" id="failed_icon">
      <ErrorIcon />
    </div>

    <div className="text-center text-xl font-semibold mt-6" id="failed_text">
      Transaction Failed
    </div>
    <h3
      className="text-center mb-5 text-sm font-normal text-secondary mt-5"
      id="failed-generated_text"
    >
      {resMessage || "Transaction Failed"}
    </h3>

    <div className="flex w-full justify-center mt-10 space-y-3">
      <PrimaryButton
        id="retry_btn"
        labelText={
          <div className="flex w-full items-center">
            <div>Retry</div>
          </div>
        }
        variant="font-medium text-xs text-white"
        containerVariant="w-full h-[48px] rounded-md flex justify-center cursor-pointer bg-lib-alat-red shadow-md"
        handleClick={handleBack}
      />
    </div>

    <div className="flex w-full items-center justify-center">
      <PrimaryButton
        id="proceed-to-dashboard_button"
        labelText="Return to dashboard"
        variant="font-medium text-xs text-lib-alat-red"
        containerVariant="w-[409px] h-[48px] rounded-md flex justify-center cursor-pointer bg-white"
        handleClick={goToDashboard}
      />
    </div>
  </div>
);
const AccountStatementPending = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Get correlationId from url
  const correlationId = location.state?.correlationId;

  const { statusData, statusLoading, statementData, handleStatementStatus } =
    useTransactionStatus();

  useEffect(() => {
    if (!statusLoading && statusData.status === 2) {
      navigate("/payments/account-statement/success");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusData, statusLoading]);

  return (
    <DashboardWrapper>
      <div className="px-4 lg:px-20 w-full flex items-center justify-center">
        <Card className="md:w-[768px] mt-5 w-full bg-white pb-14  dark:bg-white dark:border-[#EDEDED] border-[#EDEDED] dark:shadow-white">
          <div className="flex items-center justify-between">
            <ChevronLeftIcon
              id="back_btn"
              className="h-6 w-6 text-[#333333] cursor-pointer"
              onClick={() => navigate("/payments/account-statement")}
            />
            <div
              className="text-[#4D4D4D] text-xl font-bold text-center"
              id="details_text"
            >
              Details
            </div>
            <div></div>
          </div>
          <hr />
          {/* If there's failed transaction  */}
          {!statusLoading && statusData.status === 3 ? (
            <FailedTransaction
              resMessage={statementData}
              handleBack={() =>
                navigate("/payments/account-statement/payment-summary")
              }
              goToDashboard={() => navigate("/user-dashboard")}
            />
          ) : (
            <div className="sm:max-w-[405px] w-full mx-auto">
              <div
                className="text-center flex justify-center mt-10"
                id="pending_icon"
              >
                <img src={PendingIcon} alt="" />
              </div>
              <div
                className="text-center text-xl font-semibold mt-6"
                id="pending_text"
              >
                Thank you!
              </div>
              <h3
                className="text-center mb-5 text-sm font-normal text-secondary mt-5"
                id="pending-generated_text"
              >
                Request received! Your account statement will be sent to your
                email shortly
              </h3>
              <div className="text-center text-sm text-lib-alat-gray-light bg-[#f5f5f5] my-3 py-3 rounded-lg">
                If you are not redirected in 30 seconds, use the refresh button
                below to manually check your transaction status
              </div>
              <div className="flex w-full justify-center mt-10 space-y-3">
                <PrimaryButton
                  id="download-statement_btn"
                  labelText={
                    <div className="flex w-full items-center">
                      <div>Refresh</div>
                    </div>
                  }
                  isLoading={statusLoading}
                  variant="font-medium text-xs text-white"
                  containerVariant="w-full h-[48px] rounded-md flex justify-center cursor-pointer bg-lib-alat-red shadow-md"
                  handleClick={() => handleStatementStatus(correlationId)}
                />
              </div>

              <div className="flex w-full items-center justify-center">
                <PrimaryButton
                  id="proceed-to-dashboard_btn"
                  labelText={"Return to dashboard"}
                  variant="font-medium text-xs text-lib-alat-red"
                  containerVariant="w-[409px] h-[48px] rounded-md flex justify-center cursor-pointer bg-white"
                  handleClick={() => navigate("/user-dashboard")}
                />
              </div>
            </div>
          )}
        </Card>
      </div>
    </DashboardWrapper>
  );
};

export default AccountStatementPending;
