import CardActivation from "pages/Cards/CardActivation";
import { CardControl } from "pages/Cards/CardControl";
import CardHotList from "pages/Cards/CardHotList";
import CardRequest from "pages/Cards/CardRequest";
import ChangeCardPin from "pages/Cards/ChangeCardPin";
import { Storage } from "utils/inAppstorage";

const isProtected = Storage.getItem("afb-app-token");

export const routes = [
  {
    path: "/card/card-request",
    isAuthenticated: true,
    element: <CardRequest />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/card/card-request/utility",
    isAuthenticated: true,
    element: <CardRequest />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/card/card-activation",
    isAuthenticated: true,
    element: <CardActivation />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/card/card-activation/utility",
    isAuthenticated: true,
    element: <CardActivation />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/card/card-pin",
    isAuthenticated: true,
    element: <ChangeCardPin />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/card/card-pin/utility",
    isAuthenticated: true,
    element: <ChangeCardPin />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/card/card-control",
    isAuthenticated: true,
    element: <CardControl />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/card/hotlist-and-card-deactivation",
    isAuthenticated: true,
    element: <CardHotList />,
    allowedRoles: ["soleproprietor"],
  },
  {
    path: "/card/hotlist-and-card-deactivation/utility",
    isAuthenticated: true,
    element: <CardHotList />,
    allowedRoles: ["soleproprietor"],
  },
];

const unProtectedRoutes: any = [];

export const cardsRoutes = isProtected ? routes : unProtectedRoutes;
