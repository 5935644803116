import html2pdf from "html2pdf.js";
import { useRef } from "react";
import { ReactComponent as AfbLogo } from "assets/svgs/transactions/afb-receipt-logo.svg";
import { useNotificationState } from "react-wrappers/stores/redux/notifications/slices";
import { useNavigate } from "react-router-dom";
import BackArrowIcon from "assets/svgs/BackArrowIcon.svg";

export const ViewTransactionDetails = () => {
  const contentRef = useRef(null);
  const navigate = useNavigate();
  const { notificationsDetails } = useNotificationState();

  const handleDownloadPDF = async () => {
    const contentElement = contentRef.current;

    const pdfBlob = await html2pdf()
      .set({
        margin: [0, 0, 0, 0],
        filename: "mydocument.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        pagebreak: { mode: ["avoid-all"] },
        elementHandler: function (element: any, renderer: any) {
          if (element === contentElement) {
            return true;
          }
          return false;
        },
      })
      .from(contentElement)
      .outputPdf("blob");

    const pdfUrl = URL.createObjectURL(pdfBlob);

    const downloadLink = document.createElement("a");
    downloadLink.href = pdfUrl;
    downloadLink.download = `TransactionReceipt-${notificationsDetails?.destinationBankName}.pdf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="lg:px-20 space-y-4 mt-5">
      <div className="flex items-center">
        <div onClick={() => navigate(-1)} className="cursor-pointer">
          <img src={BackArrowIcon} alt="<" />
        </div>
        <h2 className="text-2xl font-bold">Transaction Details</h2>
      </div>
      <div className="mx-auto bg-white md:pb-20 pb-10 md:px-10 px-2 pt-5">
        <div className="md:w-7/12 mx-auto">
          <div ref={contentRef}>
            <div className="px-10 py-5 w-full flex justify-between items-center">
              <AfbLogo className="w-16 h-16" />
              <div className="text-[#A90836] text-2xl font-semibold">
                Transaction Receipt
              </div>
              <div className="w-20"></div>
            </div>

            <div className="flex gap-10 p-3 px-10 rounded   bg-[#F7F7F7]">
              <p className="text-lib-alat-dark-red">Transaction Date:</p>
              <p>{notificationsDetails?.date}</p>
            </div>

            <div className="flex gap-10 mt-5 p-3 px-10 rounded">
              <p className="text-lib-alat-dark-red font-bold">
                Transaction Details:
              </p>
            </div>
            <hr />

            <div className="flex gap-10 p-3 px-10 rounded">
              <table className="w-[500px]">
                <tbody className="text-sm">
                  <tr>
                    <td className="text-lib-alat-dark-red">
                      Transaction Amount:
                    </td>
                    <td className=" py-2" id="groupname_text">
                      {notificationsDetails?.amount}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-lib-alat-dark-red">Amount in Words:</td>
                    <td className=" py-2" id="numberOfRecipients_text">
                      {notificationsDetails?.amountInWord}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-lib-alat-dark-red">
                      Reference Number:
                    </td>
                    <td className=" py-2" id="numberOfRecipients_text">
                      {notificationsDetails?.reference}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-lib-alat-dark-red">
                      Purpose Of Transfer:
                    </td>
                    <td className=" py-2" id="numberOfRecipients_text">
                      {notificationsDetails?.narration}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="flex gap-10 mt-5 p-3 px-10 rounded">
              <p className="text-lib-alat-dark-red font-bold">
                Beneficiary Details:
              </p>
            </div>
            <hr />

            <div className="flex gap-10 p-3 px-10 rounded">
              <table className="w-[500px]">
                <tbody className="text-sm">
                  <tr>
                    <td className="text-lib-alat-dark-red">Name:</td>
                    <td className=" py-2" id="groupname_text">
                      {notificationsDetails?.destinationAccountName}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-lib-alat-dark-red">Bank:</td>
                    <td className=" py-2" id="numberOfRecipients_text">
                      {notificationsDetails?.destinationBankName}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-lib-alat-dark-red">Account Number:</td>
                    <td className=" py-2" id="numberOfRecipients_text">
                      {notificationsDetails?.destinationAccountNumber}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="flex gap-10 mt-5 p-3 px-10 rounded">
              <p className="text-lib-alat-dark-red font-bold">
                Sender Details:
              </p>
            </div>
            <hr />

            <div className="flex gap-10 p-3 px-10 rounded">
              <table className="w-[500px]">
                <tbody className="text-sm">
                  <tr>
                    <td className="text-lib-alat-dark-red">Account Name:</td>
                    <td className=" py-2" id="groupname_text">
                      {notificationsDetails?.sourceAccountName}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-lib-alat-dark-red">Bank:</td>
                    <td className=" py-2" id="numberOfRecipients_text">
                      {notificationsDetails?.sourceBankName}
                    </td>
                  </tr>

                  {notificationsDetails?.collectionTransactionSummaryFields?.map(
                    (t: any) => (
                      <tr>
                        <td className="text-lib-alat-dark-red">
                          {t.fieldName}:
                        </td>
                        <td className=" py-2" id="numberOfRecipients_text">
                          {t?.fieldValue}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>

            <div className=" p-3 px-10 rounded   bg-[#F7F7F7]">
              <p className="font-bold text-sm">Disclaimer:</p>
              <p className="text-xs">
                Disclaimer Your transfer has been successful and the
                beneficiary's account will be credited. However, this does not
                serve as confirmation of credit into the beneficiary’s account.
                Due to the nature of the Internet, transactions may be subject
                to interruption, transmission blackout, delayed transmission and
                incorrect data transmission. The Bank is not liable for
                malfunctions in communications facilities not within its control
                that may affect the accuracy or timeliness of messages and
                transactions you send. All transactions are subject to
                verification and our normal fraud checks
              </p>

              {notificationsDetails?.disclaimerMessage && (
                <div className="mt-5">
                  <p className="font-bold text-sm">Disclaimer (Dangote):</p>
                  <p className="text-xs">
                    {notificationsDetails?.disclaimerMessage}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="w-72 flex gap-5 mt-10 mx-10">
            <div
              id="approve_btn"
              className="w-full h-10 text-white bg-lib-alat-dark-red rounded-md flex justify-center items-center cursor-pointer"
              onClick={handleDownloadPDF}
            >
              Download Receipt
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
