import React, { useEffect, useState } from "react";
import { CardActivationPayload, IUtilityCard } from "types/global";
import UtilityCards from "../UtilityCards";
import Success from "../Success";
import ChangePinForm from "./ChangePinForm";
import { useGetUnMappedUtilityCards } from "hooks/apis/Cards/utilityCards/useGetUnMappedUtilityCards";

export interface CardActivationPayload2 extends CardActivationPayload {
  card?: IUtilityCard;
}
const UtilityCardsChangePinForm = () => {
  const [successMsg, setSuccessMsg] = useState("");
  const [step, setStep] = useState(0);
  const [cardPayload, setCardPayload] = useState<CardActivationPayload2 | null>(
    null
  );
  const { handleGetUnMappedUtilityCards, unMappedWallets } =
    useGetUnMappedUtilityCards();

  // Fetch unmapped wallet accounts
  useEffect(() => {
    handleGetUnMappedUtilityCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = (next: number) => setStep(next);
  const subTitle = (
    <h5 className="text-sm text-secondary text-center">Please select card</h5>
  );
  const handleView = () => {
    switch (step) {
      case 0:
        return (
          <UtilityCards
            type="reset pin"
            isButton={true}
            subTitle={subTitle}
            unMappedWallets={unMappedWallets}
            buttonText="Set Preference"
            title="Set Preference"
            cardPayload={cardPayload}
            handleNext={(val: number, card?: IUtilityCard) => {
              setCardPayload({ ...cardPayload, card });
              handleNext(val);
            }}
          />
        );

      case 1:
        return (
          <ChangePinForm
            cardPayload={cardPayload}
            onNext={(msg) => {
              setSuccessMsg(msg || "");
              handleNext(2);
            }}
            handlePrev={() => handleNext(0)}
          />
        );

      case 2:
        return (
          <Success
            title="Details"
            message={successMsg || "Card PIN Changed Successful"}
          />
        );
      default:
        return (
          <UtilityCards
            subTitle={subTitle}
            isButton={true}
            unMappedWallets={unMappedWallets}
            type="reset pin"
            buttonText="Set Preference"
            title="Utility Cards"
            cardPayload={cardPayload}
            handleNext={(val: number, card?: IUtilityCard) => {
              setCardPayload({ ...cardPayload, card });
              handleNext(val);
            }}
          />
        );
    }
  };
  const stepView = handleView();
  return <div>{stepView}</div>;
};

export default UtilityCardsChangePinForm;
