import React from "react";
import NewTransferComponent1 from "../Reusables/NewTransferComponent1";
import NewTransferComponent2 from "../Reusables/NewTransferComponent2";
import NewTransferComponent3 from "../Reusables/NewTransferComponent3";
import OTPVerification from "../Reusables/OTPVerification";
import ResponseStatus from "components/Payments/ResponseStatus";
import SuccessScreen from "../Reusables/SuccessScreen";
import ErrorScreen from "components/Payments/BulkTransfer.tsx/general/ErrorScreen";
import { handleAccountValidation } from "./utils";
import useNewTransfer from "controllers/NewTransfer/useNewTransferController";
import PageLoader from "components/Loader/PageLoader";

const NewTransfer = () => {
  const {
    isLoading,
    paymentState,
    showScreen,
    selectedBank,
    bankLists,
    bankListLoading,
    accountNumber,
    accountValidated,
    accountValidationError,
    beneficiaryName,
    handleChange,
    handleBackToFundsTransfer,
    setisLoading,
    dispatch,
    setAccountValidated,
    setAccountValidationError,
    setBeneficiaryName,
    handleScreenChangeNext,
    accountToDebit,
    allAccounts,
    amount,
    description,
    setAccountToDebit,
    handleScreenChangePrev,
    showPinError,
    pinError,
    nipCharges,
    pin,
    setPin,
    isPinValidated,
    authToken,
    setAuthToken,
    handleSubmit,
    allowSubmit,
    handleValidate,
    // responseCode,
    correlationId,
    otp,
    otpMessage,
    setOtp,
    handleOtpSubmit,
    handleOtpValidationSubmit,
    handleRefreshTrasaction,
    handleGenerateReceipt,
    handleTryAgain,
  } = useNewTransfer();

  return (
    <div className="">
      {isLoading || bankListLoading ? <PageLoader message="" /> : null}
      {!!paymentState.loading.includes("PUT_COMPLETE_TRANSFER_TRANSACTION") && (
        <PageLoader message="Confirming request..." />
      )}
      {showScreen === 1 && (
        <NewTransferComponent1
          selectedBank={selectedBank}
          bankList={bankLists}
          accountNumber={accountNumber}
          accountValidated={accountValidated}
          accountValidationError={accountValidationError}
          beneficiaryName={beneficiaryName}
          handleChange={handleChange}
          handleBackToFundsTransfer={handleBackToFundsTransfer}
          handleAccountValidation={() => {
            handleAccountValidation({
              accountNumber,
              selectedBank,
              setLoading: setisLoading,
              dispatch,
              accountValidated: setAccountValidated,
              accountValidationError: setAccountValidationError,
              beneficiaryName: setBeneficiaryName,
            });
          }}
          handleScreenChangeNext={handleScreenChangeNext}
        />
      )}
      {showScreen === 2 && (
        <NewTransferComponent2
          accountToDebit={accountToDebit}
          selectedBank={selectedBank}
          beneficiaryName={beneficiaryName}
          accountNumber={accountNumber}
          accounts={allAccounts}
          amount={amount}
          description={description}
          handleChange={handleChange}
          setAccountToDebit={setAccountToDebit}
          handleScreenChangePrev={handleScreenChangePrev}
          handleScreenChangeNext={handleScreenChangeNext}
        />
      )}
      {showScreen === 3 && (
        <NewTransferComponent3
          showPinError={showPinError}
          pinError={pinError}
          accountToDebit={accountToDebit}
          beneficiaryName={beneficiaryName}
          accountNumber={accountNumber}
          amount={amount}
          nipCharges={nipCharges}
          selectedBank={selectedBank}
          pin={pin}
          setPin={setPin}
          isPinValidated={isPinValidated}
          authToken={authToken}
          setAuthToken={setAuthToken}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          allowSubmit={allowSubmit}
          handleScreenChangePrev={handleScreenChangePrev}
          handleScreenChangeNext={handleScreenChangeNext}
          handleValidate={handleValidate}
          // responseCode={responseCode}
        />
      )}
      {showScreen === 4 && (
        <OTPVerification
          // accountToDebit={accountToDebit}
          // beneficiaryName={beneficiaryName}
          // accountNumber={accountNumber}
          // amount={amount}
          // selectedBank={selectedBank}
          otpMsg={otpMessage}
          correlationId={correlationId}
          otp={otp}
          setOtp={setOtp}
          handleChange={handleChange}
          isLoading={isLoading}
          handleScreenChangePrev={handleScreenChangePrev}
          // handleScreenChangeNext={handleScreenChangeNext}
          handleSubmit={handleOtpSubmit}
          handleOtpValidationSubmit={handleOtpValidationSubmit}
        />
      )}
      {showScreen > 4 && (
        <div className="">
          <ResponseStatus
            type={
              showScreen === 5
                ? "pending"
                : showScreen === 6
                  ? "successful"
                  : showScreen === 7
                    ? "failed"
                    : "nothing"
            }
            onRefresh={handleRefreshTrasaction}
            onGenerateReceipt={handleGenerateReceipt}
            amount={amount.withMoneyFormat}
            recipient={beneficiaryName}
            saveBeneficiary={true}
            bankCode={selectedBank.bankCode}
            bankName={selectedBank.bankName}
            accountNumber={accountNumber}
            pin={pin}
            correlationId={correlationId}
            onRetry={handleTryAgain}
          />
        </div>
      )}
      {showScreen === 9 && <SuccessScreen />}
      {showScreen === 10 && <ErrorScreen />}
      {/* {showScreen === 5 && (
        <div className="ml-56">
          <PaymentPending onRefresh={handleRefreshTrasaction} />
        </div>
      )}
      {showScreen === 6 && (
        <div className="ml-56">
          <PaymentSuccessful
            onGenerateReceipt={handleGenerateReceipt}
            amount={amount}
            recipient={beneficiaryName}
            saveBeneficiary={true}
            bankCode={selectedBank.bankCode}
            bankName={selectedBank.bankName}
            accountNumber={accountNumber}
            pin={pin}
            correlationId={correlationId}
          />
        </div>
      )}
      {showScreen === 7 && (
        <div className="ml-56">
          <PaymentFailed onRetry={handleTryAgain} />
        </div>
      )} */}
    </div>
  );
};

export default NewTransfer;
