import React from "react";
import { PrimaryButton } from "../Buttons";
import { CenterModalProps } from "./types";
import { ReactComponent as PendingIcon } from "../assets/images/modals/warning-error-icon.svg";
import { ReactComponent as CancelIcon } from "../assets/images/icons/cancel_icon.svg";

const CenterModal = ({
  icon = <PendingIcon />,
  title,
  children,
  info,
  id = "",
  idCloseModal = "",
  idButton1 = "",
  idButton2 = "",
  idTitle = "",
  idInfo = "",
  headerFaintColor = "text-black",
  btnText,
  btnText2,
  variant,
  handleClick,
  handleClose,
  handleClick2,
  showCloseIcon,
  isLoading,
  isLoading2,
  containerVariant,
  linkText,
  linkVariant,
  handleLinkAction,
  showLink,
}: CenterModalProps) => {
  return (
    <div
      className="fixed top-0 left-0 flex justify-center items-center w-full h-full bg-black/80 p-5 z-[2000] overflow-hidden backdrop-filter backdrop-blur-sm"
      id={id}
    >
      <center className="flex justify-center items-center w-full">
        <div
          className={
            containerVariant ||
            "h-auto w-96 bg-white fixed border z-1000 rounded-md py-8"
          }
        >
          <div className="flex flex-col justify-center items-center w-full px-6 space-y-4">
            {showCloseIcon && (
              <div
                id={idCloseModal}
                onClick={handleClose}
                className="cursor-pointer flex justify-end w-full"
              >
                <CancelIcon />
              </div>
            )}
            {icon}
            <h2
              className={`text-[20px] font-bold ${headerFaintColor}`}
              id={idTitle}
            >
              {title}
            </h2>
            <p
              className="text-sm text-custom-gray mt-5 text-center"
              id={idInfo}
            >
              {info}
            </p>
            {children}
            <div className="flex w-full flex-col justify-center gap-5 mt-4">
              {btnText && (
                <PrimaryButton
                  labelText={btnText}
                  id={idButton1}
                  handleClick={handleClick}
                  variant={
                    variant ||
                    "w-full h-10 md:h-12 text-lg font-medium rounded-md bg-lib-alat-red hover:bg-lib-alat-red-hover text-white flex flex-row justify-center item-center pt-2"
                  }
                  containerVariant="relative w-full mt-8 cursor-pointer rounded-md"
                  isDisabled={false}
                  isLoading={isLoading}
                />
              )}
              {btnText2 && (
                <PrimaryButton
                  labelText={btnText2}
                  id={idButton2}
                  handleClick={handleClick2}
                  variant={`w-full h-10 md:h-12 text-sm font-medium rounded-md bg-white text-lib-alat-dark-red flex flex-row justify-center item-center`}
                  containerVariant="relative w-full mt-2 cursor-pointer rounded-md text-lib-alat-dark-red hover:border-alat-lib-dark-red"
                  isDisabled={false}
                  isLoading={isLoading2}
                />
              )}
              {showLink && (
                <p
                  className={`cursor-pointer ${linkVariant}`}
                  onClick={handleLinkAction}
                >
                  {" "}
                  {linkText}{" "}
                </p>
              )}
            </div>
          </div>
        </div>
      </center>
    </div>
  );
};

export default CenterModal;
