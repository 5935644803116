import React, { useState } from "react";
import { RouterProvider } from "react-router-dom";
import router from "./routers";
import { Storage } from "../src/utils/inAppstorage";

import { POST_REFERESH_TOKEN } from "utils/apis/routes";
import { useApiRequest } from "hooks/useApiRequest";
import { ToastNotify } from "helpers/toastNotify";
import {
  setToken,
  useAuthState,
} from "react-wrappers/stores/redux/auth/slices";
import { useAppDispatch } from "hooks/useReduxHook";
import { useIdleTimer } from "react-idle-timer";
import CenteredModal from "alat-components-library/Modal/CenterModal";
import { ReactComponent as PendingIcon } from "alat-components-library/assets/images/modals/warning-error-icon.svg";
import PageLoader from "components/Loader/PageLoader";
import useNetworkStatus from "hooks/useNetworkStatus";
import { jwtDecode, JwtPayload } from "jwt-decode";

interface IDecode extends JwtPayload {
  "alat-customer-id": string;
  unique_name: string;
}

function App() {
  const auth = useAuthState();
  const [showSessionModal, setShowSessionModal] = useState<boolean>(false);
  const [refreshLoading, setRefreshLoading] = useState<boolean>(false);
  const isAuthenticated = auth?.token !== null ? true : false;
  const makeRequest = useApiRequest();
  const networkStatus = useNetworkStatus();
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    localStorage.clear();
    setShowSessionModal(false);
    ToastNotify({
      type: "error",
      message: "Your session has expired",
      position: "top-right",
    });
    window.location.replace("/logout");
  };

  // Refresh token
  const handleRefresh = async () => {
    const getUser: IDecode = jwtDecode(`${auth?.token}`);
    const payload = {
      token: auth?.token,
      username: getUser?.unique_name,
      customerId: getUser["alat-customer-id"],
    };
    setRefreshLoading(true);
    try {
      const result = await makeRequest({
        method: "post",
        route: POST_REFERESH_TOKEN,
        body: payload,
        originType: "identity",
      });

      //Store the token to store
      if (result.status === 200) {
        Storage.setItem("afb-app-token", result?.data?.data?.accessToken);
        dispatch(setToken(result?.data?.data?.accessToken));
        setShowSessionModal(false);
        reset();
        // resetTokenTimer();
      } else {
        setShowSessionModal(false);
        handleLogout();
      }
      setRefreshLoading(false);
    } catch (error) {
      setRefreshLoading(false);
    }
  };

  const onPrompt = () => {
    if (isAuthenticated) {
      setShowSessionModal(true);
    } else {
      setShowSessionModal(false);
    }
  };

  const onAction = () => {
    if (isAuthenticated) {
      if (Math.ceil(getRemainingTime() / 1000) <= 295 && !showSessionModal) {
        handleRefresh();
      }
    }
  };

  const onIdle = () => {
    if (isAuthenticated) {
      setShowSessionModal(false);
      handleLogout();
    } else {
      setShowSessionModal(false);
    }
  };

  const { isIdle, reset, getRemainingTime } = useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle,
    promptBeforeIdle: 1000 * 60 * 1,
    onPrompt,
    onAction,
  });

  return (
    <React.StrictMode>
      {!networkStatus && (
        <PageLoader message="You are not connected to the internet. Waiting for a connection" />
      )}
      {isAuthenticated && showSessionModal && (
        <CenteredModal
          icon={<PendingIcon />}
          title={"Session Inactivity"}
          info={"You're about to be logged out"}
          btnText={"Keep me signed in"}
          btnText2={"Log me out"}
          isLoading={refreshLoading}
          // showCloseIcon
          handleClick={(e) => {
            handleRefresh();
          }}
          handleClick2={() => {
            handleLogout();
          }}
          handleClose={(e: any) => setShowSessionModal(false)}
        />
      )}
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
