import React, { ReactNode, ReactElement, useState } from "react";
import checkedRadioIcon from "../../../../assets/icons/checkedRadioIcon.svg";
import defaultRadioIcon from "../../../../assets/icons/defaultRadioIcon.svg";

interface Props {
  checked: boolean;
  clickEvent: () => void;
  icon: () => ReactElement;
  heading: string | ReactNode;
  text?: string | ReactNode;
  value?: string;
  id?: string;
  topAligned?: boolean;
  children?: ReactNode;
  childOffset?: string;
  variantClassName?: string;
  alternateText?: string;
}

export const CustomRadioInput: React.FC<Props> = ({
  checked,
  clickEvent,
  icon,
  heading,
  alternateText,
  text,
  id = "",
  value,
  topAligned,
  children,
  variantClassName = "",
  childOffset,
}) => {
  const [expanded, setExpanded] = useState(false);

  const customStyles = {
    overflow: "hidden",
    transition: "max-height 0.5s ease-in-out",
    maxHeight: `${expanded && children ? "1000px" : "0"}`,
  };

  const containerStyle = checked
    ? ` w-full z-[2000] border border-lib-alat-dark-red
        ${
          !(expanded && children && checked)
            ? " bg-[#F9ECEF] h-[14vh]"
            : "bg-gray-100"
        } rounded-lg cursor-pointer hover:max-h-[600px] max-h-[20vh]`
    : `2xl:pt-4 h-[14vh] relative w-full bg-gray-100 rounded-lg cursor-pointer 
        ${!(expanded && children) ? "" : ""} flex flex-col ${customStyles}`;

  const topAlignedStyle = topAligned ? "items-start" : "items-center";

  return (
    <div className="w-full relative">
      <div
        className={`${variantClassName} ${containerStyle} ${topAlignedStyle} max-h-[25vw] overflow-hidden transition-[max-height] duration-[400ms] transition-property-height`}
        onClick={clickEvent}
        onMouseEnter={() => setExpanded(true)}
        onMouseLeave={() => setExpanded(false)}
        id={id}
      >
        <div
          className={`w-full flex justify-between border-x-0 border-t-0 ${
            topAligned ? "" : "items-center"
          }`}
        >
          <div className={`pl-[2vh] py-[2vh] flex gap-[1.5vh] ${topAligned}`}>
            <div className={`flex ${topAlignedStyle} ${!text ? "" : ""}`}>
              {icon()}
            </div>
            <div className="">
              {typeof heading === "string" ? (
                <b className="text-[2.2vh] sm:text-sm md:text-[2.1vh] font-semi-bold">
                  {heading}
                </b>
              ) : (
                heading
              )}

              <div className="text-[2vh] pt-1 sm:text-[2vh] md:text-[2vh]">
                {expanded && children ? alternateText || text : text}
              </div>
            </div>
          </div>

          <div className="pr-[2vh] py-[2vh]">
            <img
              src={checked ? checkedRadioIcon : defaultRadioIcon}
              alt=""
              className={`w-[3vh] h-[3vh] ${topAligned && "mt-2"}`}
            />
          </div>
        </div>

        <div
          className={`px-3 text-[2vh] sm:text-[2vh] md:text-[2vh] ${
            expanded && children && checked ? "block" : "hidden"
          }`}
        >
          <div className="-mt-1">
            <hr className="border-t-3 border-black" />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
