import { useAppDispatch } from "hooks/useReduxHook";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import html2pdf from "html2pdf.js";
import { useDebounce } from "use-debounce";
import * as XLSX from "xlsx";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import {
  GET_TRANSACTION_HISTORY_ACTION,
  fetchPage,
} from "react-wrappers/stores/redux/transactions/actions";
import {
  savePaginationData,
  useTransactionState,
} from "react-wrappers/stores/redux/transactions/slices";
import { TransactionOperation } from "utils/enums";

export const useTransactionHistoryController = (noDefaultFetch?: boolean) => {
  // Redux State
  const authState = useAuthState();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state: index } = useLocation();
  const transactionState = useTransactionState();

  const {
    loading,
    transactionsResponse: { filteredTransactions, allFilteredTransactions },
  } = useTransactionState();

  const user = authState?.user;
  const { pagination } = transactionState;
  const { accountNumber, from, to, page, pageSize } =
    transactionState.pagination;

  // Variable
  const accounts = user?.accounts?.map((account: any) => ({
    name: account?.accountDescription + " - " + account.accountNo,
    id: account.accountNo,
    key: account.accountNo,
  }));

  //Get's the index of the account card selected
  const accountIndex = index ?? 1 - 1;
  const userAccount = user?.accounts
    ? user?.accounts[accountIndex]?.accountNo
    : "";

  const currency = user?.accounts[accountIndex]?.currency ?? "NGN";

  const startPage =
    filteredTransactions.pageNumber * filteredTransactions?.count -
    filteredTransactions?.count +
    1;

  const pageStart = startPage < 1 ? 1 : startPage;
  const maxPageItems =
    filteredTransactions?.totalItemCount < 10
      ? filteredTransactions?.totalItemCount
      : filteredTransactions?.count * filteredTransactions?.pageNumber;

  const totalTransactionCount = filteredTransactions.totalItemCount;

  const currentPage = filteredTransactions?.pageNumber?.toString();

  const maxPage = Math.ceil(
    (filteredTransactions?.totalItemCount || 0) /
      (filteredTransactions?.count || 1)
  );

  // Local State
  const [exportOptions, setExportOptions] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [transactionType, setTransactionType] = useState<number>(1);
  const [pageValue, setPageValue] = useState<number>(1);

  // Ref
  // Ref of the element that wowuld be downloaded
  const contentRef = useRef(null);

  // Effect triggers
  useEffect(() => {
    if (!noDefaultFetch) {
      dispatch(
        savePaginationData({
          ...pagination,
          from: "",
          to: "",
          accountNumber: userAccount || accountNumber || "",
          currencyCode: currency,
        })
      );
      dispatch(
        GET_TRANSACTION_HISTORY_ACTION({
          ...pagination,
          from: "",
          to: "",
          accountNumber: userAccount || accountNumber || "",
          transactionOperation: TransactionOperation.All,
          currencyCode: currency,
        })
      );
    }
  }, []);

  // Assist Functions

  //Handler Functions
  // Navigates to the receipt page and downloads the transaction receipt
  const handleReceiptDownload = (transaction: any) => {
    navigate("/payments/transaction-receipt", {
      state: {
        payload: {
          transaction,
          version: "1",
          accountNumber,
        },
      },
    });
  };

  // delay the input for 500ms
  const [debouncedPageInputChange] = useDebounce((value: any) => {
    handlePageInputChange(value);
  }, 500);

  // Uses debounce to take the input of the user and fetches the data
  const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const maxValue = Math.ceil(
      filteredTransactions?.totalItemCount / filteredTransactions?.count
    );
    const inputValue = e.target.value;
    const numericValue = parseInt(inputValue, 10);
    // console.log(
    //   "Input ans numeric values: ",
    //   inputValue,
    //   numericValue,
    //   maxValue
    // );

    if (numericValue > 0 && numericValue <= maxValue) {
      setPageValue(numericValue || 1);
      dispatch(fetchPage(numericValue || 1));
    }
  };

  // Downloads PDF using the ref
  const handleDownloadPDF = async () => {
    if (isDownloading) return;
    setIsDownloading(true);

    const contentElement = contentRef.current;

    const pdfBlob = await html2pdf()
      .set({
        margin: [0, 0, 0, 0],
        filename: "mydocument.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        pagebreak: { mode: ["avoid-all"] },
        elementHandler: function (element: any, renderer: any) {
          return true;
        },
      })
      .from(contentElement)
      .outputPdf("blob");

    const pdfUrl = URL.createObjectURL(pdfBlob);

    const downloadLink = document.createElement("a");
    downloadLink.href = pdfUrl;
    downloadLink.download = `TransactionHistory-${pagination?.accountNumber}.pdf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    setIsDownloading(false);
  };

  // Exports File as Excel
  const exportToExcel = (data: any) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = `TransactionHistory-${pagination?.accountNumber}`;
    const sheetName = "Sheet1";

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const dataFile = new Blob([excelBuffer], { type: fileType });
    const url = window.URL.createObjectURL(dataFile);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}${fileExtension}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Selects the style of the Status field based on the status
  const statusStyle = (status: string) => {
    let style;
    switch (status?.toLowerCase()) {
      case "debit":
        style = "text-[#FF0000]";
        break;
      case "credit":
        style = "text-[#219653]";
        break;
      default:
        style = "text-[#219653]";
        break;
    }
    return style;
  };

  const transactionStatus = (status: string) => {
    switch (status?.toLowerCase()) {
      case "successfull":
      case "successful":
        return "text-[#3BB54A] border border-[#3BB54A] rounded-sm";
      case "failed":
        return "text-[#A90836] border border-[#A90836] rounded-sm";
      case "pending":
        return "text-[#FF9900] border border-[#FF9900] rounded-sm";
      default:
        return;
    }
  };
  // Handle page size change
  const handlePageSizeChange = (pageSize: string) => {
    const requestValues = { ...pagination, pageSize };
    handleSubmitFilterForm(requestValues);
  };

  const handleSubmitFilterForm = (values: any) => {
    const accountNo = values?.accountNumber;
    dispatch(
      savePaginationData({
        accountNumber: accountNo,
        page: values?.page || 1,
        pageSize: values?.pageSize || pagination.pageSize,
        from: values.from,
        to: values.to,
        transactionOperation: values?.transactionOperation || transactionType,
        currencyCode: values?.currencyCode,
      })
    );
    try {
      dispatch(
        GET_TRANSACTION_HISTORY_ACTION({
          ...pagination,
          from: values.from,
          to: values.to,
          pageSize: values?.pageSize || pagination.pageSize,
          accountNumber: accountNo,
          page: values?.page || 1,
          transactionOperation: values?.transactionOperation || transactionType,
          currencyCode: values?.currencyCode,
        })
      );
    } catch (error) {
      // console.log(error);
    }
  };

  const goToNextPage = () => {
    if (filteredTransactions?.hasNextPage) {
      dispatch(fetchPage("next"));
    }
  };

  const goToPrevPage = () => {
    if (filteredTransactions.pageNumber > 1) {
      dispatch(fetchPage("prev"));
    }
  };

  return {
    pageValue,
    setPageValue,
    startPage,
    goToNextPage,
    goToPrevPage,
    accounts,
    userAccount,
    accountNumber,
    pageSize,
    debouncedPageInputChange,
    transactionType,
    setTransactionType,
    from,
    to,
    currency,
    pageStart,
    maxPageItems,
    totalTransactionCount,
    currentPage,
    maxPage,
    contentRef,
    loading,
    filteredTransactions,
    allFilteredTransactions,
    isDownloading,
    setIsDownloading,
    exportOptions,
    setExportOptions,
    transactionStatus,
    statusStyle,
    exportToExcel,
    handleDownloadPDF,
    handlePageInputChange,
    handleReceiptDownload,
    handleSubmitFilterForm,
    handlePageSizeChange,
  };
};
