export const GET_PREQUALIFIED_LOAN_AMOUNT: string = `SmeLoan/PrequalifiedLoan`;
export const GET_PREQUALIFIED_LOANS: string = `SmeLoan/PrequalifiedLoanDetails`;
export const POST_LOANS_DOCUMENTS: string = `SmeLoan/LoanDocuments`;
export const GET_SME_LOAN_OPTIONS: string = `SmeLoan/SmeLoanOptions`;
export const POST_SUBMIT_STATEMENT_OF_WORTH = "SmeLoan/StatementOfWorth";
export const POST_PROCESS_DISBURSEMENT = "SmeLoan/Disburse";
export const POST_COMPLETE_DISBURSEMENT_WITH_OTP =
  "SmeLoan/CompleteDisburseWithOtp";
export const POST_PROCESS_LIQUIDATION = "SmeLoan/Liquidate";
export const POST_COMPLETE_LIQUIDATION_WITH_OTP =
  "SmeLoan/CompleteLiquidationWithOtp";
export const GET_RESEND_LOANS_OTP = "Notification/SendOtp";
export const GET_LOAN_HISTORY = "SmeLoan/LoanHistory";
export const GET_SME_LOAN_BLACKLISTED_STATES = "SmeLoan/BlacklistedStates";
export const GET_LOAN_REPAYMENT_SCHEDULE = (loanAcct: string) =>
  `SmeLoan/RepaymentSchedule/${loanAcct}`;
