import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Card, Label } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { FormikButton, PrimaryButton } from "alat-components-library";
import CardSelectionSlide from "./elements/CardSelectionSlide";
import { useGetCustomerCards } from "hooks/apis/Cards/useGetCustomerCards";
import { useEffect, useMemo } from "react";
import { useAppDispatch } from "hooks/useReduxHook";
import { useCardState } from "react-wrappers/stores/redux/cards/slices";
import PageLoader from "components/Loader/PageLoader";
import { ToastNotify } from "helpers/toastNotify";

const CardSelection = ({ setScreen, isActiveCards = false, type }: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    loading,
    cardsForActivation,
    selectedCardForActivation,
    activeCards,
    selectedActiveCard,
  } = useCardState();
  const { handleFetchCustomerCards } = useGetCustomerCards();

  const dataToShow = useMemo(() => {
    if (type === "activate") {
      return cardsForActivation;
    }

    return activeCards;
  }, [activeCards, cardsForActivation, type]);

  useEffect(() => {
    handleFetchCustomerCards();
  }, []);

  return (
    <div className="w-full">
      {!!loading.includes("getting-customer-cards") && <PageLoader />}

      <Card className=" bg-white md:w-[48rem] w-full mx-auto dark:bg-white dark:border-transparent border-transparent">
        <div className="flex items-center justify-between">
          <ChevronLeftIcon
            onClick={() => navigate(-1)}
            id="back_btn"
            className="h-6 w-6 text-[#333333] cursor-pointer"
          />
          <div
            className="text-[#4D4D4D] text-xl text-center"
            id="banktransfer_heading"
          >
            Set Preference
          </div>
          <div></div>
        </div>
        <hr />
        <div className="flex flex-wrap justify-center gap-4">
          <div className="text-alat-grey relative w-full lg:max-w-lg mb-2 pb-5 ">
            {dataToShow?.length ? (
              <div className="w-full">
                <p className="text-sm py-4 text-center text-[#606060] pl-2">
                  Please select card
                </p>
                <CardSelectionSlide data={dataToShow} type={type} />
              </div>
            ) : (
              <p className="text-sm text-center text-[#606060] p-5">
                You do not have a card
              </p>
            )}
            {/* {cardsForActivation?.length && !isActiveCards && (
              <CardSelectionSlide data={cardsForActivation} type={type} />
            )} */}

            {/* <p className='text-xs py-4 text-[#A90836] italic'>
              Hot listing your Card would render it unusable and you would have to request a new one
            </p> */}
            <div className="relative w-full py-14 mb-2 flex items-center justify-center">
              <PrimaryButton
                labelText="Set Preference"
                variant="font-medium text-sm text-white tracking-tighter leading-[28px]"
                containerVariant=" w-full h-[48px] lg:max-w-sm rounded-md flex justify-center border border-lib-alat-red cursor-pointer bg-lib-alat-red"
                isDisabled={!dataToShow?.length}
                handleClick={() => {
                  if (
                    !Object?.keys(selectedCardForActivation)?.length &&
                    !Object?.keys(selectedActiveCard)?.length
                  ) {
                    ToastNotify({
                      type: "error",
                      message: `Please select a card to ${type}`,
                    });
                  } else {
                    setScreen(1);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CardSelection;
