import React, { lazy } from "react";
const Login = lazy(() =>
  import("../pages/Login").then(({ Login }) => ({ default: Login }))
);
const SignUp = lazy(() =>
  import("../pages/Onboarding/SignUp").then(({ SignUp }) => ({
    default: SignUp,
  }))
);
const ResetPasswordForFirstTimeUser = lazy(() =>
  import("pages/Login/ResetPasswordForFirstTimeUser").then(
    ({ ResetPasswordForFirstTimeUser }) => ({
      default: ResetPasswordForFirstTimeUser,
    })
  )
);
const ForgotPassword = lazy(() =>
  import("pages/ForgotPassword/ForgotPassword").then(({ ForgotPassword }) => ({
    default: ForgotPassword,
  }))
);
const NewPassword = lazy(() =>
  import("pages/ForgotPassword/NewPassword").then(({ NewPassword }) => ({
    default: NewPassword,
  }))
);
const AdminNewPassword = lazy(() =>
  import("pages/ForgotPassword/Admin/AdminNewPassword").then(
    ({ AdminNewPassword }) => ({ default: AdminNewPassword })
  )
);
const EnterNewPassword = lazy(() =>
  import("pages/ForgotPassword/EnterNewPassword").then(
    ({ EnterNewPassword }) => ({ default: EnterNewPassword })
  )
);
const EnterUsername = lazy(() =>
  import("pages/ForgotPassword/EnterUsername").then(({ EnterUsername }) => ({
    default: EnterUsername,
  }))
);
const ForgotPasswordDaon = lazy(() =>
  import("pages/ForgotPassword/ForgotPasswordDaon").then(
    ({ ForgotPasswordDaon }) => ({ default: ForgotPasswordDaon })
  )
);
const ForgotPasswordVerifyIdentity = lazy(() =>
  import("pages/ForgotPassword/ForgotPasswordVerifyIdentity ").then(
    ({ ForgotPasswordVerifyIdentity }) => ({
      default: ForgotPasswordVerifyIdentity,
    })
  )
);
const Logout = lazy(() =>
  import("pages/Login/Logout").then(({ Logout }) => ({ default: Logout }))
);
const ResetSecurityQuestions = lazy(() =>
  import("pages/ForgotPassword/ResetSecurityQuestions").then(
    ({ ResetSecurityQuestions }) => ({ default: ResetSecurityQuestions })
  )
);
const ResetSecurityQuestionsUsername = lazy(() =>
  import(
    "pages/RetrieveUsername/components/ResetSecurityQuestionsUsername"
  ).then(({ ResetSecurityQuestionsUsername }) => ({
    default: ResetSecurityQuestionsUsername,
  }))
);
const ResetSecurityQuestionsUsernameDimec = lazy(() =>
  import("pages/RetrieveUsername/ResetSecurityQuestionsUsernameDimec").then(
    ({ ResetSecurityQuestionsUsernameDimec }) => ({
      default: ResetSecurityQuestionsUsernameDimec,
    })
  )
);
const ResetSecurityQuestionsUsernameDaon = lazy(() =>
  import("pages/RetrieveUsername/ResetSecurityQuestionsDaon").then(
    ({ ResetSecurityQuestionsDaon }) => ({
      default: ResetSecurityQuestionsDaon,
    })
  )
);
const ResetSecurityQuestionsDimec = lazy(() =>
  import("pages/ForgotPassword/ResetSecurityQuestionsDimec").then(
    ({ ResetSecurityQuestionsDimec }) => ({
      default: ResetSecurityQuestionsDimec,
    })
  )
);
const ResetSecurityQuestionsVerifyIdentity = lazy(() =>
  import("pages/ForgotPassword/ResetSecurityQuestionsVerifyIdentity").then(
    ({ ResetSecurityQuestionsVerifyIdentity }) => ({
      default: ResetSecurityQuestionsVerifyIdentity,
    })
  )
);
const ResetSecurityQuestionsUsernameVerifyId = lazy(() =>
  import("pages/RetrieveUsername/ResetSecurityQuestionsVerifyIdentity").then(
    ({ ResetSecurityQuestionsVerifyIdentity }) => ({
      default: ResetSecurityQuestionsVerifyIdentity,
    })
  )
);

const ResetSecurityQuestionsDaon = lazy(() =>
  import("pages/ForgotPassword/ResetSecurityQuestionsDaon").then(
    ({ ResetSecurityQuestionsDaon }) => ({
      default: ResetSecurityQuestionsDaon,
    })
  )
);
const EnterAccountNumber = lazy(() =>
  import("pages/RetrieveUsername/EnterAccountNumber").then(
    ({ EnterAccountNumber }) => ({
      default: EnterAccountNumber,
    })
  )
);
const RetrieveUsernameIntro = lazy(() =>
  import("pages/RetrieveUsername/RetrieveUsernameIntro").then(
    ({ RetrieveUsernameIntro }) => ({
      default: RetrieveUsernameIntro,
    })
  )
);

export const AUTH_URL = {
  RETRIEVE_USERNAME_ENTER_ACCOUNT_NUMBER: "/retrieve-username/account-number",
  RETRIEVE_USERNAME_INTRO: "/retrieve-username/:tabPath",
  RESET_SECURITY_QUESTIONS: "/forgot-password/reset-security-questions",
  RESET_SECURITY_QUESTIONS_USERNAME:
    "/forgot-username/reset-security-questions",
  RESET_SECURITY_QUESTIONS_DIMEC: "/forgot-password/new-security-questions",
  RESET_SECURITY_QUESTIONS_USERNAME_DIMEC:
    "/forgot-username/new-security-questions",
  RESET_SECURITY_QUESTIONS_SELFIE: "/reset-security-questions/take-selfie",
  RESET_SECURITY_QUESTIONS_USERNAME_SELFIE:
    "/retrieve-username/reset-security-questions/take-selfie",
  RESET_SECURITY_QUESTIONS_VERIFY_ID:
    "/reset-security-questions/verify-identity",
  RESET_SECURITY_QUESTIONS_USERNAME_VERIFY_ID:
    "/retrieve-username/reset-security-questions/verify-identity",
};
export const authRoutes = [
  {
    path: "/signin",
    isAuthenticated: false,
    element: <Login />,
  },
  {
    path: "/logout",
    isAuthenticated: false,
    element: <Logout />,
  },
  {
    path: "/first-time/reset-password",
    isAuthenticated: false,
    element: <ResetPasswordForFirstTimeUser />,
  },
  {
    path: "/signup",
    isAuthenticated: false,
    element: <SignUp />,
  },
  {
    path: "/forgot-password",
    isAuthenticated: false,
    element: <ForgotPassword />,
  },
  {
    path: "/forgot-password/take-selfie",
    element: <ForgotPasswordDaon />,
  },
  {
    path: "/forgot-password/verify-identity",
    element: <ForgotPasswordVerifyIdentity />,
  },

  {
    path: "/forgot-password/enter-new-password",
    element: <EnterNewPassword />,
  },
  {
    path: "/forgot-password/enter-username",
    isAuthenticated: false,
    element: <EnterUsername />,
  },

  {
    path: "/forgot-password/new-password",
    isAuthenticated: false,
    element: <NewPassword />,
  },
  {
    path: AUTH_URL.RETRIEVE_USERNAME_ENTER_ACCOUNT_NUMBER,
    isAuthenticated: false,
    element: <EnterAccountNumber />,
  },
  {
    path: AUTH_URL.RETRIEVE_USERNAME_INTRO,
    isAuthenticated: false,
    element: <RetrieveUsernameIntro />,
  },
  {
    path: AUTH_URL.RESET_SECURITY_QUESTIONS,
    isAuthenticated: false,
    element: <ResetSecurityQuestions />,
  },
  {
    path: AUTH_URL.RESET_SECURITY_QUESTIONS_USERNAME,
    isAuthenticated: false,
    element: <ResetSecurityQuestionsUsernameDimec />,
  },
  {
    path: AUTH_URL.RESET_SECURITY_QUESTIONS_USERNAME,
    isAuthenticated: false,
    element: <ResetSecurityQuestionsUsername />,
  },
  {
    path: AUTH_URL.RESET_SECURITY_QUESTIONS_USERNAME_DIMEC,
    isAuthenticated: false,
    element: <ResetSecurityQuestionsUsernameDimec />,
  },
  {
    path: AUTH_URL.RESET_SECURITY_QUESTIONS_DIMEC,
    isAuthenticated: false,
    element: <ResetSecurityQuestionsDimec />,
  },
  {
    path: AUTH_URL.RESET_SECURITY_QUESTIONS_VERIFY_ID,
    isAuthenticated: false,
    element: <ResetSecurityQuestionsVerifyIdentity />,
  },
  {
    path: AUTH_URL.RESET_SECURITY_QUESTIONS_USERNAME_VERIFY_ID,
    isAuthenticated: false,
    element: <ResetSecurityQuestionsUsernameVerifyId />,
  },
  {
    path: AUTH_URL.RESET_SECURITY_QUESTIONS_SELFIE,
    isAuthenticated: false,
    element: <ResetSecurityQuestionsDaon />,
  },
  {
    path: AUTH_URL.RESET_SECURITY_QUESTIONS_USERNAME_SELFIE,
    isAuthenticated: false,
    element: <ResetSecurityQuestionsUsernameDaon />,
  },
  {
    path: "/forgot-password/admin/new-password",
    isAuthenticated: false,
    element: <AdminNewPassword />,
  },
];
