import { ToastNotify } from "helpers/toastNotify";
import { useLoginController } from "../Login/LoginController";
import { useNIPCharges } from "hooks/apis/Payment/useNIPCharges";
import { useAppSelector } from "hooks/useReduxHook";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GET_BENEFICIARY_LIST_ACTION,
  GET_TRANSACTION_STATUS_ACTION,
  POST_TRANSACTION_VALIDATE_ACTION,
  PUT_COMPLETE_TRANSFER_TRANSACTION_ACTION,
} from "react-wrappers/stores/redux/payments/actions";
import {
  TransactionResponseStatusEnum,
  TransactionTypeEnum,
} from "utils/enums";
import {
  generateChargeTwo,
  getTransactionType,
  moneyInputFormat,
} from "utils/helperFunctions";
import {
  setRegularTransferBeneficiaries,
  setSelectedTransferBeneficiary,
  setResponseCode,
  usePaymentState,
  setTransferResponseMessage,
} from "react-wrappers/stores/redux/payments/slices";
import { useAccountsState } from "react-wrappers/stores/redux/accounts/slices";

const useSavedBeneficiary = ({ tabsRef }: any) => {
  const { authState, dispatch } = useLoginController();
  const userAccounts = useAccountsState();
  const allAccounts =
    userAccounts?.allAccounts?.length > 0
      ? userAccounts?.allAccounts
      : authState?.user?.accounts;
  const paymentState = usePaymentState();

  const options = {
    headers: {
      Authorization: `Bearer ${authState?.token}`,
    },
  };
  // const location = useLocation();
  const navigate = useNavigate();
  // const { nipCharges, handleGetNIPCharges } = useNIPCharges();

  const [accountNumber, setAccountNumber] = useState("");
  const [selectedBank, setSelectedBank] = useState({
    bankCode: "",
    bankName: "",
  });
  const [amount, setAmount] = useState({
    withMoneyFormat: "0",
    withNumberFormat: 0,
  });
  // const [pin, setPin] = useState("");
  // const [enableSubmit, setEnableSubmit] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [showScreen, setshowScreen] = useState(1);

  const [beneficiaryName, setBeneficiaryName] = useState("");

  const [correlationId, setCorrelationId] = useState("");
  // const [accountToCredit, setAccountToCredit] = useState({});
  const [accountToDebit, setAccountToDebit] = useState({
    accountId: "",
    currency: "",
    balance: 0,
    accountName: "",
    accountNo: "",
  });
  const [description, setDescription] = useState("");
  const [pin, setPin] = useState("");
  const [otp, setOtp] = useState("");
  const [authToken, setAuthToken] = useState("");
  const [isPinValidated, setIsPinValidated] = useState(true);
  const [showPinError, setShowPinError] = useState("no");
  const [pinError, setPinError] = useState("");
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [otpMessage, setOtpMessage] = useState<string>("");
  // const [responseCode, setResponseCode] = useState<number>();

  const [savedBeneficiaryList, setSavedBeneficiaryList] = useState<
    {
      bankCode: string;
      bankName: string;
      accountNumber: string;
      accountName: string;
      currency: string;
    }[]
  >([]);
  // const [selectedBeneficiary, setSelectedBeneficiary] = useState<{
  //   bankCode: string;
  //   bankName: string;
  //   accountNumber: string;
  //   accountName: string;
  //   currency: string;
  // }>({
  //   bankCode: "",
  //   bankName: "",
  //   accountNumber: "",
  //   accountName: "",
  //   currency: "",
  // });

  const handlePinValidation = async (pin: any, token: string) => {
    setIsPinValidated(true);
    setAllowSubmit(true);
    setShowPinError("no");
  };

  const handleChange = (event: {
    target: { name: any; value: any; dataset: any };
  }) => {
    const getAuthType = event?.target?.dataset.authType;
    // console.log(getAuthType)
    // console.log(event.target.name, event.target.value);
    if (event.target.name === "AccountNumber") {
      const value = event.target.value.replace(/\D/g, "");
      setAccountNumber(value);
    }
    if (event.target.name === "selectedBank") {
      setSelectedBank(JSON.parse(event.target.value));
      setAccountNumber("");
      // setAccountValidated(false);
    }

    if (event.target.name === "amount") {
      const result = moneyInputFormat(event.target.value);
      result && setAmount(result);
    }
    if (event.target.name === "description") {
      setDescription(event.target.value);
    }
    if (event.target.name === "pin") {
      setShowPinError("no");
      setIsPinValidated(false);
      setAllowSubmit(false);
      const result = event.target.value.replace(/\D/g, "");

      setPin(result);
      if (result.length === 4) {
        if (result.length === 4) {
          if (getAuthType !== "hardTokenPin") {
            return handlePinValidation(result, "");
          }
          setIsPinValidated(true);
          setShowPinError("no");
          return setAllowSubmit(true);
        }
      }
    }
    if (event.target.name === "authToken") {
      const result = event.target.value.replace(/\D/g, "");
      setAllowSubmit(false);
      setAuthToken(result);
      if (result.length === 6) {
        // if(getAuthType !== "hardTokenPin" ) handlePinValidation("", result);
        // setIsPinValidated(true);
        // setShowPinError("no");
        // return setAllowSubmit(true);
        if (getAuthType !== "hardTokenPin") {
          handlePinValidation("", result);
          return;
        } else {
          setIsPinValidated(true);
          setShowPinError("no");
          return setAllowSubmit(true);
        }
      }
    }
    if (event.target.name === "otp") {
      if (event.target.value.length > 6) {
        return;
      }
      const result = event.target.value.replace(/\D/g, "");

      setOtp(result);
      // if (result.length === 4) {
      //   handlePinValidation(result);
      // }
    }
    if (event.target.name === "selectedAccount") {
      const convertedAccountDetails = JSON.parse(event.target.value);
      setAccountToDebit(convertedAccountDetails);

      // const filterAccounts = accounts.filter(
      //   (account) => account.accountNo !== convertedAccountDetails.accountNo
      // );
      // setShowAccounts(filterAccounts);
    }
    // setFundAccount({ ...fundAccount, [event.target.name]: event.target.value });
  };
  const handleBackToFundsTransfer = () => {
    navigate("/payments/funds-transfer");
  };
  const handleBackToNewTransfer = () => {
    tabsRef.current?.setActiveTab(0);
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    // prevents the submit button from refreshing the page
    event.preventDefault();

    const selectedBeneficiary =
      paymentState?.selectedTransferBeneficiary ?? selectedBank;

    let transactionType = getTransactionType(selectedBeneficiary, authState);

    let transactionFee = 0;
    if (selectedBeneficiary?.bankCode !== "035") {
      transactionFee = generateChargeTwo(amount?.withNumberFormat);
    }

    const body = {
      sourceAccountNumber: accountToDebit?.accountNo,
      sourceAccountName: accountToDebit?.accountName,
      sourceAccountCurrencyCode: accountToDebit?.currency,
      transactionType,
      transferDestinations: [
        {
          beneficiaryAccountNumber: selectedBeneficiary?.accountNumber,
          beneficiaryName: selectedBeneficiary?.accountName,
          beneficiaryBankName: selectedBeneficiary?.bankName,
          beneficiaryBankCode: selectedBeneficiary?.bankCode,
          amount: amount?.withNumberFormat,
          beneficiaryCurrency: accountToDebit?.currency,
          narration: description,
          transactionFee: transactionFee,
        },
      ],
      multipleTransferGroupName: "",
      transactionPin: pin,
      token: authToken,
      isSoftToken: authState?.user?.hasSoftToken,
      createMultipleTransferGroup: false,
    };
    // console.log("submit", body);
    // console.log("submit-selectedBank", selectedBank);

    try {
      setisLoading(true);
      dispatch(POST_TRANSACTION_VALIDATE_ACTION(body))
        .unwrap()
        .then((res: any) => {
          // dispatch(setResponseCode(res?.data?.data?.responseCode))

          // console.log("res-save-bene", res);

          setisLoading(false);
          dispatch(
            setTransferResponseMessage({
              title: res?.data?.responseMessage?.title,
              actionMessage: res?.data?.responseMessage?.actionMessage,
            })
          );
          if (res.status === 200) {
            setCorrelationId(res?.data?.data?.correlationId);
            setPin("");
            setPinError("");
            if (authState.userRole !== "initiator") {
              setshowScreen(5);
            } else {
              setshowScreen(8);
            }
          } else {
            // setshowScreen(7);
            ToastNotify({
              type: "error",
              message: `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
            });
          }
        })
        .catch((e: any) => {
          // console.log("error", e);
          setPin("");
          setPinError("");
          setisLoading(false);
          setshowScreen(7);
        });

      // setshowScreen(7);
    } catch (e) {
      setisLoading(false);
      setshowScreen(7);
    }
  };

  const handleScreenChangeNext = () => {
    setshowScreen(showScreen + 1);
  };
  const handleScreenChangePrev = () => {
    if (showScreen === 2) {
      setAmount({
        withMoneyFormat: "",
        withNumberFormat: 0,
      });
    }
    if (showScreen === 3) {
      setAccountToDebit({
        accountId: "",
        currency: "",
        balance: 0,
        accountName: "",
        accountNo: "",
      });
      setAmount({
        withMoneyFormat: "",
        withNumberFormat: 0,
      });
      setAllowSubmit(false);
    }
    setshowScreen(showScreen - 1);
  };
  const handleRefreshTrasaction = async () => {
    try {
      setisLoading(true);
      dispatch(GET_TRANSACTION_STATUS_ACTION(correlationId))
        .unwrap()
        .then((res: any) => {
          setisLoading(false);
          if (res?.status === 200) {
            if (
              res?.data?.data?.transactions?.[0]?.status ===
              TransactionResponseStatusEnum.Pending
            ) {
              ToastNotify({
                type: "error",
                message:
                  res?.data?.data === null
                    ? `${res?.data?.responseMessage?.title}. ${res?.data?.responseMessage?.actionMessage}`
                    : res?.data?.data?.transactions?.[0]?.statusMessage ?? "",
              });
            } else {
              dispatch(
                setTransferResponseMessage({
                  title: res?.data?.data?.transactions?.[0]?.statusMessage,
                  actionMessage: "",
                })
              );
              if (
                res.data.data.transactions[0].status ===
                TransactionResponseStatusEnum.Success
              ) {
                // setisLoading(false);
                setshowScreen(6);
              }
              if (
                res.data.data.transactions[0].status ===
                TransactionResponseStatusEnum.Failed
              ) {
                // setisLoading(false);
                setshowScreen(7);
              }
            }
          } else {
            ToastNotify({
              type: "error",
              message:
                res?.status >= 500
                  ? "Oops! Something went wrong"
                  : `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
            });
          }
        })
        .catch((e: any) => {
          setisLoading(false);
          setshowScreen(7);
        });
    } catch (e) {
      setisLoading(false);
      // console.log(e);
    }
  };
  const handleGenerateReceipt = () => {
    navigate("/payments/transaction-receipt", {
      state: { payload: { correlationId, version: "1" } },
    });
  };
  const handleTryAgain = () => {
    setAccountNumber("");
    setSelectedBank({
      bankCode: "",
      bankName: "",
    });
    setAmount({
      withMoneyFormat: "",
      withNumberFormat: 0,
    });
    setBeneficiaryName("");
    setAccountToDebit({
      accountId: "",
      currency: "",
      balance: 0,
      accountName: "",
      accountNo: "",
    });
    setDescription("");
    setPin("");
    setOtp("");

    setshowScreen(1);
  };
  const handleSelectedBeneficiary = (accountNumber: any) => {
    // console.log("beneficiary", beneficiaryList);
    // console.log("test", accountNumber);
    const selectedCard = (
      paymentState?.beneficiaryList ?? savedBeneficiaryList
    )?.find((acc: any) => acc?.accountNumber === accountNumber);
    // console.log("selectedCard", selectedCard)
    dispatch(setSelectedTransferBeneficiary(selectedCard));

    // setSelectedBeneficiary(selectedCard);
    // setSelectedBank({
    //   bankCode: selectedCard?.bankCode,
    //   bankName: selectedCard?.bankName,
    // });
    // setBeneficiaryName(selectedCard?.accountName);
    // setAccountNumber(selectedCard?.accountNumber);
    setshowScreen(2);
  };
  const GetBeneficiaryLists = async () => {
    const payload = {
      pageSize: 10,
      pageNumber: 1,
    };
    dispatch(GET_BENEFICIARY_LIST_ACTION(payload))
      .unwrap()
      .then((res: any) => {
        if (res?.data?.httpStatusCode === 200) {
          setSavedBeneficiaryList(res?.data?.data?.beneficiaries || []);
          dispatch(
            setRegularTransferBeneficiaries(
              res?.data?.data?.beneficiaries || []
            )
          );
        }
      })
      .catch((e: any) => {
        // console.log(e);
      });
  };

  useEffect(() => {
    GetBeneficiaryLists();
    // handleGetNIPCharges();
  }, []);

  const handleOtpValidationSubmit = async (values: any) => {
    setisLoading(true);
    // console.log(values);
    let transactionType = TransactionTypeEnum.SingleSignatoryInterTransfer; // Sole Proprietor

    if (authState?.userRole === "initiator") {
      transactionType = TransactionTypeEnum.MultipleSignatoryInterTransfer;
    }

    if (
      authState?.userRole === "initiator" &&
      selectedBank?.bankCode === "035"
    ) {
      transactionType = TransactionTypeEnum.MultipleSignatoryIntraTransfer;
    }
    const payload = {
      transactionType,
      code: otp,
      correlationId: correlationId,
    };
    // console.log(payload);
    dispatch(PUT_COMPLETE_TRANSFER_TRANSACTION_ACTION(payload))
      .unwrap()
      .then((res: any) => {
        setisLoading(false);
        dispatch(
          setTransferResponseMessage({
            title: res?.data?.responseMessage?.title,
            actionMessage: res?.data?.responseMessage?.actionMessage,
          })
        );
        // console.log("saved-ben-res", res);
        // Redirect to the pending screen
        if (
          res?.data?.httpStatusCode === 200 &&
          authState.userRole !== "initiator"
        )
          setshowScreen(5);
        if (
          res?.data?.httpStatusCode === 200 &&
          authState.userRole === "initiator"
        )
          setshowScreen(8);
        if (res?.status !== 200)
          return ToastNotify({
            type: "error",
            message:
              res?.status >= 500
                ? "Oops! Something went wrong"
                : `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}` ||
                  "Something went wrong",
          });
      })
      .catch((error: any) => {
        setisLoading(false);
        // console.log(error);
        // redirect to the error screen
        setshowScreen(7);
      });
  };

  const handleValidate = async (event: { preventDefault: () => void }) => {
    // prevents the submit button from refreshing the page
    event.preventDefault();
    const selectedBeneficiary =
      paymentState?.selectedTransferBeneficiary ?? selectedBank;

    let transactionType = getTransactionType(selectedBeneficiary, authState);

    let transactionFee = 0;
    if (selectedBeneficiary?.bankCode !== "035") {
      transactionFee = generateChargeTwo(amount?.withNumberFormat);
    }

    const body = {
      sourceAccountNumber: accountToDebit?.accountNo,
      sourceAccountName: accountToDebit?.accountName,
      sourceAccountCurrencyCode: accountToDebit?.currency,
      transactionType,
      transferDestinations: [
        {
          beneficiaryAccountNumber:
            selectedBeneficiary?.accountNumber ?? accountNumber,
          beneficiaryName: selectedBeneficiary?.accountName ?? beneficiaryName,
          beneficiaryBankName: selectedBeneficiary?.bankName,
          beneficiaryBankCode: selectedBeneficiary?.bankCode,
          amount: amount?.withNumberFormat,
          beneficiaryCurrency: accountToDebit?.currency,
          narration: description,
          transactionFee: transactionFee,
        },
      ],
      multipleTransferGroupName: "",
      createMultipleTransferGroup: false,
      transactionPin: pin,
      token: authToken,
      isSoftToken: authState?.user?.hasSoftToken,
    };

    // console.log("validate", body)

    try {
      setisLoading(true);
      dispatch(POST_TRANSACTION_VALIDATE_ACTION(body))
        .unwrap()
        .then((res: any) => {
          // console.log("saved-bene-res-validate", res);
          setisLoading(false);
          if (res?.data?.data?.responseCode === 21) {
            dispatch(setResponseCode(res?.data?.data?.responseCode));
          }
          if (res.status === 200) {
            setCorrelationId(res.data.data.correlationId);
            setOtpMessage(res?.data?.responseMessage?.actionMessage);
            dispatch(
              setTransferResponseMessage({
                title: res?.data?.responseMessage?.title,
                actionMessage: res?.data?.responseMessage?.actionMessage,
              })
            );
            // if (
            //     res?.data?.data?.correlationId &&
            //     Number(amount.withNumberFormat) < 2000000
            // ) {
            //     setshowScreen(4);
            // }
            // if (res?.data?.data?.responseCode === 21) {
            //     dispatch(setResponseCode(res?.data?.data?.responseCode));
            // }
            // setCorrelationId(res?.data?.data?.correlationId);

            // setPin("")
            setPinError("");
            if (
              res?.data?.data?.correlationId &&
              amount.withNumberFormat < 2000000
            ) {
              setshowScreen(4);
            }
            if (res?.data?.data?.responseCode === 21) {
              dispatch(setResponseCode(res?.data?.data?.responseCode));
            }
          } else {
            ToastNotify({
              type: "error",
              message:
                res?.status >= 500
                  ? "Oops! Something went wrong"
                  : `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}` ||
                    "Something went wrong",
            });
          }

          // if (res?.data?.data?.hasError) {
          //   return ToastNotify({
          //     type: "error",
          //     message: `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`
          //   })
          // }
        })
        .catch((e: any) => {
          setPin("");
          setPinError("");
          setisLoading(false);
          // setshowScreen(7);
        });

      // setshowScreen(7);
    } catch (e) {
      setisLoading(false);
    }
  };

  return {
    isLoading,
    options,
    showScreen,
    accountNumber,
    accountToDebit,
    selectedBank,
    setSelectedBank,
    // selectedBeneficiary,
    savedBeneficiaryList,
    beneficiaryName,
    allAccounts,
    amount,
    description,
    showPinError,
    pinError,
    tabsRef,
    // nipCharges,
    correlationId,
    otp,
    otpMessage,
    setOtp,
    pin,
    setPin,
    authToken,
    setAuthToken,
    isPinValidated,
    allowSubmit,
    // responseCode,
    handleSubmit, //
    handleChange,
    handleValidate, //
    handleRefreshTrasaction,
    handleGenerateReceipt,
    handleTryAgain,
    handleOtpValidationSubmit,
    handleBackToNewTransfer,
    handleScreenChangeNext,
    handleScreenChangePrev,
    handlePinValidation,
    setAccountToDebit,
    setAccountNumber,
    setBeneficiaryName,
    setSavedBeneficiaryList,
    setDescription,
    setAmount,
    setCorrelationId,
    handleBackToFundsTransfer,
    handleSelectedBeneficiary,
  };
};

export default useSavedBeneficiary;
