import { useState } from "react";
import { useApiRequest } from "../../../useApiRequest";
import { GET_UTILITY_CARDS_WALLETS } from "utils/apis/routes";
import { handleErrors } from "utils/helperFunctions";

export const useGetUnMappedUtilityCards = () => {
  const makeRequest = useApiRequest();
  const [status, setStatus] = useState<boolean>(false);
  const [unMappedWallets, setUnMappedWallets] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleGetUnMappedUtilityCards = async () => {
    setIsLoading(true);
    setStatus(false);

    try {
      const response = await makeRequest({
        method: "get",
        route: GET_UTILITY_CARDS_WALLETS,
        originType: "identity",
      });
      const accounts =
        response?.data?.data?.utilityWalletAccounts?.result || [];
      setUnMappedWallets(accounts);

      if (response?.data?.httpStatusCode === 200) {
        setStatus(true);
      } else {
        setStatus(false);
      }
    } catch (err: any) {
      if (err?.response?.status === 500) {
        handleErrors();
      } else {
        handleErrors(
          err?.response?.data?.responseMessage?.title ||
            err?.response?.data?.responseMessage?.actionMessage
        );
      }
    } finally {
      setIsLoading(false);
    }
  };
  return {
    handleGetUnMappedUtilityCards,
    status,
    unMappedWallets,
    isLoading,
  };
};
