import { PrimaryButton } from "alat-components-library";
import { ReusableCard } from "components/reusables/Page/ReusableCard";
import React from "react";
import { ReactComponent as SuccessIcon } from "../../../assets/icons/success-icon.svg";
import { useNavigate } from "react-router-dom";

const Success = ({ title, message }: { message: string; title: string }) => {
  const navigate = useNavigate();

  return (
    <ReusableCard
      cardTitle={title}
      className="mt-0"
      variant="md:w-[48rem] w-full mx-auto bg-white border border-alat-gray-input-border rounded-lg"
    >
      <div className="max-w-[409px] mx-auto">
        <div className="text-center flex justify-center my-8">
          <SuccessIcon />
        </div>
        <div className="text-center text-xl/[24px] font-semibold mt-6">
          Success!
        </div>
        <div className="pt-3 text-center text-sm rounded-lg font-normal text-gray-500 light:text-gray-400 pb-[22px]">
          {message}
        </div>
        <div className="flex flex-col gap-5 justify-center flex-row gap-4 pb-10">
          <PrimaryButton
            id="proceed-to-dashboard_btn"
            labelText="Okay"
            variant="font-medium text-xs text-white"
            containerVariant="w-[409px] h-[48px] rounded-md flex justify-center cursor-pointer bg-lib-alat-red"
            handleClick={() => {
              navigate("/user-dashboard");
            }}
          />
          {/* <PrimaryButton
            id="proceed-to-dashboard_btn"
            labelText="View wallet account"
            variant="font-medium text-xs text-lib-alat-red"
            containerVariant="flex justify-center ursor-pointer "
            handleClick={() => {
              navigate("/user-dashboard");
            }}
          /> */}
        </div>
      </div>
    </ReusableCard>
  );
};

export default Success;
