import { useState } from "react";
import { useApiRequest } from "../../../useApiRequest";
import { POST_SUBMIT_CARD_ACTIVATION } from "utils/apis/routes";
import { CardActivationPayload } from "types/global";
import { handleErrors } from "utils/helperFunctions";

export const useActivateCard = () => {
  const makeRequest = useApiRequest();
  const [status, setStatus] = useState<boolean>(false);
  const [responseData, setResponseData] = useState();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleActivateCard = async (payload: CardActivationPayload) => {
    setIsLoading(true);
    setStatus(false);

    try {
      const response = await makeRequest({
        method: "post",
        route: POST_SUBMIT_CARD_ACTIVATION,
        originType: "identity",
        body: payload,
      });

      setResponseData(response?.data?.data);
      if (response?.data?.httpStatusCode === 200) {
        setStatus(true);
      } else {
        setStatus(false);
        const hasErrors = response?.data?.data?.errors;
        const errorMsg = hasErrors
          ? Object.values(hasErrors)
          : response?.data?.data?.responseMessage?.title;
        handleErrors(errorMsg);
      }
    } catch (err: any) {
      if (err?.response?.status === 500) {
        handleErrors();
      } else {
        handleErrors(
          err?.response?.data?.responseMessage?.title ||
            err?.response?.data?.responseMessage?.actionMessage
        );
      }
    } finally {
      setIsLoading(false);
    }
  };
  return {
    handleActivateCard,
    status,
    responseData,
    isLoading,
  };
};
