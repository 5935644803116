import React, { useEffect, useState } from "react";
import RequestForm from "./RequestForm";
import SummaryForm from "./SummaryForm";
import PickupDeliveryForm from "./PickupDeliveryForm";
import Failed from "./Failed";
import { CardRequestPayload } from "types/global";
import UtilityCards from "../UtilityCards";
import Success from "../Success";
import { useLocation } from "react-router-dom";
import { useGetUnMappedUtilityCards } from "hooks/apis/Cards/utilityCards/useGetUnMappedUtilityCards";

const UtilityCardRequest = () => {
  const [successMsg, setSuccessMsg] = useState("");
  const [step, setStep] = useState(0);
  const [cardRequestPayload, setCardRequestPayload] =
    useState<CardRequestPayload | null>(null);
  const [cardOption, setCardOption] = useState({
    isNewCard: true,
  });

  const location = useLocation();
  const activeUrlStep = location?.state?.step || 0;
  const newCardState = location?.state?.isNewCard;

  const handleNext = (next: number) => setStep(next);

  const { handleGetUnMappedUtilityCards, unMappedWallets, isLoading } =
    useGetUnMappedUtilityCards();

  // Update step when it comes from the url
  useEffect(() => {
    activeUrlStep && setCardOption({ ...cardOption, isNewCard: newCardState });
    setTimeout(() => setStep(activeUrlStep));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUrlStep, newCardState]);

  // Fetch unmapped wallet accounts
  useEffect(() => {
    handleGetUnMappedUtilityCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateCardOption = (isNewCard: boolean) => {
    setCardOption({ ...cardOption, isNewCard });
    handleNext(1);
  };

  const handleView = () => {
    switch (step) {
      case 0:
        return (
          <UtilityCards
            buttonText="Request a new card"
            type="request"
            unMappedWallets={unMappedWallets}
            title="Utility Cards"
            unMappedWalletsLoading={isLoading}
            updateCardOption={updateCardOption}
            handleNext={(val: number) => handleNext(val)}
          />
        );
      case 1:
        return (
          <RequestForm
            onBack={() => handleNext(0)}
            isNewCard={cardOption.isNewCard}
            unMappedWallets={unMappedWallets}
            cardRequestPayload={cardRequestPayload}
            onNext={(values) => {
              setCardRequestPayload(values);
              handleNext(2);
            }}
          />
        );
      case 2:
        return (
          <PickupDeliveryForm
            onNext={(values) => {
              setCardRequestPayload(values);
              handleNext(3);
            }}
            cardRequestPayload={cardRequestPayload}
            handlePrev={() => handleNext(1)}
          />
        );
      case 3:
        return (
          <SummaryForm
            onNext={(val: number, msg?: string) => {
              handleNext(val);
              setSuccessMsg(msg || "");
            }}
            handlePrev={() => handleNext(2)}
            cardRequestPayload={cardRequestPayload}
          />
        );
      case 4:
        return (
          <Success
            message={
              successMsg ||
              "Your utility card request is successful and a new wallet account has been generated for your utility card"
            }
            title="Payment Summary"
          />
        );
      case 5:
        return <Failed cardRequestPayload={cardRequestPayload} />;
      default:
        return (
          <UtilityCards
            type="request"
            buttonText="Request a new card"
            title="Utility Cards"
            unMappedWalletsLoading={isLoading}
            updateCardOption={updateCardOption}
            unMappedWallets={unMappedWallets}
            handleNext={(val: number) => handleNext(val)}
          />
        );
    }
  };
  const stepView = handleView();
  return <div>{stepView}</div>;
};

export default UtilityCardRequest;
