import React from "react";
import CardIcon from "assets/svgs/card-icon-colored.svg";

const EmptyState = () => {
  return (
    <div className="text-center max-w-[385px] mx-auto">
      <div className="flex justify-center mt-24 pb-1.5">
        <img src={CardIcon} alt="Card" />
      </div>
      <h2 className="text-lib-alat-red text-sm font-semibold pb-4">
        No Card Available
      </h2>
      <p className="text-black text-base">
        You currently do not have a utility card attached to this account. Click
        on{" "}
        <span className="font-medium text-lib-alat-red">
          Request for a new Card
        </span>{" "}
        to apply for a new debit card.
      </p>
    </div>
  );
};

export default EmptyState;
