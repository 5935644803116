export const tabTheme = {
  tabs: {
    tablist: {
      base: "flex text-center gap-2 z-0",
      tabitem: {
        base: "flex text-center px-7",
        variant: {
          underline: {
            base: "rounded-t-lg",
            active: {
              on: "active py-2 rounded-t-lg border-b-2 border-lib-alat-red text-lib-alat-red font-medium",
              off: "py-2 border-b-2 border-transparent text-lib-alat-black font-medium",
            },
          },
        },
      },
    },
  },
};
