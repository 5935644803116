import { FormikButton, TextInput } from "alat-components-library";
import { ReusableCard } from "components/reusables/Page/ReusableCard";
import { Formik, Form, useFormikContext } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SelectDropdown } from "components/Dashboard/components/SelectDropdown";
import { getOption } from "utils/helperFunctions";
import { useUtilityCard } from "../hooks/useUtilityCard";
import { Label } from "flowbite-react";
import { StateDropdown } from "components/Dashboard/components/StateDropdown";
import CardBg from "assets/svgs/Cards/card-bg-utility.svg";
import PageLoader from "components/Loader/PageLoader";
import { useCardState } from "react-wrappers/stores/redux/cards/slices";
import { CardRequestPayload, UtilityCardRequest } from "types/global";
import { CustomRadioInput } from "components/reusables/Inputs/RadioInput/CustomRadioInput";
import CardIcon from "assets/svgs/components/CardIcon";

// This component sets default state for card
const DefaultCardState = ({
  cardRequestPayload,
}: {
  cardRequestPayload: CardRequestPayload | null;
}) => {
  const { setFieldValue } = useFormikContext();
  const { deleveryOptionList } = useUtilityCard();

  // Set the value card has default state
  useEffect(() => {
    if (setFieldValue && !!cardRequestPayload) {
      const defautOption = deleveryOptionList.find(
        (option) => option.id === cardRequestPayload?.deliveryOption
      );

      setFieldValue("preferredName", cardRequestPayload?.preferredName);
      setFieldValue(
        "accountNumberDebit",
        cardRequestPayload?.sourceAccountNumber
      );
      setFieldValue("deliveryOption", defautOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardRequestPayload]);

  return null;
};

const RequestForm = ({
  onNext,
  onBack,
  isNewCard,
  unMappedWallets,
  cardRequestPayload,
}: {
  isNewCard: boolean;
  unMappedWallets: string[];
  cardRequestPayload: CardRequestPayload | null;
  onNext: (data: CardRequestPayload) => void;
  onBack: () => void;
}) => {
  const [accountError, setAccountError] = useState<string>("");

  const { loading } = useCardState();

  const {
    activeAccounts,
    deleveryOptionList,
    handlePreferredNameValidation,
    setAccountToDebit,
    accountToDebit,
    preferredName,
    setPreferredName,
    handleSelectedAccount,
    charges,
    handleAccountError,
  } = useUtilityCard();

  // Set selected account object
  useEffect(() => {
    if (cardRequestPayload) {
      const selectedAccount = handleSelectedAccount(
        cardRequestPayload?.sourceAccountNumber
      );
      setAccountToDebit(selectedAccount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values: UtilityCardRequest) => {
    const hasError = handleAccountError(values, isNewCard);
    // Return if there is error
    if (hasError) {
      return;
    }
    const optionIndex = values.deliveryOption.id - 1;
    const data = {
      sourceAccountName: accountToDebit.accountName,
      sourceAccountCurrencyCode: accountToDebit.currency,
      sourceAccountNumber: accountToDebit.accountNo,
      sourceAccountNumberSchemeCode: accountToDebit.schemeCode,
      transactionType: 1,
      deliveryOption: values.deliveryOption.id,
      preferredName: values.preferredName,
      isNewRequest: isNewCard,
      totalDebitAmount:
        charges[optionIndex].cardFees +
        charges[optionIndex].deliveryFee +
        charges[optionIndex].vat,
    };

    onNext(values?.accountNumberDebit ? data : cardRequestPayload!);
  };

  //   Get existing delivery option when back is clicked
  const handleDefaultDeliveryOption = useCallback(() => {
    if (cardRequestPayload?.deliveryOption) {
      return cardRequestPayload.deliveryOption === 1
        ? "Branch Pick up"
        : "Office Delivery";
    } else {
      return "";
    }
  }, [cardRequestPayload?.deliveryOption]);

  const defaultDeliveryOption = handleDefaultDeliveryOption();
  // Get regular accounts without wallet account (wallet scheme codes 64005, 64004)
  const regularAccounts = useMemo(() => {
    return activeAccounts.filter(
      (account: { schemeCode: string }) =>
        account.schemeCode !== "64005" && account.schemeCode !== "64004"
    );
  }, [activeAccounts]);

  // UnMapped Wallet Accounts
  const unMappedAccounts = useMemo(() => {
    return activeAccounts.filter((account: { accountNo: string }) =>
      unMappedWallets.includes(account.accountNo)
    );
  }, [activeAccounts, unMappedWallets]);

  return (
    <>
      {loading.includes("checking-account-availability") && <PageLoader />}
      <ReusableCard
        showGoBackIcon
        handleBackNavigation={onBack}
        cardTitle="Utility cards"
        className="mt-0 border-b border-alat-gray-input-border"
        variant="md:w-[48rem] w-full mx-auto bg-white border border-alat-gray-input-border rounded-lg"
      >
        <div className="text-center max-w-[420px] mx-auto">
          <Formik
            initialValues={{
              deliveryOption: {
                id: 0,
                label: "",
                name: "",
              },
              accountNumberDebit: "",
              preferredName: "",
            }}
            onSubmit={handleSubmit}
            validateOnChange
          >
            {({
              handleChange,
              handleBlur,
              errors,
              touched,
              isValid,
              dirty,
              values,
              setFieldValue,
            }) => (
              <Form>
                {/* Set default card state  */}
                <DefaultCardState cardRequestPayload={cardRequestPayload} />
                <div>
                  <div className="flex flex-wrap justify-center gap-4 ">
                    <div className="text-alat-grey relative w-full lg:max-w-sm mb-2 pb-5">
                      <p className="text-sm py-4 text-secondary text-left">
                        Please fill in the form to request card
                      </p>
                      <div className="flex h-[211px] w-[326px] mb-[33px] relative mx-auto">
                        <img
                          src={CardBg}
                          alt="Card"
                          className="absolute top-0 left-0 h-full w-full"
                        />
                        <span className="absolute bottom-3 text-black left-4">
                          {preferredName.name ??
                            cardRequestPayload?.preferredName}
                        </span>
                      </div>
                      {/* Hide for hotlisted cards  */}
                      {isNewCard && (
                        <div className="pb-4 text-left">
                          <TextInput
                            id="preferredName_input"
                            label="Preferred name on card"
                            name="preferredName"
                            placeHolder=""
                            handleBlur={handleBlur}
                            maxLength={21}
                            handleChange={(e) => {
                              handleChange(e);
                              const value = e.target.value;
                              const errorMsg =
                                handlePreferredNameValidation(value);
                              setPreferredName({
                                name: value,
                                error: errorMsg,
                              });
                            }}
                            value={values?.preferredName}
                            error={preferredName.error || errors.preferredName}
                            hasError={
                              !!preferredName.error || !!errors.preferredName
                            }
                          />
                          {!preferredName.error && (
                            <span className="inline-block text-[10px]/[24px] text-[#8080808C]">
                              Max 21 characters
                            </span>
                          )}
                        </div>
                      )}
                      <div className="pb-4 text-left">
                        <label
                          htmlFor="card-type_btn"
                          className="pb-2 text-lib-alat-black text-sm	inline-block"
                        >
                          Card type
                        </label>
                        <CustomRadioInput
                          variantClassName="h-auto text-left items-center !bg-white !border-lib-alat-gray-input-border"
                          id="card-type_btn"
                          clickEvent={() => {}}
                          icon={() => (
                            <CardIcon color="white" className="h-9 w-9" />
                          )}
                          checked={true}
                          heading={
                            <p className="text-sm text-black">
                              Prepaid card wallet
                            </p>
                          }
                          text={
                            <p className="!text-xs text-[#464646]">
                              Suitable for naira transactions
                            </p>
                          }
                          value="2"
                        />
                      </div>
                      <div className="relative w-full lg:max-w-sm text-left">
                        <div className="mb-2 block  ">
                          <Label
                            value="Select Account to debit"
                            className="!text-lib-alat-black font-normal text-sm/[24px]"
                          />
                        </div>

                        <SelectDropdown
                          options={getOption(
                            "debit",
                            isNewCard ? regularAccounts : unMappedAccounts
                          )}
                          placeholder="--Select--"
                          name="accountNumberDebit"
                          onChange={(_, accountNo: string) => {
                            handleChange(accountNo);
                            const selectedAccount =
                              handleSelectedAccount(accountNo);
                            if (selectedAccount?.balance < 2500) {
                              setAccountError("Insufficient funds");
                            } else {
                              setAccountError("");
                              setAccountToDebit(selectedAccount);
                            }
                          }}
                          value={
                            accountToDebit.accountNo ||
                            cardRequestPayload?.sourceAccountNumber
                          }
                          hasError={accountError !== ""}
                          error={accountError}
                        />
                      </div>
                      <p className="text-xs py-4 text-lib-alat-red text-left">
                        A minimum deposit of N2,500 is required (cost of card
                        inclusive)
                      </p>
                      <div className="relative w-full lg:max-w-sm mb-2 pt-2">
                        <div className="mb-2 block text-left text-[#666666]">
                          <Label
                            value="Select Delivery Option"
                            className="!text-lib-alat-black font-normal text-sm/[24px]"
                          />
                        </div>
                        <div className="relative w-full lg:max-w-sm text-left">
                          <StateDropdown
                            name="deliveryOption"
                            id="deliveryOptionSelect"
                            onChange={handleChange}
                            value={
                              values.deliveryOption.label ||
                              defaultDeliveryOption
                            }
                            options={deleveryOptionList}
                            placeholder="--Select--"
                            onSelectOption={(data) => {
                              setFieldValue("deliveryOption", data);
                            }}
                          />

                          {touched.deliveryOption && errors.deliveryOption && (
                            <span className="text-sm text-red-600">
                              {typeof errors?.deliveryOption == "string"
                                ? errors?.deliveryOption
                                : ""}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="relative w-full py-6 mb-2  flex items-center justify-center">
                        <FormikButton
                          id="proceed"
                          labelText="Proceed"
                          isDisabled={
                            cardRequestPayload
                              ? !cardRequestPayload
                              : (!isValid && !dirty) ||
                                (isNewCard ? !preferredName.name : false) ||
                                accountError !== ""
                          }
                          containerVariant={`w-full h-[48px] rounded-md flex justify-center item-center`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ReusableCard>
    </>
  );
};

export default RequestForm;
