import React, { forwardRef, useLayoutEffect, useState } from "react";
import { moneyInputFormat } from "utils/helperFunctions";
import { InputType } from "../types";
import ErrorIcon from "../../assets/images/error-icon.svg";
import { ReactComponent as SuccessIcon } from "../../assets/images/icons/success-icon.svg";
import { ReactComponent as TooltipIcon } from "../../assets/images/icons/tooltip-icon.svg";
import Preloader from "../../Mics/Preloader";

export const TextInput = forwardRef<HTMLInputElement, InputType>(
  (
    {
      autoFocus = false,
      extraLabel,
      isAmount = false,
      containerVariant = "w-full flex flex-col",
      type = "text",
      name,
      isCardExpiry = false,
      label,
      labelStyle = "text-sm mb-2.5",
      toolTip,
      id = "",
      handleChange,
      handleBlur,
      value = "",
      error,
      isAllowSpecialChar = true,
      isOnlyNumber = false,
      hasError = false,
      placeHolder = "Enter text",
      variant = "border text-black w-full h-12 text-lg px-5",
      icon = {
        active: false,
        variant: "",
        preview: null,
      },
      autoComplete,
      isDisabled = false,
      isLoading = false,
      success = false,
      showTooltip = false,
      showExtras = false,
      minLength,
      maxLength,
      isPin,
    },
    ref
  ) => {
    // Local State
    const [localValue, setLocalValue] = useState("");
    const [open, setOpen] = useState(false);

    // Effects
    useLayoutEffect(() => {
      setLocalValue(value != "" ? value : "");
    }, [value]);

    const validationFufilled = value !== "" && success;
    return (
      <div className={`${containerVariant} relative`}>
        {label && (
          <div className="flex items-center justify-between">
            <label
              htmlFor={`input-${name}`}
              className={`${labelStyle}
							${isDisabled ? "text-gray-900" : "text-black"}`}
            >
              {label}
            </label>
            {showTooltip && (
              <>
                <div className="cursor-pointer relative">
                  {open && toolTip}
                  <div
                    onMouseEnter={() => setOpen(true)}
                    onMouseLeave={() => setOpen(false)}
                  >
                    <TooltipIcon />
                  </div>
                </div>
              </>
            )}
            {showExtras && <>{extraLabel}</>}
          </div>
        )}
        {icon?.active && <span className={icon.variant}>{icon.preview}</span>}
        {isLoading && (
          <div className="relative">
            <span
              className={`absolute text-lib-alat-dark-red ml-5 right-1.5 top-3`}
            >
              {isLoading && (
                <Preloader
                  variant="w-6 h-6"
                  currentColor="#AB0B4B"
                  currentFill="#F8E8E8"
                />
              )}
            </span>
          </div>
        )}
        {validationFufilled && (
          <div className="relative">
            <span
              className={`absolute text-lib-alat-dark-red ml-5 right-1.5 top-3`}
            >
              {validationFufilled && <SuccessIcon />}
            </span>
          </div>
        )}
        <input
          ref={ref}
          id={id}
          name={name}
          type={type}
          className={`focus:outline-none text-sm focus:border-lib-alat-gray-input-border z-2 focus:bg-white outline-none placeholder:text-sm rounded-md
          ${icon?.active && "px-12"} 
          ${
            isDisabled
              ? `cursor-not-allowed border-gray-100 bg-gray-50 placeholder:text-gray-300 text-gray-300`
              : "bg-lib-alat-gray-input placeholder:text-gray-300 border-lib-alat-gray-input-border"
          }
          ${
            success &&
            !hasError &&
            "valid:border-[#3BB54A] focus:valid:border-[#3BB54A]"
          }
          ${hasError && "border-red-500 focus:border-red-500"}
          ${variant}`}
          value={localValue}
          onChange={(event) => {
            const re = /^[0-9\b]+$/;
            const onlyNumbers = event.target.value.replace(/\D/g, "");
            const allowSpecialChar = event.target.value.replace(
              /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\/?]+/g,
              ""
            );
            // if value is not blank, then test the regex
            if (
              isPin &&
              event.target.value !== "" &&
              re.test(event.target.value) === false
            )
              return false;
            if (isPin && maxLength && event.target.value.length > maxLength)
              return false;
            const inputVal = isOnlyNumber
              ? onlyNumbers
              : !isAllowSpecialChar
                ? allowSpecialChar
                : event?.target?.value;
            // Put in MM/YY if the in put is card expiration
            if (isCardExpiry) {
              const formattedInput = inputVal
                .replace(/^(\d{2})(\d{0,2})/, "$1/$2") // Add '/' after the first two digits
                .replace(/^(\d{2})\/(\d{0,2}).*/, "$1/$2");
              setLocalValue(formattedInput);
              handleChange(event);
            } else if (isAmount) {
              const amountValue = moneyInputFormat(event.target.value);
              setLocalValue(amountValue?.withMoneyFormat || "");
              handleChange(event);
            } else {
              setLocalValue(inputVal);
              handleChange(event);
            }
          }}
          onBlur={handleBlur}
          placeholder={placeHolder}
          disabled={isDisabled}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          minLength={minLength}
          maxLength={maxLength}
        />

        {hasError && (
          <div className="flex gap-2">
            <img src={ErrorIcon} alt="Error" />
            <p className="text-red-500  text-xs h-auto py-1">{error}</p>
          </div>
        )}
      </div>
    );
  }
);

// Important note
// This input can manage (text, search, number, email) type.
