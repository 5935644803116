export const POST_SUBMIT_CARD_REQUEST = "card/request/submit";
export const GET_ACCOUNT_CARDS = (
  accountNumber: string,
  schemeCode: string
): string => {
  return `card/account-cards?accountNumber=${accountNumber}&schemeCode=${schemeCode}`;
};
export const POST_SUBMIT_CARD_ACTIVATION = "card/activate";
export const GET_CARD_HOTLIST_REASON = "card/hotlist-reasons";
export const GET_CARD_UTILITY_CARDS = "card/utility-cards";
export const GET_UTILITY_CARDS_WALLETS = "card/utility-wallet-accounts";
export const POST_SUBMIT_CARD_HOTLIST = "card/hotlist";
export const POST_SUBMIT_CARD_CHANGE_PIN = "card/change-pin";
export const GET_ALL_CARDS = "card/cards";
export const GET_CHECK_ACCOUNT_AVAILABILITY = ({
  accountNumber,
  schemeCode,
}: {
  accountNumber: string;
  schemeCode: string;
}): string => {
  return `card/request/account-availability?AccountNumber=${accountNumber}&SchemeCode=${schemeCode}`;
};
