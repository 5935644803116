import SuccessScreen from "components/Cards/CardActivation/SuccessScreen";
import HotListScreen from "components/Cards/CardHotListScreen";
import CardSelection from "components/Cards/CardSelection";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import PageHeader from "components/reusables/Page/PageHeader";
import { useAppDispatch } from "hooks/useReduxHook";
import { useEffect, useState } from "react";
import { GET_CARD_HOTLIST_REASON_ACTION } from "react-wrappers/stores/redux/cards/actions";
import { useCardState } from "react-wrappers/stores/redux/cards/slices";

const HotList = () => {
  const [screen, setScreen] = useState(0);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GET_CARD_HOTLIST_REASON_ACTION());
  }, []);

  return (
    <div className="w-full">
      {screen === 0 && (
        <CardSelection setScreen={setScreen} isActiveCards type={"hotlist"} />
      )}
      {screen === 1 && <HotListScreen setScreen={setScreen} />}
      {screen === 2 && (
        <SuccessScreen pageName={"Card Hotlist"} setScreen={setScreen} />
      )}
    </div>
  );
};

export default HotList;
