import * as Yup from "yup";
const containsNumbersRegex = /^\d+$/;

export const cardActivationValidationSchema = (
  authType: string,
  isDebit?: boolean
) =>
  Yup.object({
    cardNumber: Yup.string()
      .trim()
      .required("Please enter your card number")
      .test(
        "cardNumber",
        `${isDebit ? "Invalid card number" : "Your card number must be 19 digits"}`,
        (value) => {
          if (isDebit) {
            // When debit card must be between than 19 and 21
            return value.length > 18 && value.length < 22;
          }
          return value.length === 23;
        }
      ),
    cardPin: Yup.string()
      .trim("PIN cannot contain spaces")
      .required("Please enter your current PIN")
      .length(4, "Your new PIN must be 4 digits")
      .matches(containsNumbersRegex, {
        message: "Your PIN should only contain numbers",
        excludeEmptyString: true,
      })
      .test(
        "cardPin",
        "Your New PIN can only contain numbers",
        (value) => !Number.isNaN(Number(value))
      ),
    confirmCardPin: Yup.string()
      .trim("PIN cannot contain spaces")
      .required("Please confirm your new PIN")
      .length(4, "PIN must be 4 digits")
      .matches(containsNumbersRegex, {
        message: "Your PIN should only contain numbers",
        excludeEmptyString: true,
      })
      .oneOf([Yup.ref("cardPin")], "PIN does not match")
      .test(
        "confirmCardPin",
        "Your PIN can only contain numbers",
        (value) => !Number.isNaN(Number(value))
      ),
    softToken:
      authType === "soft"
        ? Yup.string()
            .trim("Your soft token cannot contain spaces")
            .required("Please enter your current soft token")
            .matches(containsNumbersRegex, {
              message: "Your soft token should only contain numbers",
              excludeEmptyString: true,
            })
            .test(
              "token",
              "Your soft token cannot be less than 6 digits",
              (value) => value?.length >= 6
            )
        : Yup.string().notRequired(),

    hardTokenOtp:
      authType === "hard"
        ? Yup.string()
            .trim("Hard token otp cannot contain spaces")
            .required("Please enter your current hard token otp")
            .matches(containsNumbersRegex, {
              message: "Your hard token otp should only contain numbers",
              excludeEmptyString: true,
            })
            .test(
              "hardTokenOtp",
              "Your hard token otp cannot be less than 6 digits",
              (value) => value?.length >= 6
            )
        : Yup.string().notRequired(),

    hardTokenPin:
      authType === "hard"
        ? Yup.string()
            .trim("PIN cannot contain spaces")
            .required("Please enter your hard token PIN")
            .length(4, "PIN must be 4 digits")
            .matches(containsNumbersRegex, {
              message: "Your hard token PIN should only contain numbers",
              excludeEmptyString: true,
            })
            .test(
              "hardTokenPin",
              "Your hard Token PIN can only contain numbers",
              (value) => !Number.isNaN(Number(value))
            )
        : Yup.string().notRequired(),

    pin:
      authType === "pin"
        ? Yup.string()
            .trim("PIN cannot contain spaces")
            .required("Please enter your AFB PIN")
            .length(4, "Your AFB PIN must be 4 digits")
            .matches(containsNumbersRegex, {
              message: "Your AFB PIN should only contain numbers",
              excludeEmptyString: true,
            })
            .test(
              "pin",
              "Your AFB PIN can only contain numbers",
              (value) => !Number.isNaN(Number(value))
            )
        : Yup.string().notRequired(),
  });
