import { ReactNode, useEffect, useState } from "react";
import { ReactComponent as PrevSlideBtn } from "../../../../alat-components-library/assets/images/icons/back-arrow.svg";
import { ReactComponent as NextSlideBtn } from "../../../../alat-components-library/assets/images/icons/next-arrow.svg";
import UtilityCardView from "alat-components-library/Cards/UtilityCard";
import { IUtilityCard } from "types/global";
import FilterButton from "./FilterButton";

const CardSelectionSlide = ({
  data,
  type,
  subTitle,
  selectedCard,
  handleCardSelection,
  isButton = false,
}: {
  type: "activate" | "reset pin" | "request" | "hotlist";
  selectedCard?: IUtilityCard;
  handleCardSelection: (card: IUtilityCard) => void;
  isButton?: boolean;
  subTitle?: ReactNode;
  data: IUtilityCard[];
}) => {
  const [measureWidth, setMeasureWidth] = useState(0);
  const [cardFilter, setCardFilter] = useState("active");

  const isActivate = type === "activate";
  const isRequest = type === "request";

  useEffect(() => {
    const width = Number(data?.length) * 300 + 30;
    setMeasureWidth(width);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measureWidth]);

  const scrollSlider = (direction: string) => {
    const element = document.querySelector(".card-slide") as HTMLElement;
    const distance = 500;
    const duration = 1000;
    const start = element.scrollLeft;
    const end = direction === "left" ? start - distance : start + distance;
    const startTime = performance.now();

    const easeOutCubic = (t: number, b: number, c: number, d: number) => {
      return c * ((t = t / d - 1) * t * t + 1) + b;
    };

    const scroll = (timestamp: number) => {
      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const easeProgress = easeOutCubic(progress, 0, 1, 1);
      const newPosition = Math.floor(easeProgress * (end - start)) + start;
      element.scrollTo({ left: newPosition });

      if (progress < 1) {
        requestAnimationFrame(scroll);
      }
    };

    requestAnimationFrame(scroll);
  };
  const handleFilter = (cards: IUtilityCard[], cardFilter: string) => {
    return cards.filter((card) => {
      const cardStatus = String(card.cardStatus).toLowerCase();

      if (isRequest) {
        return String(cardStatus).toLowerCase() === cardFilter;
      }
      if (isActivate) {
        return cardStatus === "inactive";
      } else {
        return cardStatus === "active";
      }
    });
  };
  const cards = handleFilter(data, cardFilter);
  const hasCards = cards?.length;

  return (
    <div>
      {!!subTitle && hasCards && subTitle}
      {/* Filter for card request */}
      {isRequest && (
        <div className="flex max-w-[328px] m-auto w-full justify-center items-center">
          <FilterButton
            name="Active cards"
            isActive={cardFilter === "active"}
            onClick={() => setCardFilter("active")}
          />
          <FilterButton
            name="Inactive cards"
            isActive={cardFilter === "inactive"}
            onClick={() => setCardFilter("inactive")}
          />
        </div>
      )}
      {!hasCards && isRequest ? (
        <div className="w-full p-5">
          <p className="text-sm text-center text-[#606060]">
            You do not have {isRequest ? `an ${cardFilter}` : "a"} card
          </p>
        </div>
      ) : (
        <div className="w-full py-4 bg-white rounded-md">
          {data?.length <= 2 && data?.length > 0 && (
            <div className="w-full flex flex-row justify-center relative items-center overflow-x-auto card-slide">
              <div
                className={`h-48 flex flex-row justify-center  relative items-center  space-x-3`}
                style={{ width: `${measureWidth}px` }}
              >
                {cards.map((item: IUtilityCard, index: number) => {
                  const isActive =
                    selectedCard?.linkedAccounts?.[0] ===
                    item?.linkedAccounts?.[0];
                  return (
                    <div
                      className={`${isButton ? "cursor-pointer" : "cursor-default"} border border-[3px] rounded-[11.18px] ${isActive ? "border-lib-alat-black" : "border-[transparent]"}`}
                      key={index}
                      onClick={() => isButton && handleCardSelection(item)}
                    >
                      <UtilityCardView
                        isButton={isButton}
                        data={item}
                        type={type}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {data?.length > 2 && data?.length > 0 && (
            <div className="flex w-full space-x-2">
              <div
                className="flex items-center justify-center cursor-pointer w-1/12"
                id="left-arrow_btn"
                onClick={() => scrollSlider("left")}
              >
                <PrevSlideBtn />
              </div>
              <div className="w-10/12 h-auto item-center overflow-x-auto hide-scroller card-slide">
                <div
                  className={`h-48 flex flex-row justify-start relative items-center space-x-3`}
                  style={{ width: `${measureWidth}px` }}
                >
                  {cards?.map((item: IUtilityCard, index: number) => {
                    const isActive =
                      selectedCard?.linkedAccounts?.[0] ===
                      item?.linkedAccounts?.[0];

                    return (
                      <div
                        className={`${isButton ? "cursor-pointer" : "cursor-default"}  border border-[3px] rounded-[16.18px] ${isActive ? "border-lib-alat-black" : "border-[transparent]"}`}
                        key={index}
                        onClick={() => isButton && handleCardSelection(item)}
                      >
                        <UtilityCardView isButton={isButton} data={item} />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className="flex items-center justify-center cursor-pointer w-1/12 m-0"
                id="right-arrow_btn"
                onClick={() => scrollSlider("right")}
              >
                <NextSlideBtn />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CardSelectionSlide;
