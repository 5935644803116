import React, { lazy } from "react";
// import { AccountSetup } from "../pages/Dashboard/PendingActions/AccountSetup";
import { UploadId } from "../pages/Onboarding/UploadId";
import TermsAndConditions from "pages/Home/TermsAndConditions";

const CreateUser = lazy(() =>
  import("../pages/Profile/CreateUser").then(({ CreateUser }) => ({
    default: CreateUser,
  }))
);

const Home = lazy(() =>
  import("../pages/Home/Home").then(({ Home }) => ({ default: Home }))
);
const ComplianceAction = lazy(() =>
  import("../pages/Home/ComplianceAction").then(({ ComplianceAction }) => ({
    default: ComplianceAction,
  }))
);
const ContactUs = lazy(() =>
  import("../pages/Home/ContactSupport").then(({ ContactUs }) => ({
    default: ContactUs,
  }))
);
const PurpleConnect = lazy(() =>
  import("../pages/Home/ContactSupport/PurpleConnect").then(
    ({ PurpleConnect }) => ({
      default: PurpleConnect,
    })
  )
);
export const HOME_ROUTES = {
  COMPLIANCE_ACTION: "/compliance/license/:id",
  CONTACT_US: "/contact-us",
  PURPLE_CONNECT: "/purple-connect",
};
export const homeRoutes = [
  {
    path: "/",
    isAuthenticated: false,
    element: <Home />,
  },
  {
    path: HOME_ROUTES.CONTACT_US,
    isAuthenticated: false,
    element: <ContactUs />,
  },
  {
    path: HOME_ROUTES.PURPLE_CONNECT,
    isAuthenticated: false,
    element: <PurpleConnect />,
  },
  {
    path: HOME_ROUTES.COMPLIANCE_ACTION,
    isAuthenticated: false,
    element: <ComplianceAction />,
  },
  // {
  //     path: "/account-setup",
  //     isAuthenticated: true,
  //     element: <AccountSetup />,
  // },
  {
    path: "/upload-id",
    isAuthenticated: true,
    element: <UploadId />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },
  {
    path: "/create-user",
    isAuthenticated: true,
    element: <CreateUser />,
    allowedRoles: ["admin"],
  },

  {
    path: "/terms-and-conditions",
    isAuthenticated: false,
    element: <TermsAndConditions />,
  },
];
