import { Tabs, Flowbite } from "flowbite-react";
import React from "react";
import NewRecipientComponent1 from "./NewRecipientComponent1";
import NewRecipientComponent2 from "./NewRecipientComponent2";
import NewRecipientComponent3 from "./NewRecipientComponent3";
import NewRecipientComponent4 from "./NewRecipientComponent4";
import OTPVerification from "../Reusables/OTPVerification";
import AddFromSavedBeneficiary from "./AddFromSavedBeneficiary";
import AddFromSavedGroup from "./AddFromSavedGroup";
import ResponseStatus from "components/Payments/ResponseStatus";
import TransactionBreakdown from "./TransactionBreakdown";
import SuccessScreen from "../Reusables/SuccessScreen";
import PageLoader from "components/Loader/PageLoader";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";
import { useMultipleTransferController } from "controllers/MultipleTransfer/useMultipleTransferController";

const MultipleTransferTab = () => {
  const {
    isLoading,
    showScreen,
    amount,
    accountNumber,
    accountValidated,
    accountValidationError,
    accountName,
    selectedBank,
    recipients,
    otp,
    otpMessage,
    authToken,
    description,
    allowSubmit,
    showPinError,
    pin,
    accountToDebit,
    recipientMinMaxError,
    isPinValidated,
    pinError,
    correlationId,
    editRecipientIndex,
    sumTotal,
    totalIsGreaterThanBalance,
    transaction,
    activeTab,
    setActiveTab,
    dispatch,
    authState,
    navigate,
    allAccounts,
    tabsRef,
    bankLists,
    nipCharges,
    savedGroupList,
    savedBeneficiaryList,
    handlePinValidation,
    handleChange,
    handleSubmit,
    handleBackToRecipients,
    handleScreenChangeNext,
    handleScreenChangePrev,
    handleRefreshTrasaction,
    handleAccountValidation,
    handleAddRecipient,
    handleAddBeneficiary,
    handleAddGroup,
    handleEditRecipient,
    handleRemoveRecipient,
    handleTryAgain,
    handleTransactionBreakdown,
    handlePrev,
    handleValidate,
    handleOtpValidationSubmit,
    setSumTotal,
    setPinError,
    setTotalIsGreaterThanBalance,
    setAccountToDebit,
    setPin,
    setAuthToken,
    setOtp,
  } = useMultipleTransferController();
  const handleBackToFundsTransfer = () => {
    navigate("/payments/funds-transfer");
  };
  const handleScreenChangeToBankTransfer = () => {
    navigate("/payments/bank-transfer");
  };

  const theme = {
    tabs: {
      tablist: {
        base: "flex text-center gap-5 z-0 px-2 md:px-0",
        tabitem: {
          base: "flex text-center",
          variant: {
            underline: {
              base: "rounded-t-lg",
              active: {
                on: "active py-2 rounded-t-lg border-b-2 border-lib-alat-red text-lib-alat-red font-medium",
                off: "py-2 border-b-2 border-transparent text-lib-alat-black font-medium",
              },
            },
          },
        },
      },
    },
  };

  const handleGenerateReceipt = () => {};
  const paymentState = usePaymentState();

  return (
    <>
      <div className="w-full"></div>
      {!!paymentState.loading.includes("PUT_COMPLETE_TRANSFER_TRANSACTION") && (
        <PageLoader message="Confirming request..." />
      )}
      <Flowbite theme={{ theme }}>
        <Tabs
          aria-label="Default tabs"
          // eslint-disable-next-line react/style-prop-object
          variant="underline"
          ref={tabsRef}
          onActiveTabChange={(tab: React.SetStateAction<number>) =>
            setActiveTab(tab)
          }
          className="dark:!border-[#E4DFDF] !border-[#E4DFDF] w-full md:px-0 px-2"
        >
          <Tabs.Item
            itemID="multiple-new-recipient_tab"
            active={activeTab === 0}
            title="New Recipient"
            className=""
          >
            {/* <NewRecipient /> */}
            <div className="">
              {isLoading ? <PageLoader message="" /> : null}
              {showScreen === 1 && (
                <NewRecipientComponent1
                  amount={amount}
                  bankList={bankLists}
                  accountNumber={accountNumber}
                  accountValidated={accountValidated}
                  accountValidationError={accountValidationError}
                  accountName={accountName}
                  selectedBank={selectedBank}
                  handleChange={handleChange}
                  handleBackToFundsTransfer={handleBackToFundsTransfer}
                  handleAccountValidation={handleAccountValidation}
                  handleScreenChangeNext={handleScreenChangeNext}
                  handleScreenChangePrev={handleScreenChangePrev}
                  handleScreenChangeToBankTransfer={
                    handleScreenChangeToBankTransfer
                  }
                  handleAddRecipient={handleAddRecipient}
                />
              )}
              {showScreen === 2 && (
                <NewRecipientComponent2
                  handleRemoveRecipient={handleRemoveRecipient}
                  handleEditRecipient={handleEditRecipient}
                  recipients={recipients}
                  // // accountToDebit={accountToDebit}
                  // selectedBank={selectedBank}
                  // accountName={accountName}
                  // accountNumber={accountNumber}
                  // accounts={data.data.accounts}
                  // amount={amount}
                  // description={description}
                  handleChange={handleChange}
                  handleScreenChangePrev={handleScreenChangePrev}
                  handleScreenChangeNext={handleScreenChangeNext}
                  recipientMinMaxError={recipientMinMaxError}
                />
              )}
              {showScreen === 3 && (
                <NewRecipientComponent3
                  recipients={recipients}
                  accountToDebit={accountToDebit}
                  setSumTotal={setSumTotal}
                  handleChange={handleChange}
                  setAccountToDebit={(value: any, totalCharges: any) => {
                    setTotalIsGreaterThanBalance(false);
                    const convertedAccountDetails = value;
                    const totalAmount = Number(sumTotal) + Number(totalCharges);
                    // console.log("total amount", totalAmount);
                    setAccountToDebit(convertedAccountDetails);
                    if (convertedAccountDetails.balance < totalAmount) {
                      // console.log("balance", convertedAccountDetails)
                      setTotalIsGreaterThanBalance(true);
                    }
                  }}
                  accounts={allAccounts}
                  // allowSubmit={allowSubmit}
                  nipCharges={nipCharges}
                  handleScreenChangePrev={handleScreenChangePrev}
                  handleScreenChangeNext={handleScreenChangeNext}
                  totalIsGreaterThanBalance={totalIsGreaterThanBalance}
                />
              )}
              {showScreen === 4 && (
                <NewRecipientComponent4
                  recipients={recipients}
                  sumTotal={sumTotal}
                  accountToDebit={accountToDebit}
                  accountName={accountName}
                  accountNumber={accountNumber}
                  selectedBank={selectedBank}
                  pin={pin}
                  setPin={setPin}
                  nipCharges={nipCharges}
                  isPinValidated={isPinValidated}
                  showPinError={showPinError}
                  handleChange={handleChange}
                  allowSubmit={allowSubmit}
                  handleScreenChangePrev={handleScreenChangePrev}
                  handleScreenChangeNext={handleScreenChangeNext}
                  pinError={pinError}
                  authToken={authToken}
                  setAuthToken={setAuthToken}
                  handleSubmit={handleSubmit}
                  handleValidate={handleValidate}
                />
              )}
              {showScreen === 5 && (
                <OTPVerification
                  otpMsg={otpMessage}
                  otp={otp}
                  setOtp={setOtp}
                  handleChange={handleChange}
                  isLoading={isLoading}
                  handleScreenChangePrev={handleScreenChangePrev}
                  // handleScreenChangeNext={handleScreenChangeNext}
                  handleSubmit={handleSubmit}
                  correlationId={correlationId}
                  handleOtpValidationSubmit={handleOtpValidationSubmit}
                />
              )}
              {showScreen > 5 && (
                <div className="">
                  <ResponseStatus
                    type={
                      showScreen === 6
                        ? "pending"
                        : showScreen === 7
                          ? "successful"
                          : showScreen === 8
                            ? "failed"
                            : "nothing"
                    }
                    onRefresh={handleRefreshTrasaction}
                    onGenerateReceipt={handleGenerateReceipt}
                    amount={amount.withMoneyFormat}
                    sumTotal={sumTotal}
                    recipient={accountName}
                    saveGroup={true}
                    bankCode={selectedBank.bankCode}
                    bankName={selectedBank.bankName}
                    accountNumber={accountNumber}
                    pin={pin}
                    correlationId={correlationId}
                    onRetry={handleTryAgain}
                    transaction={transaction}
                    handleTransactionBreakdown={handleTransactionBreakdown}
                  />
                </div>
              )}
              {showScreen === 9 && (
                <TransactionBreakdown
                  onRefresh={handleRefreshTrasaction}
                  onGenerateReceipt={handleGenerateReceipt}
                  amount={amount.withNumberFormat}
                  recipient={accountName}
                  saveBeneficiary={true}
                  bankCode={selectedBank.bankCode}
                  bankName={selectedBank.bankName}
                  accountNumber={accountNumber}
                  pin={pin}
                  correlationId={correlationId}
                  onRetry={handleTryAgain}
                  transaction={transaction}
                  handlePrev={handlePrev}
                />
              )}
              {showScreen === 10 && <SuccessScreen />}
            </div>
          </Tabs.Item>
          <Tabs.Item
            id="multiple-add-from-beneficiary_tab"
            active={activeTab === 1}
            title="Add From Beneficiary"
            className=""
          >
            {/* <SavedBeneficiary /> */}
            <div>
              {
                <AddFromSavedBeneficiary
                  accountNumber={accountNumber}
                  // handleChange={handleChange}
                  handleBackToFundsTransfer={handleBackToRecipients}
                  handleScreenChangeNext={handleScreenChangeNext}
                  savedBeneficiaryList={paymentState?.beneficiaryList}
                  // handleSelectedBeneficiary={handleSelectedBeneficiary}
                  handleAddBeneficiary={handleAddBeneficiary}
                />
              }
            </div>
          </Tabs.Item>
          <Tabs.Item
            id="multiple-saved-group_tab"
            active={activeTab === 2}
            title="Saved Group"
          >
            <AddFromSavedGroup
              accountNumber={accountNumber}
              // handleChange={handleChange}
              handleBackToFundsTransfer={handleBackToRecipients}
              handleScreenChangeNext={handleScreenChangeNext}
              savedGroupList={savedGroupList}
              // handleSelectedBeneficiary={handleSelectedBeneficiary}
              handleAddGroup={handleAddGroup}
            />
          </Tabs.Item>
        </Tabs>
      </Flowbite>
      {/* <div className="w-[245px]"></div> */}
    </>
  );
};

export default MultipleTransferTab;
