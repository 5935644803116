import { CheckIcon } from "@heroicons/react/24/solid";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import PageHeader from "components/reusables/Page/PageHeader";
import { Button, Card } from "flowbite-react";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";

const OtpApprove = () => {
  const navigate = useNavigate();
  const { approvePendingTransactionsOrActions } = usePaymentState();
  const location = useLocation();
  // Is Stamped
  const isStamped = location.state?.isStamped;
  const isApprover = location.state?.isApprover;
  // Is cheque confirmation
  const isChequeConfirm = location.state?.isChequeConfirm;

  return (
    <DashboardWrapper>
      <div className="container-fluid 2xl:container mx-auto ">
        <div className="container-fluid 2xl:container mx-auto">
          <main className="max-h-screen overflow-auto">
            <div className="py-8">
              <div className="max-w-6xl mx-auto">
                <div className="rounded-3xl mb-5">
                  <div className="ml-4 pb-14">
                    <PageHeader
                      title={
                        isStamped
                          ? "Account Statement Request"
                          : "Review transaction"
                      }
                    />
                  </div>
                  <Card className="md:w-2/3 w-[95%] bg-white dark:border-gray-200 mx-auto">
                    <div>
                      <div className="text-center flex justify-center mt-10">
                        <div className="flex justify-center items-center w-16 h-16 bg-green-200 rounded-full">
                          <div className="flex justify-center items-center w-10 h-10 bg-green-600 rounded-full">
                            <div className=" flex justify-center items-center w-8 h-8 bg-white rounded-full">
                              <div className=" flex justify-center items-center w-6 h-6 bg-green-600 rounded-full">
                                <CheckIcon className="mx-auto w-6 h-6 text-white opacity-100 light:text-gray-200" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Hide for unstamped  */}
                      {!isStamped && (
                        <>
                          <div className="text-center text-[22px]/[37.4px] font-semibold mt-6">
                            {approvePendingTransactionsOrActions?.successMessage
                              ?.title ?? "Approved"}
                          </div>
                          <h3 className="text-center mb-5 text-sm font-normal text-gray-500 light:text-gray-400 mt-4">
                            {approvePendingTransactionsOrActions?.successMessage
                              ?.actionMessage ??
                              "Transaction was successfully approved"}
                          </h3>
                          <div className="flex justify-center items-center w-full flex-col gap-4 mb-40 mt-10">
                            <Button
                              color="gray"
                              onClick={() => navigate("/user-dashboard")}
                              className="hover:text-white dark:hover:!text-white dark:hover:bg-primary dark:border-gray-200 dark:text-primary border-primary !text-white  w-[188px] !bg-primary focus:ring-4 focus:ring-primary disabled:hover:bg-primary dark:bg-primary  dark:focus:ring-primary dark:disabled:hover:bg-primary focus:text-primary focus:bg-white"
                            >
                              Proceed to dashboard
                            </Button>
                            {!isChequeConfirm && (
                              <NavLink
                                to={"/payments/pending-transactions"}
                                className="text-lib-alat-dark-red"
                              >
                                <div className="cursor-pointer">
                                  <p
                                    className="leading-[28px] tracking-tighter font-semibold text-sm"
                                    id="returnToDashboard_btn"
                                  >
                                    Go to the next pending action
                                  </p>
                                </div>
                              </NavLink>
                            )}
                          </div>
                        </>
                      )}
                      {/* Show for stamped  */}
                      {isStamped && (
                        <>
                          <div className="text-center text-3xl font-semibold mt-6">
                            Request {isApprover ? "Approved" : "Verified"}
                          </div>
                          {!isApprover && (
                            <h3 className="text-center mb-5 text-lg font-normal text-gray-500 light:text-gray-400 mt-10">
                              Pending Approval
                            </h3>
                          )}
                          <div className="flex justify-center items-center w-full flex-col gap-4 mb-40 mt-10">
                            <Button
                              color="gray"
                              onClick={() =>
                                navigate("/payments/pending-transactions")
                              }
                              className="hover:text-white dark:hover:!text-white dark:hover:bg-primary dark:border-gray-200 dark:text-primary border-primary !text-white  w-[409px] !bg-primary focus:ring-4 focus:ring-primary disabled:hover:bg-primary dark:bg-primary  dark:focus:ring-primary dark:disabled:hover:bg-primary focus:text-primary focus:bg-white"
                            >
                              Go to the next pending action
                            </Button>
                            <NavLink
                              to="/user-dashboard"
                              className="text-lib-alat-dark-red"
                            >
                              <div className="cursor-pointer">
                                <p
                                  className="leading-[28px] tracking-tighter font-semibold text-sm"
                                  id="returnToDash_btn"
                                >
                                  Proceed to dashboard
                                </p>
                              </div>
                            </NavLink>
                          </div>
                        </>
                      )}
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default OtpApprove;
