import { useEffect, useState } from "react";
import { useAuthState } from "../../react-wrappers/stores/redux/auth/slices";
import {
  setBulkTranferIntra,
  setBulkTranferInter,
  setBulkOtpMessage,
  usePaymentState,
  setCorrelationId,
  setTransactionReference,
  setResponseCode,
} from "../../react-wrappers/stores/redux/payments/slices";
import { useAppDispatch } from "../../hooks/useReduxHook";
import {
  POST_TRANSFER_TRANSACTION_ACTION,
  PUT_COMPLETE_TRANSFER_TRANSACTION_ACTION,
} from "../../react-wrappers/stores/redux/payments/actions";
import { toast } from "react-toastify";
import {
  compareArr,
  extractNumberFromString,
  formatNumberToCurrency,
  generateCharge,
} from "utils/helperFunctions";
import { ToastNotify } from "helpers/toastNotify";
import { ExcelSheetData } from "./useIntraTransferController";

type ExcelList = {
  sortedData: ExcelSheetData;
  hasNextPage: boolean;
  currentPage: number;
  count: number;
  totalItems: number;
  pageSize: number;
  skip: number;
  take: number;
};

export const useTransactionTransferController = (
  setActiveTab: any,
  screenName: string,
  transferType?: string
) => {
  // console.log("test", transferType);
  // Redux State
  const authState = useAuthState();
  const paymentState = usePaymentState();
  const dispatch = useAppDispatch();
  const {
    // user,
    userRole,
  } = useAuthState();

  // console.log("validate", validateAccount);

  let bulkTransferPayload = paymentState?.bulkTransferPayloadIntra;
  // if (screenName === "inter")
  //   bulkTransferPayload = paymentState?.bulkTransferPayloadInter;

  // Local State
  const [transactionRef, setTransactionRef] = useState("hello");
  const [excelList, setExcelList] = useState<ExcelList>({
    sortedData: [],
    hasNextPage: false,
    currentPage: 1,
    count: 10,
    totalItems: 0,
    pageSize: 10,
    skip: 1,
    take: 10,
  });

  // console.log("bulkPayload", bulkTransferPayload)
  let totalTransferAmount =
    bulkTransferPayload?.transferDestinations?.length > 0 &&
    bulkTransferPayload?.transferDestinations.reduce(
      (acc: number, obj: any) => acc + Number(obj.amount),
      0
    );

  let totalTransferCharges =
    bulkTransferPayload?.transferDestinations?.length > 0 &&
    bulkTransferPayload?.transferDestinations.reduce(
      (acc: number, obj: any) => acc + Number(obj.transactionFee),
      0
    );

  // Effects
  useEffect(() => {
    if (bulkTransferPayload?.transferDestinations?.length > 0) {
      const cutOnlyTen = bulkTransferPayload?.transferDestinations?.slice(
        0,
        10
      );
      setExcelList({
        sortedData: cutOnlyTen,
        hasNextPage: true,
        currentPage: 1,
        count: 10,
        totalItems: bulkTransferPayload?.transferDestinations?.length,
        pageSize: 10,
        skip: 1,
        take: 10,
      });
    }
  }, [bulkTransferPayload?.transferDestinations]);

  const handleViewNext = () => {
    //Load next screen
    const cutOnlyTen = bulkTransferPayload?.transferDestinations?.slice(
      excelList.skip + 10 - 1,
      excelList.take + 10
    );
    setExcelList({
      sortedData: cutOnlyTen,
      hasNextPage: false,
      currentPage: excelList?.currentPage + 1,
      count: 10,
      totalItems: excelList?.totalItems,
      pageSize: 10,
      skip: excelList.skip + 10,
      take: excelList.take + 10,
    });
  };

  const handleViewPrev = () => {
    //Load next screen
    const cutOnlyTen = bulkTransferPayload?.transferDestinations.slice(
      excelList.skip - 10 - 1,
      excelList.take - 10
    );
    setExcelList({
      sortedData: cutOnlyTen,
      hasNextPage: false,
      currentPage: excelList.currentPage - 1,
      count: 10,
      totalItems: excelList.totalItems,
      pageSize: 10,
      skip: excelList.skip - 10,
      take: excelList.take - 10,
    });
  };

  const handleSelectAccountSubmit = async (
    values: any,
    accountSelected: any
  ) => {
    let token = "";
    let transactionPin = "";
    let type = 0;
    let authType = "";

    if (Number(totalTransferAmount) <= 2000000) {
      authType = "pinOtp";
    }
    if (Number(totalTransferAmount) > 2000000) {
      if (authState?.user?.hasSoftToken) {
        authType = "softToken";
      } else {
        authType = "hardTokenPin";
      }
    }
    if (
      Number(totalTransferAmount) <= 2000000 &&
      paymentState?.responseCode === 21
    ) {
      if (authState?.user?.hasSoftToken) {
        authType = "softToken";
      } else {
        authType = "hardTokenPin";
      }
    }
    if (
      Number(totalTransferAmount) <= 2000000 &&
      authState?.user?.hasSoftToken === false &&
      authState?.user?.hasTransactionPin === false
    ) {
      authType = "hardTokenPin";
    }

    if (
      authState?.user?.hasSoftToken &&
      authState?.user?.hasTransactionPin === false
    ) {
      authType = "softToken";
    }

    // console.log(values, authState.user)
    if (values?.account === "")
      return ToastNotify({
        type: "error",
        message: "Please select an account number.",
      });

    if (authType === "softToken") {
      if (values.softToken === "") {
        return ToastNotify({
          type: "error",
          message: "Please enter a soft token to continue.",
        });
      }
      token = values.softToken;
      type = 0;
    }

    if (authType === "pinOtp") {
      if (values.transactionALATPin === "") {
        return ToastNotify({
          type: "error",
          message: "Please enter a transaction pin.",
        });
      }
      transactionPin = values.transactionALATPin;
      type = 2;
    }

    if (authType === "hardTokenPin") {
      if (values.transactionHardTokenPin === "")
        return ToastNotify({
          type: "error",
          message: "Please enter hard token pin.",
        });
      if (values.hardToken === "")
        return ToastNotify({
          type: "error",
          message: "Please enter hard token.",
        });

      token = values.hardToken;
      transactionPin = values.transactionHardTokenPin;
      type = 1;
    }

    const payload = {
      sourceAccountNumber: values.account,
      sourceAccountName: accountSelected.accountName,
      sourceAccountCurrencyCode: accountSelected.currency,
      transactionPin: transactionPin,
      token: token,
      ShouldUseMultipleDebit:
        values?.debitType?.toLowerCase() === "single debit" ? false : true,
    };
    // Update the Bulk Trasnfer Payload
    // if (screenName === "intra")
    dispatch(setBulkTranferIntra({ ...bulkTransferPayload, ...payload }));
    // if (screenName === "inter")
    //   dispatch(setBulkTranferInter({ ...bulkTransferPayload, ...payload }));
    dispatch(
      POST_TRANSFER_TRANSACTION_ACTION({ ...bulkTransferPayload, ...payload })
    )
      .unwrap()
      .then((res: any) => {
        // console.log("res", res);
        if (res?.data?.data?.responseCode === 21) {
          dispatch(setResponseCode(res?.data?.data?.responseCode));
        }

        setTransactionRef("Bye");
        dispatch(
          setTransactionReference(res?.data?.data?.transactionReference || "")
        );
        if (type === 2 && res.status === 200) {
          //console.log(res?.data.data.correlationId)
          dispatch(
            setBulkOtpMessage(res?.data?.responseMessage?.actionMessage)
          );

          dispatch(setCorrelationId(res?.data.data.correlationId));
          return setActiveTab(`otp-${screenName}-tab`);
        }
        if (type !== 2 && res.status === 200) {
          dispatch(
            setTransactionReference(res?.data?.data?.transactionReference)
          );
          setActiveTab(
            ["initiator"]?.includes(userRole?.toLowerCase())
              ? `success-${screenName}-tab`
              : `sp-success-${screenName}-tab`
          );
        }
        if (res?.data?.data?.hasError) {
          return ToastNotify({
            type: "error",
            message: `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
            position: "top-right",
          });
        }
      });
  };

  const handleOtpValidationSubmit = async (values: any) => {
    //console.log(bulkTransferPayload)
    const { transactionType } = bulkTransferPayload;
    //console.log(values)
    //console.log(paymentState)
    if (!paymentState?.correlationId)
      return ToastNotify({
        type: "error",
        message: "You are not authorized to carry out this request.",
      });
    if (values.otp === "")
      return ToastNotify({
        type: "error",
        message: "Please enter a valid otp to continue.",
      });

    const payload = {
      transactionType,
      code: values?.otp,
      correlationId: paymentState?.correlationId,
    };
    //console.log(payload)
    dispatch(PUT_COMPLETE_TRANSFER_TRANSACTION_ACTION(payload))
      .unwrap()
      .then((res: any) => {
        // console.log(res);
        // Redirect to the success screen
        if (res.status === 200) {
          setActiveTab(
            ["initiator"]?.includes(userRole?.toLowerCase())
              ? `success-${screenName}-tab`
              : `sp-success-${screenName}-tab`
          );
        } else {
          ToastNotify({
            type: "error",
            message: res?.data?.data?.responseMessage
              ? `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`
              : "Something went wrong",
            position: "top-right",
          });
        }
      });
  };

  const handleIsHeaderEqual = (arr: any) => {
    const checkHeaderTemplateOne = compareArr(arr, [
      ...paymentState?.bulkTransferFileHeaderOne,
    ]);
    const checkHeaderTemplateTwo = compareArr(arr, [
      ...paymentState?.bulkTransferFileHeaderTwo,
    ]);
    return checkHeaderTemplateOne || checkHeaderTemplateTwo;
  };

  const validateItem = (item: any) => {
    let errorMessage = {
      status: false,
      msg: "",
    };

    if (item["ACCOUNT NUMBER"] === undefined || item["ACCOUNT NUMBER"] === "") {
      errorMessage = { status: true, msg: "Uploaded file cannot be empty." };
    }
    if (
      (item["ACCOUNT NAME"] === undefined || item["ACCOUNT NAME"] === "") &&
      !paymentState?.validateBulkTransferAccounts
    ) {
      errorMessage = { status: true, msg: "Uploaded file cannot be empty." };
    }
    if (item["INSTITUTION"] === undefined || item["ACCOUNT NAME"] === "") {
      errorMessage = { status: true, msg: "Uploaded file cannot be empty." };
    }
    if (item["AMOUNT"] === undefined || item["ACCOUNT NAME"] === "") {
      errorMessage = { status: true, msg: "Uploaded file cannot be empty." };
    }

    //Validate if not intra bank (bankcode check)
    // if (screenName === "intra" && item["INSTITUTION"]?.split("|")[1] != "035") {
    //   errorMessage = {
    //     status: true,
    //     msg: "Only intrabank transactions are allowed for the selected option.",
    //   };
    // }
    // if (screenName === "inter" && item["INSTITUTION"]?.split("|")[1] == "035") {
    //   errorMessage = {
    //     status: true,
    //     msg: "Only inter-bank transactions are allowed for the selected option.",
    //   };
    // }

    if (
      transferType?.toLowerCase() === "wema" &&
      item["INSTITUTION"]?.split("|")[1] !== "035"
    ) {
      errorMessage = {
        status: true,
        msg: "Only intrabank transactions are allowed for the selected option.",
      };
    }
    if (
      transferType?.toLowerCase() === "others" &&
      item["INSTITUTION"]?.split("|")[1] === "035"
    ) {
      errorMessage = {
        status: true,
        msg: "Only inter-bank transactions are allowed for the selected option.",
      };
    }

    return errorMessage;
  };

  const checkDuplicates = (array: any) => {
    const result = Object.values(
      array.reduce((c: any, v: any) => {
        let k = v.beneficiaryAccountNumber + "-" + v.beneficiaryName;
        c[k] = c[k] || [];
        c[k].push(v);
        return c;
      }, {})
    ).reduce((c: any, v: any) => (v.length > 1 ? c.concat(v) : c), []) as [];

    return result?.length > 0;
  };

  const handleFileUploadChange = (values: any, setFieldValue: any) => {
    // console.log("test", values);
    //Validate header column if it meets the required template
    if (!handleIsHeaderEqual(values.headersCol))
      return ToastNotify({
        type: "error",
        message: "Uploaded file is invalid",
      });

    let errorMessage = {
      status: false,
      msg: "",
    };

    // console.log("item", values?.data);

    const transferDestinations = values?.data.map(
      (item: any, index?: number) => {
        const errorCheck = validateItem(item);
        // console.log("errorCheck", errorCheck)

        if (errorCheck?.status) return (errorMessage = errorCheck);
        const amountCheckRegExp = / NGN | N | ₦ | # /i;

        const amount = Number(
          item["AMOUNT"]
            ?.replace(amountCheckRegExp, "")
            .split(",")
            .join("")
            .trim()
        );

        //Validate for Charege and generate
        const transactionFee = generateCharge(
          amount,
          transferType ?? "",
          paymentState?.nipCharges ?? [],
          item["INSTITUTION"].split("|")[1]
        );
        // console.log("transactionFee", generateCharge(
        //   amount,
        //   transferType ?? "",
        //   paymentState?.nipCharges ?? [],
        //   item["INSTITUTION"].split("|")[1]
        // ))
        return {
          // rowId: index + 1,
          beneficiaryAccountNumber: item["ACCOUNT NUMBER"],
          beneficiaryName: item["ACCOUNT NAME"] ?? "",
          beneficiaryBankName: item["INSTITUTION"].split("|")[0],
          beneficiaryBankCode: item["INSTITUTION"].split("|")[1],
          amount: amount,
          beneficiaryCurrency: "str",
          narration: item["DESCRIPTION"],
          transactionFee: Number(transactionFee),
          refCode: item["REFERENCE CODE (opt.)"] || "",
        };
      }
    );
    // console.log("dest", transferDestinations)

    if (errorMessage.status) {
      return ToastNotify({
        type: "error",
        message: errorMessage.msg,
        position: "top-right",
      });
    }
    if (transferDestinations.length < 2) {
      return ToastNotify({
        type: "error",
        message: "Uploaded file cannot be empty.",
        position: "top-right",
      });
    }
    if (
      transferDestinations.length > 5000 &&
      transferType?.toLowerCase() !== "both" &&
      !paymentState?.validateBulkTransferAccounts
    ) {
      return ToastNotify({
        type: "error",
        message: `You've uploaded ${transferDestinations.length} transactions, surpassing the 5000-transaction limit for the selected option.`,
        position: "top-right",
      });
    }
    if (
      transferDestinations.length > 100 &&
      transferType?.toLowerCase() !== "both" &&
      paymentState?.validateBulkTransferAccounts
    ) {
      return ToastNotify({
        type: "error",
        message: `You've uploaded ${transferDestinations.length} transactions, surpassing the 100-transaction limit for the selected option.`,
        position: "top-right",
      });
    }
    if (
      transferDestinations.length > 100 &&
      transferType?.toLowerCase() === "both"
    ) {
      return ToastNotify({
        type: "error",
        message: `You've uploaded ${transferDestinations.length} transactions, surpassing the 100-transaction limit for the selected option.`,
        position: "top-right",
      });
    }
    // if (checkDuplicates(transferDestinations)) {
    //   return toast.error("Duplicate transactions are not allowed");
    // }

    setFieldValue(`excelSheet${screenName}`, {
      cols: values.cols,
      data: transferDestinations,
      file: values.file,
    });
  };

  return {
    authState,
    paymentState,
    excelList,
    totalTransferAmount,
    totalTransferCharges,
    transactionRef,
    dispatch,
    validateItem,
    checkDuplicates,
    handleViewNext,
    handleViewPrev,
    handleSelectAccountSubmit,
    handleOtpValidationSubmit,
    handleIsHeaderEqual,
    handleFileUploadChange,
  };
};
