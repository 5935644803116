import ActivationScreen from "components/Cards/ActivationScreen";
import SuccessScreen from "components/Cards/CardActivation/SuccessScreen";
import { useState } from "react";
import CardSelection from "components/Cards/CardSelection";

const Activation = () => {
  const [screen, setScreen] = useState(0);

  return (
    <div className="w-full">
      {screen === 0 && (
        <CardSelection setScreen={setScreen} type={"activate"} />
      )}
      {screen === 1 && <ActivationScreen setScreen={setScreen} />}
      {screen === 2 && (
        <SuccessScreen pageName={"Card Activation"} setScreen={setScreen} />
      )}
    </div>
  );
};

export default Activation;
