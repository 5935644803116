export const POST_TRANSACTION_VALIDATE: string = `transaction/validate`;
export const GET_BANK_LIST = (type?: string) =>
  type ? `v1/Bank/Banks?Querypurpose=${type}` : "v1/Bank/Banks";
export const GET_TRANSACTION_STATUS = (correlationId: string): string => {
  return `v1/Transfer/StatusByCorrelationId/${correlationId}`;
};
export const POST_VALIDATE_PIN: string = `validation/pin`;
export const POST_ACCOUNT_STATEMENT_DATA: string =
  "transaction/get-account-statement-data";
export const GET_ACCOUNT_NAME_ENQUIRY = (
  accountNumber: string,
  bankCode: string
): string => {
  return `v1/Bank/AccountNameEquiry?accountNumber=${accountNumber}&bankCode=${bankCode}`;
};
export const GET_BENEFICIARY_LIST = (
  pageSize: string,
  pageNumber: string,
  searchValue: string | null
) => {
  if (searchValue === "" || searchValue === undefined || searchValue === null)
    return `v1/Beneficiary/Beneficiaries?pageSize=${pageSize}&pageNumber=${pageNumber}`;
  return `v1/Beneficiary/Beneficiaries?pageSize=${pageSize}&pageNumber=${pageNumber}&SearchValue=${searchValue}`;
};
export const GET_GROUP_LIST: string = `v1/Transfer/MultipleTransferGroups?PageSize=100000&PageNumber=1`;

export const POST_FETCH_TRANSACTION_LIST: string = `v1/Authorization/PendingSummary`;
export const GET_PENDING_USERS_REQUEST_DETAILS: string = `v1/Authorization/PendingUserProfileDetails`;
export const POST_PENDING_SOFT_TOKEN_REQUEST_DETAILS: string = `v1/Authorization/PendingSoftTokenRequestDetails`;
export const POST_FETCH_TRANSACTION_DETAIL: string = `v1/Authorization/PendingTransferDetails`;
export const POST_FETCH_COLLECTIONS_DETAIL: string = `v1/Authorization/PendingCollectionPaymentDetails`;
export const POST_FETCH_BILLS_DETAIL: string = `v1/Authorization/PendingBillPaymentDetails`;
export const PUT_APROVE_OR_REJECT_TRANSACTION: string = `v1/Transfer/ApprovePendingTransfer`;
export const POST_ADD_BENEFICIARY: string = "v1/Beneficiary/Add";
export const PUT_DELETE_BENEFICIARY = (id: number) =>
  `v1/Beneficiary/Delete?Id=${id}`;
export const POST_MULTIPLE_ACCOUNT_NAME_ENQUIRY: string =
  "v1/Bank/MultipleAccountNameEnquiry";
export const GET_QUERY_ACCOUNT_NAME_ENQUIRY_STATUS = (enquiryId: string) =>
  `v1/Bank/QueryAccountNameEnquiryStatus?enquiryId=${enquiryId}`;

//Bulk Transfer
export const POST_TRANSFER_TRANSACTION: string = `transaction/validate`;
export const PUT_COMPLETE_TRANSFER_TRANSACTION: string = `v1/Transfer/CompleteWebTransferWithOtp`;
export const GET_NIP_CHARGES: string = `v1/Bank/NipCharges`;
export const GET_RESEND_TRANSFER_OTP = (correlationId: string) =>
  `v1/Transfer/ResendWebTransferValidationOtp/${correlationId}`;
export const POST_FETCH_ALL_BULK_TRANSFER = "v1/Transfer/BulkTransferSummary";
export const POST_FETCH_ONE_BULK_TRANSFER =
  "v1/Transfer/BulkTransferSummaryDetails";
export const GET_EXPORT_BULK_TRANSFER_SUMMARY = ({
  accountNumber,
  referenceNumber,
}: {
  accountNumber: string;
  referenceNumber: string;
}) =>
  `v1/Transfer/ExportBulkTransferSummaryDetailsToExcel?AccountNumber=${accountNumber}&Reference=${referenceNumber}`;

// Account Statement
export const POST_CREATE_ACCOUNT_STATEMENT = "/transaction/account-statement";
export const POST_CREATE_CHEQUE_CLEARANCE_STATEMENT =
  "/transaction/fetch-cheque-statement";
//Transaction history

// FX TRANSFER
export const GET_SWIFT_CODE_DETAILS = (swiftCode: string) =>
  `v1/Fx/SwiftCodeDetails?swiftCode=${swiftCode}`;
export const GET_PAYMENT_PURPOSES_AND_CHARGES = (amount: number) =>
  `v1/Fx/PaymentPurposesAndCharges?amount=${amount}`;
export const POST_FX_EXCHANGE_RATE = "v1/Fx/ExchangeRate";
export const POST_MAKE_OUTBOUND_FX_PAYMENT = "v1/Fx/MakeOutboundFxPayment";
export const GET_FX_TRANSACTION_HISTORY = (
  pageNumber: number,
  pageSize: number,
  referenceId?: string
) => {
  if (referenceId === null || referenceId === undefined || referenceId === "")
    return `v1/Fx/TransactionHistory?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  return `v1/Fx/TransactionHistory?pageNumber=${pageNumber}&pageSize=${pageSize}&referenceId=${referenceId}`;
};
export const GET_FX_BENEFICIARIES = (
  pageNumber: number,
  pageSize: number,
  beneficiaryId?: string,
  beneficiaryName?: string
) => {
  if (
    beneficiaryId === null ||
    beneficiaryId === undefined ||
    beneficiaryId === ""
  )
    return `v1/Fx/FxBeneficiaries?pageNumber=${pageNumber}&pageSize=${pageSize}&beneficiaryName=${beneficiaryName}`;
  return `v1/Fx/FxBeneficiaries?pageNumber=${pageNumber}&pageSize=${pageSize}&beneficiaryId=${beneficiaryId}&beneficiaryName=${beneficiaryName}`;
};
export const GET_FX_TRANSACTION_DETAILS = (referenceId: string) =>
  `v1/Fx/FxTransactionDetails?referenceId=${referenceId}`;
export const POST_FX_TRANSFER_DETAILS: string = `v1/Authorization/PendingFxTransferDetails`;

// Bill Payment
export const GET_BILL_CATEGORIES_LIST: string = `v1/Bill/BillersCategoriesDetails`;
export const POST_VALIDATE_BILL_REFERENCE = "v1/Bill/ValidateCustomer";
export const POST_CREATE_BILL = "/transaction/validate/bills";

// Account Stamp statement
export const GET_STAMP_STATEMENT_SUMMARY: string =
  "/transaction/get-fixed-charges";
export const POST_PENDING_ACCOUNT_STATEMENT_DETAIL: string =
  "/v1/Authorization/PendingAccountStatementRequestDetails";
// Cheque Confirmation
export const POST_PENDING_CHEQUE_CONFIRMATION_DETAIL: string =
  "/v1/Authorization/PendingChequeConfirmationRequestDetails";

//#region  Fx Sales
export const GET_ALL_FX_SALES_EXCHANGE_RATES: string =
  "v1/FxSales/GetAllFxRate";
export const GET_FX_SALES_MAX_AMOUNT: string =
  "transaction/get-fx-sales-maximum-amount";
export const POST_VALIDATE_FX_SALES_TRANSACTION: string =
  "transaction/validate/fxsalestransaction";
export const GET_FX_SALES_TRANSACTION_RECEIPT_DETAILS_BY_CORRELATION_ID = (
  correlationId: string
) => `v1/FxSales/FxSalesReceiptDetailsByCorrelationId/${correlationId}`;
