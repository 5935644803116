import { ReusableCard } from "components/reusables/Page/ReusableCard";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import PageLoader from "components/Loader/PageLoader";
import { PrimaryButton } from "alat-components-library";
import { useGetUtilityCards } from "hooks/apis/Cards/utilityCards/useGetUtilityCards";
import EmptyState from "./EmptyState";
import CardSelectionSlide from "./elements/CardSelectionSlide";
import { CardActivationPayload, IUtilityCard } from "types/global";
import { useNavigate } from "react-router-dom";
import OptionModal from "./CardRequest/OptionModal";

interface CardPayload extends CardActivationPayload {
  card?: IUtilityCard;
}
const UtilityCards = ({
  handleNext,
  title,
  unMappedWalletsLoading,
  subTitle,
  type,
  isButton = false,
  buttonText,
  cardPayload,
  updateCardOption,
  unMappedWallets = [],
}: {
  updateCardOption?: (isNewCard: boolean) => void;
  unMappedWalletsLoading?: boolean;
  title: string;
  unMappedWallets?: string[];
  type: "activate" | "reset pin" | "request" | "hotlist";
  subTitle?: ReactNode;
  cardPayload?: CardPayload | null;
  buttonText: string;
  handleNext: (val: number, card?: IUtilityCard) => void;
  isButton?: boolean;
}) => {
  const [selectedCard, setSelectedCard] = useState<IUtilityCard>();
  const [showCardOption, setShowCardOption] = useState(false);
  const [cardError, setCardError] = useState("");
  const isRequest = type === "request";
  const isActivate = type === "activate";

  const { handleGetUtilityCards, isLoading, responseData } =
    useGetUtilityCards();

  const navigate = useNavigate();

  const handleCardSelection = (card: IUtilityCard) => {
    setCardError("");
    setSelectedCard(card);
  };

  useEffect(() => {
    handleGetUtilityCards();
    if (cardPayload?.card) {
      setSelectedCard(cardPayload?.card);
    }
    // Hide card option modal on mount
    setShowCardOption(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const utilityCards = useMemo(
    () => responseData?.utilityCardResponses || [],
    [responseData?.utilityCardResponses]
  );
  const handleFilter = (cards: IUtilityCard[]) => {
    return cards.filter((card) => {
      const cardStatus = String(card.cardStatus).toLowerCase();
      if (isRequest) {
        return true;
      }
      if (isActivate) {
        return cardStatus === "inactive";
      } else {
        return cardStatus === "active";
      }
    });
  };
  const cards = handleFilter(utilityCards);
  const hasCards = cards?.length;
  const isEmpty = cards.length === 0;

  // MAP new wallet to cards
  const hasUnmappedWallet = !!unMappedWallets.length;

  const handleCardOption = (isNewCard: boolean) => {
    // When there's no active cards
    if (isEmpty) {
      navigate("/card/card-request/utility", {
        state: {
          step: 1,
          isNewCard,
        },
      });
      return;
    }
    updateCardOption && updateCardOption(isNewCard);
    setShowCardOption(false);
  };

  return (
    <>
      {(isLoading || unMappedWalletsLoading) && <PageLoader />}

      <ReusableCard
        cardTitle={title}
        className="mt-0"
        variant="md:w-[48rem] w-full mx-auto bg-white border border-alat-gray-input-border rounded-lg"
      >
        {!!hasCards ? (
          <div className="">
            <CardSelectionSlide
              subTitle={subTitle}
              type={type}
              selectedCard={selectedCard}
              handleCardSelection={handleCardSelection}
              data={utilityCards}
              isButton={isButton}
            />
            {cardError && (
              <p className="text-center -mt-6 text-xs text-lib-alat-red">
                {cardError}
              </p>
            )}
          </div>
        ) : (
          <EmptyState />
        )}
        <div className="flex max-w-[409px] mx-auto justify-center flex-row gap-4 py-20 mb-10">
          <PrimaryButton
            id="new-card_btn"
            labelText={isEmpty ? "Request for a new Card" : buttonText}
            variant="font-medium text-xs text-white"
            containerVariant="w-full h-[48px] rounded-md  flex items-center justify-center cursor-pointer bg-lib-alat-red"
            handleClick={() => {
              if (isEmpty) {
                // When it has unmapped accounts
                if (hasUnmappedWallet) {
                  setShowCardOption(true);
                  return;
                }
                navigate("/card/card-request/utility", {
                  state: {
                    step: 1,
                    isNewCard: true,
                  },
                });
                return;
              }
              if (!selectedCard && !isRequest) {
                setCardError(`Please select a card to ${type}`);
                return;
              }
              // When it's request and has unmapped accounts
              if (isRequest && hasUnmappedWallet) {
                setShowCardOption(true);
                return;
              }
              handleNext(1, selectedCard);
            }}
          />
        </div>
      </ReusableCard>
      <div
        className={`${hasUnmappedWallet && showCardOption ? "block" : "hidden"}`}
      >
        <OptionModal handleProceed={handleCardOption} />
      </div>
    </>
  );
};

export default UtilityCards;
