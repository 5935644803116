import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { FormikButton, TextInput } from "alat-components-library";
import { Card } from "flowbite-react";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { formatNumberToCurrency, getOption } from "utils/helperFunctions";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import { useAccountStatementController } from "controllers/AccountStatementController";
import { AccountStatementPaymentSummaryValidation } from "utils/validations/transfer";
import { SelectDrop } from "components/Dashboard/components/SelectDrop";
import { useAppSelector } from "hooks/useReduxHook";
import { ReactNode, useEffect } from "react";
import { useAccountStatementSummary } from "hooks/apis/Payment/AccountStatement/useAccountStatementSummary";
import PageLoader from "components/Loader/PageLoader";
import dayjs from "dayjs";

const ItemList = ({
  name,
  value,
  className = "",
}: {
  name: string;
  value: string | ReactNode;
  className?: string;
}) => (
  <div
    className={`flex  py-4 border-lib-alat-gray-input-border gap-2 text-lib-alat-black flex-wrap justify-between ${className ? className : "border-b"}`}
  >
    <div className="basis-[100px] text-xs">{name}</div>
    <div className="text-sm basis-[200px] text-right font-medium grow">
      {value}
    </div>
  </div>
);
const PaymentSummary = () => {
  const { authState, paymentState, handlePaymentSummarySubmit } =
    useAccountStatementController();

  const { summaryData, summaryLoading, handleSummary } =
    useAccountStatementSummary();

  useEffect(() => {
    handleSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatDate = (date: string) =>
    dayjs(new Date(date)).format("DD/MM/YYYY");

  const navigate = useNavigate();
  const userAccounts = useAppSelector((state) => state.accounts);
  const account = paymentState?.accountStatementPayload;
  const accountInfo = paymentState?.selectedAccountStatement?.accountInfo;
  const accountName = accountInfo?.accountName;
  const accountNo = accountInfo?.accountNo;
  const amount = summaryData?.processingFee || 0;
  const vat = summaryData?.vatPercentage || 0;
  const total = summaryData?.total || 0;
  const startDate = account?.startDate ? formatDate(account?.startDate) : "n/a";
  const endDate = account?.endDate ? formatDate(account?.endDate) : "n/a";

  const handleAmount = (amount: number) =>
    formatNumberToCurrency({
      number: amount,
    });

  const amountVal = handleAmount(amount);
  const vatVal = handleAmount(vat);
  const totalVal = handleAmount(total);
  const hasPin = authState.user?.hasTransactionPin;
  const hasSoftToken = authState.user?.hasSoftToken;
  const isSingleSignatory = authState.user?.role === "SoleProprietor";
  const isHardToken = !hasPin && !hasSoftToken;
  const isLoading =
    paymentState.loading.includes("POST_CREATE_ACCOUNT_STATEMENT") ||
    summaryLoading;

  return (
    <DashboardWrapper>
      {isLoading && <PageLoader message="Please wait ..." />}

      <div className="px-4 lg:px-20">
        <div className="pt-6 ">
          <Card className="max-w-[990px] w-full mx-auto bg-white pb-14  dark:bg-white dark:border-[#EDEDED] border-[#EDEDED] dark:shadow-white">
            <div className="flex items-center justify-between">
              <ChevronLeftIcon
                id="back_btn"
                className="h-6 w-6 text-[#333333] cursor-pointer"
                onClick={() => navigate("/payments/account-statement")}
              />
              <div
                className="text-[#4D4D4D] text-xl font-bold text-center"
                id="details_text"
              >
                Payment Summary
              </div>
              <div></div>
            </div>
            <hr />
            <div className="max-w-[411px] w-full mt-4 mx-auto px-4 border border-lib-alat-gray-input-border rounded-xl">
              <ItemList
                name="Statement of Account"
                value={
                  <div>
                    <p className="pb-0.5">{accountNo}</p>
                    <p>{accountName}</p>
                  </div>
                }
              />
              <ItemList name="Start Date" value={startDate} />
              <ItemList name="End Date" value={endDate} />
              <ItemList name="Processing Fee" value={amountVal} />
              <ItemList name="VAT" value={vatVal} />
              <ItemList name="Total" value={totalVal} className="!border-0" />
            </div>
            <Formik
              initialValues={{
                account: {
                  accountNo: "",
                  balance: 0,
                  debitAccountName: "",
                },
                authorizationType: !hasPin ? 2 : 1,
                token: "",
                pin: "",
              }}
              onSubmit={handlePaymentSummarySubmit}
              validationSchema={AccountStatementPaymentSummaryValidation}
            >
              {({ isSubmitting, dirty, isValid, setFieldValue, values }) => (
                <Form>
                  <div className="max-w-[400px] mx-auto">
                    <div className="mt-5">
                      <SelectDrop
                        id="accountNumberDebit_dropdown"
                        idList="accountNumberDebit-account_text"
                        variant="h-12 w-full text-sm"
                        name="accountNumberDebit"
                        label="Select Account to debit"
                        placeholder="--Select--"
                        value={values?.account?.accountNo}
                        options={getOption(
                          "debit",
                          userAccounts?.allAccounts || authState.user.accounts
                        )}
                        onChange={(name: string, value: any) =>
                          setFieldValue("account", JSON.parse(value.raw))
                        }
                      />
                    </div>
                    {paymentState.accountStatementPayload.isStamped && (
                      <div className="mt-5">
                        {/* Show for single signatory that has pin and also for multiple signatory without softtoken */}
                        {((hasPin && isSingleSignatory) ||
                          isHardToken ||
                          (!hasSoftToken && !isSingleSignatory)) && (
                          <TextInput
                            id="pin_input"
                            label="Enter Your AFB PIN"
                            placeHolder="****"
                            type="password"
                            isPin
                            name="pin"
                            handleChange={(event) =>
                              setFieldValue("pin", event.target.value)
                            }
                            variant="border text-black w-full h-[48px] text-lg px-5"
                            value={""}
                            maxLength={4}
                          />
                        )}
                        {((isSingleSignatory && !hasPin && hasSoftToken) ||
                          isHardToken ||
                          !isSingleSignatory) && (
                          <div className="pt-4">
                            <TextInput
                              id={
                                hasSoftToken
                                  ? "token_input"
                                  : "hard_token_input"
                              }
                              label={`Enter ${!hasSoftToken ? "hard " : ""}token`}
                              placeHolder="******"
                              name="token"
                              type="password"
                              isPin
                              handleChange={(event) =>
                                setFieldValue("token", event.target.value)
                              }
                              variant="border text-black w-full h-[48px] text-lg px-5"
                              value={""}
                              maxLength={6}
                            />
                          </div>
                        )}
                      </div>
                    )}

                    <div className="flex justify-center pt-2">
                      <FormikButton
                        id="send_btn"
                        labelText={"Send"}
                        containerVariant={
                          "w-full h-[45px] my-[3vh] rounded-md flex justify-center"
                        }
                        isDisabled={!isValid && !dirty}
                        isLoading={isSubmitting}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Card>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default PaymentSummary;
