export const handlePendingActionNavigation = (item: any): string => {
  switch (item?.type) {
    case 6:
    case 7:
    case 9:
      return `/payments/requests/details/${item?.correlationId}`;
    case 8:
      return `/payments/fx/details/${item?.correlationId}`;
    case 10:
      return `/payments/collections/details?correlationId=${item?.correlationId}`;
    case 11:
      return `/payments/bills/details?correlationId=${item?.correlationId}`;
    case 12:
      return `/pending/account-statement/details?correlationId=${item?.correlationId}`;
    case 13:
      return `/pending/cheque-confirmation/details?correlationId=${item?.correlationId}`;
    default:
      return `/payments/transaction/details?correlationId=${item?.correlationId}`;
  }
};
