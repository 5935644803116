import { PrimaryButton } from "alat-components-library";
import { ReusableCard } from "components/reusables/Page/ReusableCard";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as FailedIcon } from "../../../../assets/icons/error-two-icon.svg";
import { CardRequestPayload } from "types/global";
import { useRequestCard } from "hooks/apis/Cards/utilityCards/useRequestCard";
import PageLoader from "components/Loader/PageLoader";

const Failed = ({
  cardRequestPayload,
}: {
  cardRequestPayload: CardRequestPayload | null;
}) => {
  const navigate = useNavigate();
  const { handleRequestCard, isLoading } = useRequestCard();
  return (
    <>
      {isLoading && <PageLoader />}

      <ReusableCard
        cardTitle="Payment Summary"
        className="mt-0"
        variant="md:w-[48rem] w-full mx-auto bg-white border border-alat-gray-input-border rounded-lg"
      >
        <div className="max-w-[409px] mx-auto">
          <div className="text-center flex justify-center my-8">
            <FailedIcon />
          </div>
          <div className="text-center text-xl/[24.2px] font-semibold mt-6">
            Failed!
          </div>
          <div className="text-center max-w-[333px] mx-auto text-sm rounded-lg font-normal text-gray-500 light:text-gray-400 pb-[22px]">
            Sorry about this! Your utility card request failed. please try again
          </div>
          <div className="flex flex-col gap-5 justify-center flex-row gap-4 pb-10">
            <PrimaryButton
              id="retry_btn"
              labelText="Try again"
              variant="font-medium text-xs text-white"
              containerVariant="w-[409px] h-[48px] rounded-md flex justify-center cursor-pointer bg-lib-alat-red"
              handleClick={() => {
                handleRequestCard(cardRequestPayload!);
              }}
            />
            <PrimaryButton
              id="proceed-to-dashboard_btn"
              labelText="Home"
              variant="font-medium text-xs text-lib-alat-red"
              containerVariant="flex justify-center ursor-pointer "
              handleClick={() => {
                navigate("/user-dashboard");
              }}
            />
          </div>
        </div>
      </ReusableCard>
    </>
  );
};

export default Failed;
