export const GET_NOTIFICATIONS = (
  notificationState: string,
  pageNumber: number,
  pageSize: number,
  from: string,
  to: string
) =>
  `Notifications?notificationState=${notificationState}&pageNumber=${pageNumber}&pageSize=${pageSize}&from=${from}&to=${to}`;
export const MARK_AS_READ = "Notifications/Read";
export const MARK_ALL_AS_READ = "Notifications/ReadAll";
export const GET_NOTIFICATIONS_COUNT = "Notifications/Count";
export const GET_NOTIFICATION_USER_DETAILS = (
  correlationId: string,
  notificationType: string
) =>
  `User/Details?correlationId=${correlationId}&notificationType=${notificationType}`;
export const GET_NOTIFICATION_TRANSACTION_DETAILS = (correlationId: string) =>
  `v1/Transfer/TransactionApprovalDetails?CorrelationId=${correlationId}`;
export const GET_NOTIFICATION_SOFT_TOKEN_DETAILS = (correlationId: string) =>
  `User/SoftToken?CorrelationId=${correlationId}`;
export const GET_STAMPED_STATEMENT_DETAILS =
  "v1/Transfer/AccountStatementTransactionDetails";
export const GET_CHEQUE_CONFIRMATION_DETAILS =
  "v1/Transfer/ChequeConfirmationTransactionDetails";
