import React, { useState } from "react";
// import CardBg from "assets/svgs/Cards/utility-card-bg.webp";
import CardBg from "assets/svgs/Cards/card-bg-utility.svg";
import { IUtilityCard } from "types/global";
import CopyIcon from "../../../assets/svgs/copy-icon-light.svg";

const UtilityCardView = ({
  data,
  isButton,
  type,
}: {
  isButton?: boolean;
  data: IUtilityCard;
  type?: string;
}) => {
  const [copied, setCopied] = useState(false);
  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const name = data?.name || "n/a";
  const cardNo = data?.maskedPan;
  const expiryDate = data?.expiryDate;
  const accountNo = (data?.linkedAccounts || [])?.[0] || "n/a";
  const year = (expiryDate || []).slice(2, 4);
  const month = (expiryDate || []).slice(0, 2);
  const status = data?.cardStatus;
  const isActive = data?.cardStatus === "Active";
  const isRequest = type === "request";

  return (
    <div className="flex h-[171px] w-[266px]  relative">
      <img
        src={CardBg}
        alt="Card"
        className={`absolute top-0 left-0 h-full w-full ${!isActive && isRequest ? "opacity-50" : ""}`}
      />
      {/* Badge  */}
      {isRequest && (
        <div className="absolute text-center py-1 w-[75px] rounded-bl-lg text-xs rounded-tr-lg top-0 right-[2px] bg-lib-alat-red text-white">
          {status}
        </div>
      )}
      {isRequest && (
        <div className="absolute px-4 py-[6px] rounded-b-lg bottom-0 z-50 bg-[#000000B2] text-white w-full items-end justify-between flex">
          <div className="grow basis-[80px]">
            <p className="pb-[3px] text-[8px]/[9.68px] font-medium text-left">
              {name}
            </p>
            <p className="text-[8px]/[9.68px] text-left">Account Number</p>
            <div className="flex items-center gap-[7px]">
              <p className="text-[10px]/[12.1px] font-semibold">{accountNo}</p>

              <button
                type="button"
                aria-label="copy"
                onClick={() => handleCopy(accountNo)}
              >
                <img src={CopyIcon} alt="copy" className="cursor-pointer" />
              </button>
            </div>
          </div>
          <div className="grow basis-[50px] text-left">
            <p className="text-[8px]/[9.68px]">Card type</p>
            <p className="text-[10px]/[12.1px] font-semibold">
              Prepaid card wallet
            </p>
          </div>
        </div>
      )}
      {cardNo && (
        <span
          style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
          className="absolute text-[#E7E6E5]  text-base left-8 top-[92px] "
        >
          {cardNo}
        </span>
      )}
      {copied && !isButton && (
        <span className="absolute bg-black rounded-lg inline-block px-2 py-1 text-white text-xs z-50 left-8 bottom-[10px] ">
          Copied
        </span>
      )}
      {isButton && (
        <>
          <div className="absolute left-[26%] top-[110px] gap-1 text-black text-[10px]/[24px] items-center flex">
            {/* <div>
              <p className="text-[6px]/[7.68px]">VALID</p>
              <p className="text-[6px]/[7.68px]">Thur</p>
            </div> */}
            <p>
              {month}/{year}
            </p>
          </div>
          <span className="absolute bottom-2 text-black left-8 text-[14px]/[24px] font-semibold">
            {name}
          </span>
        </>
      )}
    </div>
  );
};

export default UtilityCardView;
