import React from "react";

const CardIcon = ({
  color = "url(#paint1_linear_7813_18677)",
  className = "",
}: {
  className?: string;
  color?: string;
}) => {
  return (
    <svg
      className={className}
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.8343 18.1188L28.8871 13.5024C28.7339 13.1524 28.4714 12.8898 28.1214 12.7367C27.7713 12.5835 27.3775 12.6054 27.0274 12.7367L14.7318 17.9437H12.1721C11.3845 17.9437 10.75 18.5782 10.75 19.3658V28.9485C10.75 29.7361 11.3845 30.3706 12.1721 30.3706H30.156C30.9437 30.3706 31.5781 29.7361 31.5781 28.9485V19.3658C31.5781 18.8407 31.2718 18.3813 30.8343 18.1188ZM27.5088 13.8525C27.5744 13.8306 27.64 13.8306 27.6838 13.8525C27.7057 13.8744 27.7713 13.8962 27.7932 13.9619L29.4778 17.9437H17.8167L27.5088 13.8525ZM12.1721 19.147H30.156C30.2654 19.147 30.3748 19.2345 30.3748 19.3658V20.5691H11.9533V19.3658C11.9533 19.2564 12.0408 19.147 12.1721 19.147ZM30.3748 21.7943V22.9976H11.9533V21.7943H30.3748ZM30.156 29.1673H12.1721C12.0627 29.1673 11.9533 29.0798 11.9533 28.9485V24.2009H30.3748V28.9485C30.3748 29.0798 30.2873 29.1673 30.156 29.1673Z"
        fill="url(#paint0_linear_7813_18677)"
      />
      <rect opacity="0.1" width="43" height="43" rx="6.71875" fill={color} />
      <defs>
        <linearGradient
          id="paint0_linear_7813_18677"
          x1="10.75"
          y1="14.7089"
          x2="24.7957"
          y2="32.626"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B2214A" />
          <stop offset="0.986917" stopColor="#6B214A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7813_18677"
          x1="6.27922e-07"
          y1="5.03906"
          x2="34.9375"
          y2="43"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B2214A" />
          <stop offset="0.986917" stopColor="#6B214A" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CardIcon;
