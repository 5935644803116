import { ReactComponent as MySettingsIcon } from "../../../assets/icons/my-settings-icon.svg";
import { ReactComponent as PinSettingsIcon } from "../../../assets/icons/pin-settings-icon.svg";
import { ReactComponent as TokenMgmtIcon } from "../../../assets/icons/token-mgmt-icon.svg";
import { ReactComponent as AccountsIcon } from "../../../assets/icons/accounts-icon.svg";
import { ReactComponent as NairaIcon } from "../../../assets/svgs/naira-in-circle-icon.svg";
import { ReactComponent as DollarIcon } from "../../../assets/svgs/dollar-in-circle-icon.svg";
import { ReactComponent as CustomerSupportIcon } from "../../../assets/svgs/customer-support-icon.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/icons/socials/colored-facebook.svg";
import { ReactComponent as Linkedin } from "../../../assets/icons/socials/colored-linkedin.svg";
import { ReactComponent as Instagram } from "../../../assets/icons/socials/colored-instagram.svg";
import { ReactComponent as Twitter } from "../../../assets/icons/socials/colored-twitter.svg";
import { ReactComponent as EmailIcon } from "../../../assets/icons/email-icon-closed.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/phone-icon.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/icons/error-triangle-icon.svg";

export const adminProfileMenuLists = [
  // {
  //   id: "A01",
  //   name: "My Account(s)",
  //   icon: <AccountsIcon />,
  //   sub: "See all your existing AFB accounts",
  //   route: "/profile/accounts",
  //   roles: ["admin"]
  // },
  // {
  //   id: "A02",
  //   name: "Account Setup",
  //   icon: <AccountSetupIcon />,
  //   sub: "See your AFB account setup",
  //   route: "/profile/account-setup",
  //   roles: ["admin"]
  // },
  // {
  //   id: "A03",
  //   name: "Account Manager",
  //   icon: <AccountManagerIcon />,
  //   sub: "Contact your account manager",
  //   route: "/profile/account-manager",
  //   roles: ["admin"]
  // },
  {
    id: "A04",
    name: "My Settings",
    icon: <MySettingsIcon />,
    sub: "Manage your account settings",
    route: "/profile/account-settings",
    roles: ["admin"],
  },
  {
    id: "A05",
    name: "Customer Support",
    icon: <CustomerSupportIcon />,
    sub: "Talk to us",
    route: "/profile/get-support",
    roles: ["admin"],
  },

  // {
  //   id: "A06",
  //   name: "FAQs",
  //   icon: <QuestionIcon />,
  //   sub: "Frequently asked questions",
  //   route: "/profile/FAQ",
  // },
  // {
  //   id: "A07",
  //   name: "Privacy Policy",
  //   icon: <QuestionIcon />,
  //   sub: "See our Privacy Policy",
  //   route: "/profile/privacy-policy",
  // },
  // {
  //   id: "A08",
  //   name: "Token Management",
  //   icon: <TokenMgmtIcon />,
  //   sub: "Transaction token management",
  //   route: "/profile/token-management",
  //   roles: ["admin"],
  // },
];

export const userProfileMenuLists = [
  {
    id: "U01",
    name: "My Account(s)",
    icon: <AccountsIcon />,
    sub: "See all your existing AFB accounts",
    route: "/profile/accounts",
    roles: [
      // "viewer",
      // "approver",
      // "verifier",
      // "initiator",
      // "initiatorapprover",
      "soleproprietor",
    ],
  },
  // {
  //   id: "U02",
  //   name: "Account Setup",
  //   icon: <AccountSetupIcon />,
  //   sub: "See your AFB account setup",
  //   route: "/profile/account-setup",
  //   roles: ["viewer", "approver", "verifier", "initiator", "initiatorapprover", "soleproprietor"]
  // },
  // {
  //   id: "U03",
  //   name: "Account Manager",
  //   icon: <AccountManagerIcon />,
  //   sub: "Contact your account manager",
  //   route: "/profile/account-manager",
  //   roles: ["viewer", "approver", "verifier", "initiator", "initiatorapprover", "soleproprietor"]
  // },
  // {
  //   id: "U04",
  //   name: "Quick Actions",
  //   icon: <EditQuickActionsIcon />,
  //   sub: "Select actions you want on your dashboard",
  //   route: "/profile/quick-actions",
  //   roles: ["viewer", "approver", "verifier", "initiator", "initiatorapprover", "soleproprietor"]
  // },
  {
    id: "U05",
    name: "My Settings",
    icon: <MySettingsIcon />,
    sub: "Manage your account settings",
    route: "/profile/account-settings",
    roles: [
      "viewer",
      "approver",
      "verifier",
      "initiator",
      "initiatorapprover",
      "soleproprietor",
      "superapprover",
    ],
  },
  {
    id: "U06",
    name: "PIN Management",
    icon: <PinSettingsIcon />,
    sub: "Transaction PIN management",
    route: "/profile/pin-settings",
    roles: [
      "viewer",
      "initiator",
      "soleproprietor",
      "approver",
      "initiatorapprover",
      "verifier",
      "superapprover",
    ],
  },
  {
    id: "U07",
    name: "Token Management",
    icon: <TokenMgmtIcon />,
    sub: "Transaction token management",
    route: "/profile/token-management",
    roles: [
      "viewer",
      "approver",
      "verifier",
      "initiator",
      "initiatorapprover",
      "soleproprietor",
      "superapprover",
    ],
  },
  {
    id: "U08",
    name: "Customer Support",
    icon: <CustomerSupportIcon />,
    sub: "Talk to us",
    route: "/profile/get-support",
    roles: [
      "viewer",
      "approver",
      "verifier",
      "initiator",
      "initiatorapprover",
      "soleproprietor",
    ],
  },
];

export const settingsList = [
  {
    id: "S01",
    name: "Change Password",
    sub: "Change your login Password",
    // route: "/profile/change-password",
    route: "/profile/account-settings/change-password",
  },
  {
    id: "S02",
    name: "Security Question",
    sub: "Set security question and answer",
    // route: "/profile/security-question",
    route: "/profile/account-settings/security-questions",
  },
  // {
  //   id: "S03",
  //   name: "Delete my profile.",
  //   sub: "Permanently delete my AFB profile",
  //   route: "/profile/delete-profile",
  // },
];
export const customerSupportList = [
  {
    id: "CS01",
    name: "Report an error",
    sub: "Send your error report for swift solution",
    isExternal: false,
    icon: <ErrorIcon />,
    route: "/profile/report-error",
  },
  {
    id: "CS02",
    name: "08039003700",
    sub: "Our call center is open to you 24/7",
    isExternal: true,
    icon: <PhoneIcon />,
    route: "tel:+2348039003700",
  },
  {
    id: "CS03",
    name: "help@alat.ng",
    sub: "Send us an email for any enquiry",
    isExternal: true,
    icon: <EmailIcon />,
    route: "mailto:help@alat.ng",
  },
  {
    id: "CS04",
    name: "ALAT By Wema Bank",
    sub: "Follow us on Facebook",
    isExternal: true,
    icon: <FacebookIcon />,
    route: "https://www.facebook.com/Alatng/",
  },

  {
    id: "CS05",
    name: "@alat_ng",
    sub: "Follow us on X (Formerly Twitter)",
    isExternal: true,
    icon: <Twitter />,
    route: "https://x.com/alat_ng",
  },
  {
    id: "CS06",
    name: "ALAT by Wema",
    sub: "Send us an email for any enquiry",
    isExternal: true,
    icon: <Linkedin />,
    route: "https://ng.linkedin.com/company/alatbywema",
  },
  {
    id: "CS07",
    name: "@alat_ng",
    sub: "Follow us on Instagram",
    isExternal: true,
    icon: <Instagram />,
    route: "https://www.instagram.com/alat_ng/?hl=en",
  },
];
export const pinManagementList = [
  {
    id: "P01",
    name: "Create PIN",
    sub: "Create your transaction PIN",
    route: "/profile/pin-management/create-pin",
  },
];

export const pinManagementList2 = [
  {
    id: "P01",
    name: "Change PIN",
    sub: "Change your transaction PIN",
    route: "/profile/pin-management/change-pin",
  },
  {
    id: "P02",
    name: "Forgot PIN",
    sub: "Get a new transaction PIN",
    route: "/profile/pin-management/forgot-pin",
  },
];

export const tokenManagementList = [
  {
    id: "T01",
    name: "Request Soft Token",
    sub: "Soft Token Management",
    route: "/profile/token-management/request-soft-token",
  },
  {
    id: "T02",
    name: "Reactivate Existing Token",
    sub: "Soft Token Management",
    route: "/profile/token-management/reactivate-existing-token",
  },
  {
    id: "T03",
    name: "Change Hardware Token PIN",
    sub: "Hard Token Management",
    route: "/profile/token-management/change-hard-token",
  },
];

export const accountsTypeList = [
  {
    id: "ACT01",
    name: "Naira Account(s)",
    icon: <NairaIcon />,
    sub: "Local currency accounts",
    route: "/profile/accounts/naira",
  },
  {
    id: "ACT02",
    name: "Domiciliary account(s)",
    icon: <DollarIcon />,
    sub: "Foreign currency accounts",
    route: "/profile/accounts/dom",
  },
];
