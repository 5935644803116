import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Button, Card, Label } from "flowbite-react";
import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { ReactComponent as OTPICON } from "../../../../assets/svgs/otp-mobile.svg";
import { ReactComponent as RefreshIcon } from "../../../../assets/svgs/refresh-rounded.svg";
import { blockInvalidNumberCharacters } from "../../../../utils/blockInvalidNumberCharacters";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";
import { useAppDispatch } from "hooks/useReduxHook";
import { GET_RESEND_TRANSFER_OTP_ACTION } from "react-wrappers/stores/redux/payments/actions";
import { toast } from "react-toastify";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import PageLoader from "components/Loader/PageLoader";
import { ReusableCard } from "components/reusables/Page/ReusableCard";
import { ToastNotify } from "helpers/toastNotify";

interface IOTPVerificationProps {
  otp: any;
  setOtp: any;
  handleChange: any;
  isLoading: any;
  handleScreenChangePrev: any;
  handleSubmit: any;
  correlationId: any;
  handleOtpValidationSubmit: any;
  otpMsg: string;
}

const OTPVerification = ({
  otp,
  setOtp,
  handleChange,
  isLoading,
  handleScreenChangePrev,
  handleSubmit,
  correlationId,
  handleOtpValidationSubmit,
  otpMsg,
}: IOTPVerificationProps) => {
  // const location = useLocation();
  const navigate = useNavigate();
  const authState = useAuthState();
  // console.log(authState.user.phoneNumber)

  // Redux State
  const paymentState = usePaymentState();
  const dispatch = useAppDispatch();

  const phoneNumberCast = `${authState.user.phoneNumber.slice(
    1,
    authState.user.phoneNumber.length - 7
  )}*****${authState.user.phoneNumber.slice(
    authState.user.phoneNumber.length - 2,
    authState.user.phoneNumber.length
  )}`;

  useEffect(() => {
    setOtp("");
  }, []);

  return (
    <div className="flex w-full items-center justify-center">
      {isLoading && <PageLoader message="" />}

      <ReusableCard
        cardTitle="Verification"
        handleBackNavigation={handleScreenChangePrev}
        showGoBackIcon
      >
        <div className="flex w-full items-center justify-center px-2">
          <form className="flex flex-col w-full gap-4 justify-center items-center mt-8">
            <div className="text-[#666666] text-center mb-6">
              <OTPICON />
            </div>
            <div>
              <div className="text-center text-[#444444]">{otpMsg}</div>
            </div>
            <div className="relative w-full">
              <div className="mb-2 block text-left">
                <Label
                  htmlFor="otp"
                  value="Enter OTP"
                  className=" text-[#464646] dark:!text-[#464646] font-normal text-sm"
                />
              </div>

              <input
                id="otp_input"
                name="otp"
                type="number"
                className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary"
                value={otp}
                onKeyDown={blockInvalidNumberCharacters}
                onChange={handleChange}
                max={999999}
                min={100000}
              />
            </div>
            <div
              className="flex flex-row w-full justify-end items-center mb-8 text-primary cursor-pointer"
              onClick={() => {
                if (correlationId) {
                  dispatch(GET_RESEND_TRANSFER_OTP_ACTION(correlationId))
                    .unwrap()
                    .then((res: any) => {
                      // console.log(res);
                      res.status === 200 &&
                        ToastNotify({
                          type: "success",
                          message: "OTP sent",
                        });
                    });
                }
              }}
            >
              <div>
                <RefreshIcon />
              </div>
              <div className="text-sm ml-1">Resend OTP</div>
            </div>
            <Button
              type="button"
              className="bg-primary hover:bg-transparent hover:border-primary lg:max-w-sm mb-20  w-[188px] hover:bg-primary focus:ring-1 focus:ring-primary dark:bg-primary dark:hover:bg-primary dark:focus:ring-primary disabled:bg-red-200 disabled:hover:bg-red-200 disabled:text-white disabled:focus:ring-red-200"
              onClick={handleOtpValidationSubmit}
              disabled={otp.length < 6}
              id="completetransfer_btn"
            >
              Complete transfer
            </Button>
          </form>
        </div>
      </ReusableCard>

      {/* <Card className=" bg-white md:w-[48rem] w-full mx-auto dark:bg-white dark:border-transparent border-transparent">
        <div>
          <div className="flex items-center justify-between">
            <div
              id="back_btn"
              onClick={handleScreenChangePrev}
              className="cursor-pointer"
            >
              <ChevronLeftIcon className="h-6 w-6 text-[#333333]" />
            </div>
            <div className="text-[#464646] text-[18px] text-center font-semibold leading-6">
              Verification
            </div>
            <div></div>
          </div>
        </div>
        <hr />

        
      </Card> */}
    </div>
  );
};

export default OTPVerification;
