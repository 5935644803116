import { lazy } from "react";
import { ViewProfile } from "../pages/Profile/ViewProfile";
import { Storage } from "utils/inAppstorage";

const ContactSupport = lazy(() =>
  import("../pages/Profile/ContactSupport").then(({ ContactSupport }) => ({
    default: ContactSupport,
  }))
);
const ReportError = lazy(() =>
  import("../pages/ContactSupport/ReportError").then(({ ReportError }) => ({
    default: ReportError,
  }))
);
const TransactionList = lazy(() =>
  import("../pages/ContactSupport/TransactionList").then(
    ({ TransactionList }) => ({
      default: TransactionList,
    })
  )
);
const TransactionErrorFilters = lazy(() =>
  import("../pages/ContactSupport/TransactionErrorFilters").then(
    ({ TransactionErrorFilters }) => ({
      default: TransactionErrorFilters,
    })
  )
);

export const SUPPORT_URL = {
  REPORT_ERROR: "/support/report-error",
  TRANSACTION_ERROR_FILTERS: "/support/transaction-error-filters",
  TRANSACTION_LIST: "/support/transaction-list",
  // REPORT_TRANSACTION_ERROR: "/support/report-transaction-error",
};
export const routes = [
  {
    path: SUPPORT_URL.TRANSACTION_LIST,
    isAuthenticated: true,
    element: <TransactionList />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },
  {
    path: SUPPORT_URL.TRANSACTION_ERROR_FILTERS,
    isAuthenticated: true,
    element: <TransactionErrorFilters />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },
  {
    path: SUPPORT_URL.TRANSACTION_ERROR_FILTERS,
    isAuthenticated: true,
    element: <TransactionErrorFilters />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },
  {
    path: SUPPORT_URL.REPORT_ERROR,
    isAuthenticated: true,
    element: <ReportError />,
    allowedRoles: [
      "soleproprietor",
      "initiator",
      "initiatorapprover",
      "superapprover",
      "verifier",
      "approver",
      "viewer",
    ],
  },
];

const isProtected = Storage.getItem("afb-app-token");
const unProtectedRoutes: any = [];

export const supportRoutes = isProtected ? routes : unProtectedRoutes;
