import { Formik, Form } from "formik";
import {
  FormikButton,
  PasswordInput,
  PrimaryButton,
  TextInput,
} from "alat-components-library";
import PageLoader from "components/Loader/PageLoader";
import { ReusableCard } from "components/reusables/Page/ReusableCard";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { CardActivationPayload2 } from ".";
import { useEffect } from "react";
import { cardChangePinValidationSchema } from "pages/Cards/validations/cardPinChange.validations";
import UtilityCardView from "alat-components-library/Cards/UtilityCard";
import { useChangePinCard } from "hooks/apis/Cards/utilityCards/useChangePinCard";

interface CardPayload {
  oldPin: string;
  newPin: string;
  confirmNewPin: string;
  softToken: string;
  hardTokenOtp: string;
  hardTokenPin: string;
  pin: string;
  cardNumber: string;
}

const ActivationForm = ({
  handlePrev,
  onNext,
  cardPayload,
}: {
  handlePrev: () => void;
  cardPayload?: CardActivationPayload2 | null;
  onNext: (msg?: string) => void;
}) => {
  const { isLoading, handleChangePinCard, responseData, status } =
    useChangePinCard();
  const auth = useAuthState();

  const authType = () => {
    if (auth?.user?.hasTransactionPin) return "pin";
    if (auth?.user?.hasSoftToken && !auth?.user?.hasTransactionPin)
      return "soft";
    return "hard";
  };
  const handleSubmit = (values: CardPayload) => {
    const isToken = authType() === "soft";
    const card = cardPayload?.card;
    const sanitizedCardNumber = values.cardNumber.replace(/\s/g, "");

    const payload = {
      isSoftToken: isToken,
      fullPan: sanitizedCardNumber,
      expiryDate: card?.expiryDate,
      accountNumber: card?.linkedAccounts?.[0],
      oldPin: values.oldPin,
      cardRouteId: cardPayload?.card?.cardRouteId,
      newPin: values.newPin,
      confirmNewPin: values.confirmNewPin,
      token: values.softToken || values.hardTokenOtp,
      transactionPin: values.pin || values.hardTokenPin,
      cardTypeRequest: 2,
    };
    handleChangePinCard(payload);
  };

  // Navigate to success screen
  useEffect(() => {
    if (status) {
      const msg = responseData;
      onNext(msg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <div className="w-full">
      {isLoading && <PageLoader />}
      <ReusableCard
        cardTitle="Details"
        className="mt-0 border-b"
        variant="md:w-[48rem] w-full mx-auto bg-white border border-alat-gray-input-border rounded-lg"
        handleBackNavigation={handlePrev}
        showGoBackIcon
      >
        <div className="max-w-[409px] mx-auto">
          <Formik
            initialValues={{
              softToken: "",
              hardTokenOtp: "",
              hardTokenPin: "",
              pin: "",
              oldPin: "",
              newPin: "",
              confirmNewPin: "",
              cardNumber: "",
            }}
            onSubmit={handleSubmit}
            validateOnChange
            validationSchema={cardChangePinValidationSchema(authType())}
          >
            {({
              handleChange,
              handleBlur,
              errors,
              touched,
              isValid,
              dirty,
              values,
              setFieldValue,
            }) => (
              <Form>
                <div>
                  <div className="flex flex-wrap justify-center gap-4 ">
                    <div className="text-alat-grey relative w-full lg:max-w-sm mb-2 pb-5">
                      <div className="flex justify-center">
                        <UtilityCardView isButton data={cardPayload?.card!} />
                      </div>
                      <p className="text-sm py-4 text-[#606060] text-center">
                        Fill the details below to change card pin
                      </p>
                      <div className="relative w-full lg:max-w-sm my-4">
                        <TextInput
                          id="card_number"
                          type="text"
                          label="Enter Card Number"
                          name="cardNumber"
                          isOnlyNumber
                          placeHolder="Enter Card Number"
                          value={values.cardNumber}
                          handleChange={(e) => {
                            let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                            value = value.replace(/(.{4})/g, "$1 ").trim();
                            setFieldValue("cardNumber", value);
                          }}
                          handleBlur={handleBlur}
                          hasError={errors?.cardNumber && touched?.cardNumber}
                          error={errors?.cardNumber}
                          autoComplete="new-password"
                          maxLength={23}
                        />
                      </div>
                      <div className="relative w-full lg:max-w-sm my-4">
                        <TextInput
                          id="current_pin"
                          type={"password"}
                          label="Enter Current PIN"
                          name="oldPin"
                          placeHolder="Enter Current PIN"
                          value={values.oldPin}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          autoComplete="new-password"
                          maxLength={4}
                          isPin
                        />
                      </div>

                      <div className="relative w-full lg:max-w-sm mb-4">
                        <TextInput
                          id="new_pin"
                          type={"password"}
                          label="Enter New PIN"
                          name="newPin"
                          placeHolder="Enter New PIN"
                          value={values.newPin}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          autoComplete="new-password"
                          maxLength={4}
                          isPin
                          hasError={errors?.newPin && touched?.newPin}
                          error={errors?.newPin}
                        />
                      </div>

                      <div className="relative w-full lg:max-w-sm mb-4">
                        <TextInput
                          id="new-card_pin"
                          type={"password"}
                          label="Confirm New PIN"
                          name="confirmNewPin"
                          placeHolder="Confirm New PIN"
                          value={values.confirmNewPin}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          autoComplete="new-password"
                          maxLength={4}
                          isPin
                          hasError={
                            errors?.confirmNewPin && touched?.confirmNewPin
                          }
                          error={errors?.confirmNewPin}
                        />
                      </div>

                      {authType() === "pin" && (
                        <div className="pb-4">
                          <PasswordInput
                            name={"pin"}
                            label={"Enter AFB PIN"}
                            placeHolder={""}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.pin}
                            hasError={errors?.pin && touched?.pin}
                            error={errors?.pin}
                            iconStyle={{
                              wrapper: "",
                              icon: "w-[3.5vh] h-[3.5vh]",
                            }}
                            maxLength={4}
                          />
                        </div>
                      )}
                      {authType() === "soft" && (
                        <div className="pb-4">
                          <TextInput
                            id="token_input"
                            name={"softToken"}
                            type={"password"}
                            label={"Enter Soft Token"}
                            placeHolder={""}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.softToken}
                            hasError={errors?.softToken && touched?.softToken}
                            error={errors?.softToken}
                            maxLength={6}
                          />
                        </div>
                      )}

                      {authType() === "hard" && (
                        <>
                          <div className="pb-4">
                            <PasswordInput
                              name={"hardTokenPin"}
                              label={"Enter Hard Token PIN"}
                              placeHolder={""}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              value={values.hardTokenPin}
                              hasError={
                                errors?.hardTokenPin && touched?.hardTokenPin
                              }
                              error={errors?.hardTokenPin}
                              iconStyle={{
                                wrapper: "",
                                icon: "w-[3.5vh] h-[3.5vh]",
                              }}
                              maxLength={4}
                            />
                          </div>
                          <div className="pb-4">
                            <TextInput
                              id="token_input"
                              name={"hardTokenOtp"}
                              type={"password"}
                              label={"Enter Hard Token OTP"}
                              placeHolder={""}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              value={values?.hardTokenOtp}
                              hasError={
                                errors?.hardTokenOtp && touched?.hardTokenOtp
                              }
                              error={errors?.hardTokenOtp}
                              maxLength={6}
                            />
                          </div>
                        </>
                      )}
                      <div className="relative w-full py-6 mb-2  flex gap-4 items-center justify-center">
                        <PrimaryButton
                          id="cancel_btn"
                          labelText={"Cancel"}
                          variant="font-medium text-xs text-lib-alat-red"
                          containerVariant="w-full h-[48px] rounded-md flex justify-center border border-lib-alat-red cursor-pointer bg-white py-3 hover:bg-[#f2f5fc]"
                          handleClick={handlePrev}
                        />
                        <FormikButton
                          id="proceed"
                          labelText="Change Pin"
                          isDisabled={!isValid && !dirty}
                          containerVariant={`w-full h-[48px] !py-0 rounded-md flex justify-center items-center`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ReusableCard>
    </div>
  );
};

export default ActivationForm;
