import { XMarkIcon, CheckIcon } from "@heroicons/react/24/solid";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import PageHeader from "components/reusables/Page/PageHeader";
import { Button, Card } from "flowbite-react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";

const OtpReject = () => {
  const navigate = useNavigate();

  const paymentState = usePaymentState();

  const location = useLocation();
  // Is Stamped
  const isStamped = location.state?.isStamped;
  // Is cheque confirmation
  const isChequeConfirm = location.state?.isChequeConfirm;

  const classStyle =
    "mx-auto w-6 h-6 text-white opacity-100 light:text-gray-200";
  return (
    <DashboardWrapper>
      <div className="container-fluid 2xl:container mx-auto ">
        <div className="container-fluid 2xl:container mx-auto">
          <main className="max-h-screen overflow-auto">
            <div className="pr-6 py-8">
              <div className="max-w-6xl mx-auto">
                <div className="rounded-3xl mb-5">
                  <div className="ml-4 pb-14">
                    <PageHeader
                      title={
                        isStamped
                          ? "Account Statement Request"
                          : "Review transaction"
                      }
                    />
                  </div>
                  <Card className="md:w-[768px] w-full bg-white dark:border-gray-200 mx-auto">
                    <div>
                      <div className="text-center flex justify-center mt-10">
                        <div className="flex justify-center items-center w-16 h-16 bg-red-200 rounded-full">
                          <div className="flex justify-center items-center w-10 h-10 bg-red-600 rounded-full">
                            <div className=" flex justify-center items-center w-8 h-8 bg-red-600 rounded-full">
                              <div className=" flex justify-center items-center w-6 h-6 bg-red-600 rounded-full">
                                {isStamped ? (
                                  <CheckIcon className={classStyle} />
                                ) : (
                                  <XMarkIcon className={classStyle} />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Hide for unstamped  */}

                      {!isStamped && (
                        <>
                          <div className="text-center text-3xl font-semibold mt-6">
                            {
                              paymentState?.pendingTransactionsResponse
                                ?.responseMessage?.title
                            }
                          </div>
                          <h3 className="text-center mb-5 text-lg font-normal mt-10">
                            {paymentState?.pendingTransactionsResponse
                              ?.responseMessage?.actionMessage ??
                              "We will send this notification to the initiator"}
                          </h3>
                          <div className="flex justify-center flex-wrap flex-row gap-y-4 gap-x-8  mb-40 mt-10">
                            {isChequeConfirm && (
                              <div
                                id="pending_action_btn"
                                className="w-[188px] h-10 bg-white border border-lib-alat-dark-red text-lib-alat-dark-red rounded-md flex justify-center items-center cursor-pointer"
                                onClick={() =>
                                  navigate("/payments/pending-transactions")
                                }
                              >
                                Review another
                              </div>
                            )}
                            <div
                              id="approve_btn"
                              className="w-[188px] h-10 text-white bg-lib-alat-dark-red rounded-md flex justify-center items-center cursor-pointer"
                              onClick={() => navigate("/user-dashboard")}
                            >
                              Proceed to dashboard
                            </div>
                          </div>
                        </>
                      )}
                      {/* Show for stamped  */}

                      {isStamped && (
                        <>
                          <div className="text-center text-xl font-semibold mt-6 text-lib-alat-black">
                            {paymentState?.pendingTransactionsResponse
                              ?.responseMessage?.title || "Request Rejected!"}
                          </div>
                          <h3 className="text-center mb-5 text-base text-secondary font-normal mt-[7px]">
                            A notification has been successfully sent to the
                            initiator
                          </h3>
                          <div className="flex justify-center flex-row gap-4 mb-40 mt-1">
                            <div className="flex justify-center items-center w-full flex-col gap-4 my-10">
                              <Button
                                color="gray"
                                onClick={() =>
                                  navigate("/payments/pending-transactions")
                                }
                                className="hover:text-white dark:hover:!text-white dark:hover:bg-primary dark:border-gray-200 dark:text-primary border-primary !text-white  w-[409px] !bg-primary focus:ring-4 focus:ring-primary disabled:hover:bg-primary dark:bg-primary  dark:focus:ring-primary dark:disabled:hover:bg-primary focus:text-primary focus:bg-white"
                              >
                                Go to the next pending action
                              </Button>
                              <NavLink
                                to="/user-dashboard"
                                className="text-lib-alat-dark-red"
                              >
                                <div className="cursor-pointer">
                                  <p
                                    className="leading-[28px] tracking-tighter font-semibold text-sm"
                                    id="returnToDash_btn"
                                  >
                                    Proceed to dashboard
                                  </p>
                                </div>
                              </NavLink>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default OtpReject;
