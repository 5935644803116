import React from "react";

const FilterButton = ({
  onClick,
  isActive,
  name,
}: {
  onClick: () => void;
  isActive: boolean;
  name: string;
}) => {
  return (
    <button
      type="button"
      className={`basis-[164px] py-4 border-b-2 text-xs text-center  grow ${isActive ? "text-lib-alat-black border-lib-alat-red font-semibold" : "text-secondary border-[#EAEAEA]"}`}
      onClick={onClick}
    >
      {name}
    </button>
  );
};

export default FilterButton;
